import React, { useState } from "react";
import coursesData from "./Data/DevelopementCourse.json"; // Import JSON data
import "./CSS/popularcourse.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

function DevelopementCourse() {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleExpandClick = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  return (
    <div
      style={{
        backgroundColor: "#f4f4f6",
        borderRadius: "10px",
        padding:"1rem"
      }}
    >


      <div className="cards-container">
        {coursesData.map((course, index) => (
          <Card
            key={index}
            className="w-full max-w-[22rem] shadow-lg"
            id={course.id}
          >
            <a href={course.href}>
            <CardHeader floated={false} color="blue-gray">
  <video
    src={course.imageUrl}
    alt={course.title}
    autoPlay loop muted
    className="w-full h-full object-cover"
  />
  <div className="absolute inset-0 h-full w-full " />
</CardHeader>

              <CardBody>
                <div className="mb-3 flex items-center justify-between">
                  <Typography
                    variant="h5"
                    color="blue-gray"
                    className="font-medium"
                  >
                    {course.title}
                  </Typography>
                </div>
                <Typography
                  style={{ fontWeight: "400" }}
                  color="gray"
                  className={expandedIndex === index ? "expanded" : "collapsed"}
                  // Safely render HTML inside description
                  dangerouslySetInnerHTML={{
                    __html: course.description,
                  }}
                ></Typography>
              </CardBody>
            </a>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default DevelopementCourse;
