import React, { useState, useEffect } from 'react';

const questions = [
  {
    id: 1,
    question: 'What is MySQL?',
    options: [
      'A relational database management system',
      'A programming language',
      'An operating system',
      'A web server'
    ],
    correctAnswer: 'A relational database management system'
  },
  {
    id: 2,
    question: 'Who developed MySQL?',
    options: [
      'Microsoft',
      'Oracle Corporation',
      'Google',
      'MySQL AB'
    ],
    correctAnswer: 'MySQL AB'
  },
  {
    id: 3,
    question: 'What is the default port for MySQL server?',
    options: [
      '3306',
      '80',
      '443',
      '21'
    ],
    correctAnswer: '3306'
  },
  {
    id: 4,
    question: 'What does SQL stand for?',
    options: [
      'Structured Query Language',
      'Standard Query Language',
      'Sequential Query Language',
      'Structured Question Language'
    ],
    correctAnswer: 'Structured Query Language'
  },
  {
    id: 5,
    question: 'Which command is used to create a new database in MySQL?',
    options: [
      'CREATE DATABASE',
      'NEW DATABASE',
      'ADD DATABASE',
      'MAKE DATABASE'
    ],
    correctAnswer: 'CREATE DATABASE'
  },
  {
    id: 6,
    question: 'What is the command to select a database in MySQL?',
    options: [
      'USE',
      'SELECT',
      'SHOW',
      'DISPLAY'
    ],
    correctAnswer: 'USE'
  },
  {
    id: 7,
    question: 'Which data type is used to store a date in MySQL?',
    options: [
      'DATE',
      'DATETIME',
      'TIMESTAMP',
      'TIME'
    ],
    correctAnswer: 'DATE'
  },
  {
    id: 8,
    question: 'What is the command to insert data into a table in MySQL?',
    options: [
      'INSERT INTO',
      'ADD INTO',
      'PUT INTO',
      'PUSH INTO'
    ],
    correctAnswer: 'INSERT INTO'
  },
  {
    id: 9,
    question: 'Which command is used to update data in a table in MySQL?',
    options: [
      'UPDATE',
      'MODIFY',
      'ALTER',
      'CHANGE'
    ],
    correctAnswer: 'UPDATE'
  },
  {
    id: 10,
    question: 'What is the command to delete data from a table in MySQL?',
    options: [
      'DELETE FROM',
      'REMOVE FROM',
      'DROP FROM',
      'ERASE FROM'
    ],
    correctAnswer: 'DELETE FROM'
  },
  {
    id: 11,
    question: 'Which command is used to create a new table in MySQL?',
    options: [
      'CREATE TABLE',
      'NEW TABLE',
      'ADD TABLE',
      'MAKE TABLE'
    ],
    correctAnswer: 'CREATE TABLE'
  },
  {
    id: 12,
    question: 'What is the command to add a new column to an existing table in MySQL?',
    options: [
      'ALTER TABLE',
      'ADD COLUMN',
      'INSERT COLUMN',
      'MODIFY COLUMN'
    ],
    correctAnswer: 'ALTER TABLE'
  },
  {
    id: 13,
    question: 'Which command is used to delete a table in MySQL?',
    options: [
      'DROP TABLE',
      'DELETE TABLE',
      'REMOVE TABLE',
      'ERASE TABLE'
    ],
    correctAnswer: 'DROP TABLE'
  },
  {
    id: 14,
    question: 'What is the purpose of the SELECT statement in MySQL?',
    options: [
      'To update data in a table',
      'To delete data from a table',
      'To retrieve data from a database',
      'To create a new database'
    ],
    correctAnswer: 'To retrieve data from a database'
  },
  {
    id: 15,
    question: 'What does the LIKE operator do in MySQL?',
    options: [
      'Selects all records where a column is NULL',
      'Selects all records where a column matches a specified pattern',
      'Selects all records where a column is not NULL',
      'Selects all records where a column is equal to a specified value'
    ],
    correctAnswer: 'Selects all records where a column matches a specified pattern'
  },
  {
    id: 16,
    question: 'What is the purpose of the ORDER BY clause in MySQL?',
    options: [
      'To group rows that have the same values into summary rows',
      'To select only distinct values from a table',
      'To sort the result set in ascending or descending order',
      'To filter the result set based on a specified condition'
    ],
    correctAnswer: 'To sort the result set in ascending or descending order'
  },
  {
    id: 17,
    question: 'What does the GROUP BY clause do in MySQL?',
    options: [
      'Groups rows that have the same values into summary rows',
      'Selects only distinct values from a table',
      'Sorts the result set in ascending or descending order',
      'Filters the result set based on a specified condition'
    ],
    correctAnswer: 'Groups rows that have the same values into summary rows'
  },
  {
    id: 18,
    question: 'Which command is used to grant privileges to a user in MySQL?',
    options: [
      'GRANT',
      'ALLOW',
      'PERMIT',
      'AUTHORIZE'
    ],
    correctAnswer: 'GRANT'
  },
  {
    id: 19,
    question: 'What is the purpose of the WHERE clause in MySQL?',
    options: [
      'To group rows that have the same values into summary rows',
      'To filter the result set based on a specified condition',
      'To sort the result set in ascending or descending order',
      'To select only distinct values from a table'
    ],
    correctAnswer: 'To filter the result set based on a specified condition'
  },
  {
    id: 20,
    question: 'What is the purpose of the LIMIT clause in MySQL?',
    options: [
      'To limit the number of rows returned in the result set',
      'To group rows that have the same values into summary rows',
      'To sort the result set in ascending or descending order',
      'To select only distinct values from a table'
    ],
    correctAnswer: 'To limit the number of rows returned in the result set'
  },
  {
    id: 21,
    question: 'What is a primary key in MySQL?',
    options: [
      'A unique identifier for each row in a table',
      'A key used to join two tables together',
      'A key used to sort the result set',
      'A key used to group rows into summary rows'
    ],
    correctAnswer: 'A unique identifier for each row in a table'
  },
  {
    id: 22,
    question: 'What is a foreign key in MySQL?',
    options: [
      'A key used to join two tables together',
      'A unique identifier for each row in a table',
      'A key used to sort the result set',
      'A key used to group rows into summary rows'
    ],
    correctAnswer: 'A key used to join two tables together'
  },
  {
    id: 23,
    question: 'What is the purpose of the BETWEEN operator in MySQL?',
    options: [
      'To select only distinct values from a table',
      'To filter the result set based on a range of values',
      'To sort the result set in ascending or descending order',
      'To group rows that have the same values into summary rows'
    ],
    correctAnswer: 'To filter the result set based on a range of values'
  },
  {
    id: 24,
    question: 'What does the COUNT() function do in MySQL?',
    options: [
      'Counts the number of rows in a table',
      'Calculates the average value in a column',
      'Finds the maximum value in a column',
      'Sorts the result set in ascending or descending order'
    ],
    correctAnswer: 'Counts the number of rows in a table'
  },
  {
    id: 25,
    question: 'What is the purpose of the JOIN clause in MySQL?',
    options: [
      'To filter the result set based on a specified condition',
      'To group rows that have the same values into summary rows',
      'To combine rows from two or more tables based on a related column',
      'To limit the number of rows returned in the result set'
    ],
    correctAnswer: 'To combine rows from two or more tables based on a related column'
  },
  {
    "id": 26,
    "question": "What is the command to rename a table in MySQL?",
    "options": [
      "RENAME TABLE",
      "ALTER TABLE",
      "MODIFY TABLE",
      "CHANGE TABLE"
    ],
    "correctAnswer": "RENAME TABLE"
  },
  {
    "id": 27,
    "question": "Which operator is used for pattern matching in MySQL?",
    "options": [
      "LIKE",
      "MATCH",
      "PATTERN",
      "SEARCH"
    ],
    "correctAnswer": "LIKE"
  },
  {
    "id": 28,
    "question": "Which data type is used for storing large text in MySQL?",
    "options": [
      "TEXT",
      "VARCHAR",
      "CHAR",
      "STRING"
    ],
    "correctAnswer": "TEXT"
  },
  {
    "id": 29,
    "question": "Which statement is used to remove all records from a table in MySQL without deleting the table itself?",
    "options": [
      "TRUNCATE",
      "DELETE",
      "DROP",
      "REMOVE"
    ],
    "correctAnswer": "TRUNCATE"
  },
  {
    "id": 30,
    "question": "Which MySQL command is used to modify an existing column in a table?",
    "options": [
      "ALTER COLUMN",
      "CHANGE COLUMN",
      "MODIFY COLUMN",
      "UPDATE COLUMN"
    ],
    "correctAnswer": "CHANGE COLUMN"
  },
  {
    "id": 31,
    "question": "Which data type is used for storing monetary values in MySQL?",
    "options": [
      "DECIMAL",
      "NUMERIC",
      "FLOAT",
      "MONEY"
    ],
    "correctAnswer": "DECIMAL"
  },
  {
    "id": 32,
    "question": "Which function is used to return the current date in MySQL?",
    "options": [
      "NOW()",
      "CURDATE()",
      "GETDATE()",
      "CURRENT_DATE()"
    ],
    "correctAnswer": "CURDATE()"
  },
  {
    "id": 33,
    "question": "What is the default sorting order of the ORDER BY clause in MySQL?",
    "options": [
      "Descending",
      "Ascending",
      "Random",
      "None"
    ],
    "correctAnswer": "Ascending"
  },
  {
    "id": 34,
    "question": "Which clause is used to remove duplicates in a SELECT query in MySQL?",
    "options": [
      "DISTINCT",
      "UNIQUE",
      "REMOVE",
      "NO_DUPLICATES"
    ],
    "correctAnswer": "DISTINCT"
  },
  {
    "id": 35,
    "question": "Which command is used to give users permission to access a database in MySQL?",
    "options": [
      "GRANT",
      "ALLOW",
      "PERMIT",
      "AUTHORIZE"
    ],
    "correctAnswer": "GRANT"
  },
  {
    "id": 36,
    "question": "Which MySQL function is used to get the length of a string?",
    "options": [
      "LEN()",
      "LENGTH()",
      "STRLEN()",
      "SIZE()"
    ],
    "correctAnswer": "LENGTH()"
  },
  {
    "id": 37,
    "question": "Which command is used to retrieve the list of databases in MySQL?",
    "options": [
      "SHOW DATABASES",
      "LIST DATABASES",
      "DISPLAY DATABASES",
      "VIEW DATABASES"
    ],
    "correctAnswer": "SHOW DATABASES"
  },
  {
    "id": 38,
    "question": "Which clause is used in MySQL to filter records based on a range of values?",
    "options": [
      "BETWEEN",
      "RANGE",
      "IN",
      "WITHIN"
    ],
    "correctAnswer": "BETWEEN"
  },
  {
    "id": 39,
    "question": "What is the purpose of the HAVING clause in MySQL?",
    "options": [
      "To filter data after grouping",
      "To sort the data",
      "To join tables",
      "To select specific columns"
    ],
    "correctAnswer": "To filter data after grouping"
  },
  {
    "id": 40,
    "question": "Which of the following is a non-relational database?",
    "options": [
      "MySQL",
      "MongoDB",
      "PostgreSQL",
      "SQLite"
    ],
    "correctAnswer": "MongoDB"
  },
  {
    "id": 41,
    "question": "Which command is used to change the value of an existing column in a table in MySQL?",
    "options": [
      "UPDATE",
      "MODIFY",
      "ALTER",
      "SET"
    ],
    "correctAnswer": "ALTER"
  },
  {
    "id": 42,
    "question": "Which function is used to find the largest value in a column in MySQL?",
    "options": [
      "MAX()",
      "LARGEST()",
      "BIG()",
      "TOP()"
    ],
    "correctAnswer": "MAX()"
  },
  {
    "id": 43,
    "question": "What is a trigger in MySQL?",
    "options": [
      "A mechanism to automatically perform an action before or after an event",
      "A type of join",
      "A constraint used for data validation",
      "A type of function"
    ],
    "correctAnswer": "A mechanism to automatically perform an action before or after an event"
  },
  {
    "id": 44,
    "question": "Which function is used to count the number of rows in a table in MySQL?",
    "options": [
      "COUNT()",
      "SUM()",
      "ROWS()",
      "TOTAL()"
    ],
    "correctAnswer": "COUNT()"
  },
  {
    "id": 45,
    "question": "Which of the following is a correct syntax for creating a primary key in MySQL?",
    "options": [
      "PRIMARY KEY (column_name)",
      "KEY PRIMARY (column_name)",
      "COLUMN PRIMARY (column_name)",
      "CREATE PRIMARY KEY (column_name)"
    ],
    "correctAnswer": "PRIMARY KEY (column_name)"
  },
  {
    "id": 46,
    "question": "Which command is used to delete a database in MySQL?",
    "options": [
      "DROP DATABASE",
      "REMOVE DATABASE",
      "DELETE DATABASE",
      "CLEAR DATABASE"
    ],
    "correctAnswer": "DROP DATABASE"
  },
  {
    "id": 47,
    "question": "Which function is used to round off a number in MySQL?",
    "options": [
      "ROUND()",
      "TRUNCATE()",
      "FLOOR()",
      "CEIL()"
    ],
    "correctAnswer": "ROUND()"
  },
  {
    "id": 48,
    "question": "Which command is used to add a new row of data to a table in MySQL?",
    "options": [
      "INSERT INTO",
      "ADD TO",
      "INSERT VALUES",
      "PUT INTO"
    ],
    "correctAnswer": "INSERT INTO"
  },
  {
    "id": 49,
    "question": "What does the DISTINCT keyword do in MySQL?",
    "options": [
      "Removes duplicate records from the result set",
      "Sorts the result set",
      "Filters records based on a condition",
      "Selects only the first record"
    ],
    "correctAnswer": "Removes duplicate records from the result set"
  },
  {
    "id": 50,
    "question": "Which function is used to return the current time in MySQL?",
    "options": [
      "CURRENT_TIME()",
      "TIME()",
      "NOW()",
      "GETTIME()"
    ],
    "correctAnswer": "CURRENT_TIME()"
  }

];






const Mysql = () => {

     const [shuffledQuestions, setShuffledQuestions] = useState([]);
     const [currentQuestion, setCurrentQuestion] = useState(0);
     const [selectedOptions, setSelectedOptions] = useState(new Array(questions.length).fill(''));
     const [score, setScore] = useState(0);
     const [showScore, setShowScore] = useState(false);
     const [timeLeft, setTimeLeft] = useState(1500); // 25 minutes in seconds
     const [attemptedQuestions, setAttemptedQuestions] = useState(0);
     const [timerColor, setTimerColor] = useState('#00FF00'); // Initial color green
     const [timerPaused, setTimerPaused] = useState(false); // State to track timer pause/resume


     useEffect(() => {
       // Shuffle the questions array randomly when the component mounts
       const shuffleArray = (array) => {
         return array.sort(() => Math.random() - 0.5);
       };
       setShuffledQuestions(shuffleArray(questions));

       // Scroll to the element with the ID 'css' when the component mounts
       const element = document.getElementById('html');
       if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
       }
     }, []); // Empty dependency array ensures this effect runs only once after the component mounts

     useEffect(() => {
       const timer = setTimeout(() => {
         if (!timerPaused) { // Only decrement time if timer is not paused
           setTimeLeft(timeLeft - 1);

           // Update timer color based on remaining time
           if (timeLeft <= 300 && timeLeft > 120) {
             setTimerColor('#FFFF00'); // Yellow
           } else if (timeLeft <= 120) {
             setTimerColor('darkred'); // Red
           }

           // Check if time is up
           if (timeLeft === 0) {
             setShowScore(true);
           }
         }
       }, 1000);

       return () => clearTimeout(timer);
     }, [timeLeft, timerPaused]);

     const handleOptionSelect = (option) => {
       // Check if the selected option is different from the previously selected option
       if (selectedOptions[currentQuestion] !== option) {
         const newSelectedOptions = [...selectedOptions];
         newSelectedOptions[currentQuestion] = option;
         setSelectedOptions(newSelectedOptions);

         if (!selectedOptions[currentQuestion]) {
           // Increment attempted questions only if the current question didn't have an attempt before
           setAttemptedQuestions(attemptedQuestions + 1);
         }

         if (option === shuffledQuestions[currentQuestion].correctAnswer) {
           setScore(score + 1);
         }
       }
     };

     const handleNextQuestion = () => {
       if (currentQuestion < shuffledQuestions.length - 1) {
         setCurrentQuestion(currentQuestion + 1);
       } else {
         setShowScore(true);
       }
     };

     const handlePreviousQuestion = () => {
       if (currentQuestion > 0) {
         setCurrentQuestion(currentQuestion - 1);
       }
     };

     const handlePauseResumeTimer = () => {
       setTimerPaused(!timerPaused); // Toggle timer pause/resume state
     };

  return (
    <div className="container mx-auto p-4" id='sql' style={{ backgroundColor: '#F4F4F6', margin: '5rem', borderRadius: '15px' }}>
      {!showScore ? (
                <div className='relative isolate overflow-hidden bg-gray-900' style={{  borderRadius: '10px', color: 'white' }}>
          <h1 className="text-3xl font-bold text-center mb-8" style={{ margin: '1rem' }}>MySQL MOCK TEST<br /><span style={{ color: 'darkred', fontSize: '22px' }}>Dream Adoration</span></h1>
          <div style={{ margin: '2rem' }}>
            <h2 className="text-2xl font-medium mb-4">Question {currentQuestion + 1} of {questions.length}</h2>
            <h3 className="text-lg font-semibold mb-2">{questions[currentQuestion].question}</h3>
            {questions[currentQuestion].options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestion] === option}
                    onChange={() => handleOptionSelect(option)}
                    className="form-radio h-5 w-5 text-primary-600"
                  />
                  <span className="ml-2 ">{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-around">
            <button
              onClick={handlePreviousQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-3 rounded mt-5"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded mt-5"
            >
              Next
            </button>
            <button
              onClick={handlePauseResumeTimer}
              className="bg-green-600 hover:bg-green-700 text-black font-bold py-2 px-4 rounded mt-5"
            >
              {timerPaused ? 'Resume' : 'Pause'}
            </button>
          </div>
          <div className=" mt-1 text-center" style={{ color: timerColor, fontWeight: 'bold' }}>Time Left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}</div>
          <div className=" mt-1 text-center" style={{ fontWeight: 'bold', padding: '10px' }}>Attempted Questions: {attemptedQuestions} / {questions.length}</div>
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <h3 className="text-lg font-medium mb-2">Your Score: {score}/{questions.length}</h3>
        </div>
      )}
    </div>
  );
};

export default Mysql;
