import React, { useState, useEffect } from "react";

const questions = [
  {
    id: 1,
    question: 'What is the default value of a boolean variable in Java?',
    options: ['true', 'false', '0', 'null'],
    correctAnswer: 'false'
  },
  {
    id: 2,
    question: 'Which of the following is a valid declaration for an array in Java?',
    options: ['int[] arr;', 'int arr[];', 'Both of the above', 'None of the above'],
    correctAnswer: 'Both of the above'
  },
  {
    id: 3,
    question: 'Which keyword is used to define a class in Java?',
    options: ['class', 'define', 'object', 'struct'],
    correctAnswer: 'class'
  },
  {
    id: 4,
    question: 'What is the size of an int in Java?',
    options: ['2 bytes', '4 bytes', '8 bytes', '16 bytes'],
    correctAnswer: '4 bytes'
  },
  {
    id: 5,
    question: 'Which of the following is the parent class of all classes in Java?',
    options: ['Object', 'String', 'Class', 'Serializable'],
    correctAnswer: 'Object'
  },
  {
    id: 6,
    question: 'Which method is used to start a thread in Java?',
    options: ['start()', 'run()', 'init()', 'execute()'],
    correctAnswer: 'start()'
  },
  {
    id: 7,
    question: 'Which of the following is used to declare a constant in Java?',
    options: ['final', 'constant', 'static', 'immutable'],
    correctAnswer: 'final'
  },
  {
    id: 8,
    question: 'Which of these is not a valid access modifier in Java?',
    options: ['private', 'protected', 'secure', 'public'],
    correctAnswer: 'secure'
  },
  {
    id: 9,
    question: 'What does JVM stand for?',
    options: ['Java Visual Machine', 'Java Virtual Machine', 'Java Variable Machine', 'None of the above'],
    correctAnswer: 'Java Virtual Machine'
  },
  {
    id: 10,
    question: 'Which package is imported by default in every Java program?',
    options: ['java.lang', 'java.util', 'java.io', 'None of the above'],
    correctAnswer: 'java.lang'
  },
  {
    id: 11,
    question: 'What is the default value of a reference variable in Java?',
    options: ['null', '0', 'undefined', 'empty'],
    correctAnswer: 'null'
  },
  {
    id: 12,
    question: 'Which of these is not a valid keyword in Java?',
    options: ['abstract', 'static', 'const', 'void'],
    correctAnswer: 'const'
  },
  {
    id: 13,
    question: 'Which of the following is used to handle exceptions in Java?',
    options: ['try-catch', 'throw-catch', 'try-throw', 'catch-finally'],
    correctAnswer: 'try-catch'
  },
  {
    id: 14,
    question: 'Which of the following statements is used to terminate a loop in Java?',
    options: ['exit', 'break', 'stop', 'terminate'],
    correctAnswer: 'break'
  },
  {
    id: 15,
    question: 'Which of these is the correct syntax to create a thread in Java?',
    options: ['Thread t = new Thread();', 'Thread t = Thread();', 'Thread t = new Runnable();', 'Thread t = createThread();'],
    correctAnswer: 'Thread t = new Thread();'
  },
  {
    id: 16,
    question: 'Which class is used to handle input from the user in Java?',
    options: ['Scanner', 'InputStream', 'Reader', 'BufferedReader'],
    correctAnswer: 'Scanner'
  },
  {
    id: 17,
    question: 'What is the default value of a char variable in Java?',
    options: ['0', 'null', '""', '\u0000'],
    correctAnswer: '\u0000'
  },
  {
    id: 18,
    question: 'Which of these is a primitive data type in Java?',
    options: ['String', 'Array', 'int', 'Object'],
    correctAnswer: 'int'
  },
  {
    id: 19,
    question: 'Which of the following is used to create a package in Java?',
    options: ['package', 'import', 'namespace', 'define'],
    correctAnswer: 'package'
  },
  {
    id: 20,
    question: 'Which of the following is not a valid constructor in Java?',
    options: ['public MyClass()', 'private MyClass()', 'protected MyClass()', 'default MyClass()'],
    correctAnswer: 'default MyClass()'
  },
  {
    id: 21,
    question: 'Which method is used to compare two strings in Java?',
    options: ['==', 'equals()', 'compare()', 'compareTo()'],
    correctAnswer: 'equals()'
  },
  {
    id: 22,
    question: 'Which of the following is not a valid method signature in Java?',
    options: ['public static void main(String[] args)', 'private void main(String args[])', 'public static void main(String args)', 'protected void main(String args[])'],
    correctAnswer: 'private void main(String args[])'
  },
  {
    id: 23,
    question: 'Which class is used for writing data to a file in Java?',
    options: ['FileWriter', 'FileReader', 'BufferedReader', 'BufferedWriter'],
    correctAnswer: 'FileWriter'
  },
  {
    id: 24,
    question: 'Which of the following exceptions is thrown when an array is accessed with an invalid index?',
    options: ['ArrayIndexOutOfBoundsException', 'NullPointerException', 'ArithmeticException', 'FileNotFoundException'],
    correctAnswer: 'ArrayIndexOutOfBoundsException'
  },
  {
    id: 25,
    question: 'Which of the following is a wrapper class in Java?',
    options: ['int', 'String', 'Integer', 'char'],
    correctAnswer: 'Integer'
  },
  {
    id: 26,
    question: 'What is the purpose of the "super" keyword in Java?',
    options: ['To call a superclass method', 'To define a superclass', 'To instantiate a subclass', 'None of the above'],
    correctAnswer: 'To call a superclass method'
  },
  {
    id: 27,
    question: 'Which of the following is used to implement multiple interfaces in Java?',
    options: ['extends', 'implements', 'interface', 'None of the above'],
    correctAnswer: 'implements'
  },
  {
    id: 28,
    question: 'Which of these methods is used to sort an array in Java?',
    options: ['sort()', 'arraySort()', 'Arrays.sort()', 'Collections.sort()'],
    correctAnswer: 'Arrays.sort()'
  },
  {
    id: 29,
    question: 'Which keyword is used to prevent a class from being subclassed in Java?',
    options: ['final', 'static', 'private', 'abstract'],
    correctAnswer: 'final'
  },
  {
    id: 30,
    question: 'Which of the following collections implements the List interface in Java?',
    options: ['HashSet', 'LinkedList', 'TreeSet', 'PriorityQueue'],
    correctAnswer: 'LinkedList'
  },
  {
    id: 31,
    question: 'Which of these statements is true about method overloading in Java?',
    options: ['Methods can have the same name but must have different return types.', 'Methods can have the same name but must have different parameters.', 'Methods must have different names.', 'None of the above'],
    correctAnswer: 'Methods can have the same name but must have different parameters.'
  },
  {
    id: 32,
    question: 'Which of these is used to create a new thread in Java?',
    options: ['Runnable', 'Thread', 'Callable', 'Task'],
    correctAnswer: 'Thread'
  },
  {
    id: 33,
    question: 'What does the "this" keyword refer to in Java?',
    options: ['The current class object', 'A reference to the parent class object', 'A reference to the static method', 'None of the above'],
    correctAnswer: 'The current class object'
  },
  {
    id: 34,
    question: 'Which of these is used to create a HashMap in Java?',
    options: ['new HashMap()', 'new Map()', 'new Hashtable()', 'new LinkedHashMap()'],
    correctAnswer: 'new HashMap()'
  },
  {
    id: 35,
    question: 'What is the default initial capacity of a HashMap in Java?',
    options: ['16', '32', '64', '128'],
    correctAnswer: '16'
  },
  {
    id: 36,
    question: 'Which of these is used to define a generic class in Java?',
    options: ['T', 'E', 'K', 'V'],
    correctAnswer: 'T'
  },
  {
    id: 37,
    question: 'Which of these interfaces is used to implement a queue in Java?',
    options: ['Set', 'List', 'Map', 'Queue'],
    correctAnswer: 'Queue'
  },
  {
    id: 38,
    question: 'Which of these statements is true about Java interfaces?',
    options: ['Interfaces can have instance variables.', 'Interfaces can have constructors.', 'Interfaces cannot have method bodies.', 'Interfaces can implement methods.'],
    correctAnswer: 'Interfaces cannot have method bodies.'
  },
  {
    id: 39,
    question: 'What is the default value of an instance variable in Java?',
    options: ['0', 'null', 'undefined', 'default'],
    correctAnswer: '0'
  },
  {
    id: 40,
    question: 'Which of the following is a valid constructor for the String class in Java?',
    options: ['String s = new String("Hello");', 'String s = new String(Hello);', 'String s = new String(new char[10]);', 'All of the above'],
    correctAnswer: 'All of the above'
  },
  {
    id: 41,
    question: 'Which of the following is used to create an anonymous class in Java?',
    options: ['class', 'interface', 'new', 'abstract'],
    correctAnswer: 'new'
  },
  {
    id: 42,
    question: 'What is the purpose of the "finally" block in exception handling?',
    options: ['It is used to execute code that must run after a try-catch block.', 'It is used to catch exceptions.', 'It is used to throw exceptions.', 'It is used to ignore exceptions.'],
    correctAnswer: 'It is used to execute code that must run after a try-catch block.'
  },
  {
    id: 43,
    question: 'Which of the following is true about Java?',
    options: ['It is a compiled language.', 'It is an interpreted language.', 'It is a hybrid of compiled and interpreted language.', 'None of the above'],
    correctAnswer: 'It is a hybrid of compiled and interpreted language.'
  },
  {
    id: 44,
    question: 'Which of the following methods can be used to stop a thread in Java?',
    options: ['stop()', 'exit()', 'destroy()', 'Thread.stop()'],
    correctAnswer: 'stop()'
  },
  {
    id: 45,
    question: 'Which of the following is used to create an array in Java?',
    options: ['new int[10]', 'int[10] array', 'array = new int()', 'new int[]'],
    correctAnswer: 'new int[10]'
  },
  {
    id: 46,
    question: 'Which of the following is true about a constructor in Java?',
    options: ['A constructor can have a return type.', 'A constructor must have the same name as the class.', 'A constructor can be inherited.', 'A constructor is called explicitly by the user.'],
    correctAnswer: 'A constructor must have the same name as the class.'
  },
  {
    id: 47,
    question: 'Which method is used to get the length of a string in Java?',
    options: ['length()', 'getLength()', 'size()', 'None of the above'],
    correctAnswer: 'length()'
  },
  {
    id: 48,
    question: 'Which of the following can be used to create a new thread in Java?',
    options: ['Runnable', 'Thread', 'Executor', 'Callable'],
    correctAnswer: 'Thread'
  },
  {
    id: 49,
    question: 'Which method is used to check if a file exists in Java?',
    options: ['file.exists()', 'check.exists()', 'exists()', 'is.exists()'],
    correctAnswer: 'file.exists()'
  },
  {
    id: 50,
    question: 'Which of the following statements is true about the Java String class?',
    options: ['String is mutable.', 'String is immutable.', 'String can be changed using StringBuffer.', 'String is not a class.'],
    correctAnswer: 'String is immutable.'
  }

];

const JavaTest = () => {

      const [shuffledQuestions, setShuffledQuestions] = useState([]);
      const [currentQuestion, setCurrentQuestion] = useState(0);
      const [selectedOptions, setSelectedOptions] = useState(new Array(questions.length).fill(''));
      const [score, setScore] = useState(0);
      const [showScore, setShowScore] = useState(false);
      const [timeLeft, setTimeLeft] = useState(1500); // 25 minutes in seconds
      const [attemptedQuestions, setAttemptedQuestions] = useState(0);
      const [timerColor, setTimerColor] = useState('#00FF00'); // Initial color green
      const [timerPaused, setTimerPaused] = useState(false); // State to track timer pause/resume


      useEffect(() => {
        // Shuffle the questions array randomly when the component mounts
        const shuffleArray = (array) => {
          return array.sort(() => Math.random() - 0.5);
        };
        setShuffledQuestions(shuffleArray(questions));

        // Scroll to the element with the ID 'css' when the component mounts
        const element = document.getElementById('html');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, []); // Empty dependency array ensures this effect runs only once after the component mounts

      useEffect(() => {
        const timer = setTimeout(() => {
          if (!timerPaused) { // Only decrement time if timer is not paused
            setTimeLeft(timeLeft - 1);

            // Update timer color based on remaining time
            if (timeLeft <= 300 && timeLeft > 120) {
              setTimerColor('#FFFF00'); // Yellow
            } else if (timeLeft <= 120) {
              setTimerColor('darkred'); // Red
            }

            // Check if time is up
            if (timeLeft === 0) {
              setShowScore(true);
            }
          }
        }, 1000);

        return () => clearTimeout(timer);
      }, [timeLeft, timerPaused]);

      const handleOptionSelect = (option) => {
        // Check if the selected option is different from the previously selected option
        if (selectedOptions[currentQuestion] !== option) {
          const newSelectedOptions = [...selectedOptions];
          newSelectedOptions[currentQuestion] = option;
          setSelectedOptions(newSelectedOptions);

          if (!selectedOptions[currentQuestion]) {
            // Increment attempted questions only if the current question didn't have an attempt before
            setAttemptedQuestions(attemptedQuestions + 1);
          }

          if (option === shuffledQuestions[currentQuestion].correctAnswer) {
            setScore(score + 1);
          }
        }
      };

      const handleNextQuestion = () => {
        if (currentQuestion < shuffledQuestions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
        } else {
          setShowScore(true);
        }
      };

      const handlePreviousQuestion = () => {
        if (currentQuestion > 0) {
          setCurrentQuestion(currentQuestion - 1);
        }
      };

      const handlePauseResumeTimer = () => {
        setTimerPaused(!timerPaused); // Toggle timer pause/resume state
      };

  return (
    <div
      className="container mx-auto p-4"
      id="java"
      style={{
        backgroundColor: "#F4F4F6",
        margin: "5rem",
        borderRadius: "15px",
      }}
    >
      {!showScore ? (
        <div
          className="relative isolate overflow-hidden bg-gray-900"
          style={{ borderRadius: "10px", color: "white" }}
        >
          <h1
            className="text-3xl font-bold text-center mb-8"
            style={{ margin: "1rem" }}
          >
            Java Mock Test
            <br />
            <span style={{ color: "darkred", fontSize: "22px" }}>
              Dream Adoration
            </span>
          </h1>
          <div style={{ margin: "2rem" }}>
            <h2 className="text-2xl font-medium mb-4">
              Question {currentQuestion + 1} of {questions.length}
            </h2>
            <h3 className="text-lg font-semibold mb-2">
              {questions[currentQuestion].question}
            </h3>
            {questions[currentQuestion].options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestion] === option}
                    onChange={() => handleOptionSelect(option)}
                    className="form-radio h-5 w-5 text-primary-600"
                  />
                  <span className="ml-2 ">{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-around">
            <button
              onClick={handlePreviousQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-3 rounded mt-5"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded mt-5"
            >
              Next
            </button>
            <button
              onClick={handlePauseResumeTimer}
              className="bg-green-600 hover:bg-green-700 text-black font-bold py-2 px-4 rounded mt-5"
            >
              {timerPaused ? "Resume" : "Pause"}
            </button>
          </div>
          <div
            className=" mt-1 text-center"
            style={{ color: timerColor, fontWeight: "bold" }}
          >
            Time Left: {Math.floor(timeLeft / 60)}:
            {(timeLeft % 60).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}
          </div>
          <div
            className=" mt-1 text-center"
            style={{ fontWeight: "bold", padding: "10px" }}
          >
            Attempted Questions: {attemptedQuestions} / {questions.length}
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <h3 className="text-lg font-medium mb-2">
            Your Score: {score}/{questions.length}
          </h3>
        </div>
      )}
    </div>
  );
};

export default JavaTest;
