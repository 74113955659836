import React, { useState, useEffect } from "react";

const questions = [
    {
      id: 1,
      question: "What is MongoDB?",
      options: [
        "A NoSQL database",
        "A relational database",
        "A programming language",
        "A data visualization tool"
      ],
      correctAnswer: "A NoSQL database"
    },
    {
      id: 2,
      question: "Which data format does MongoDB use to store data?",
      options: ["JSON", "BSON", "XML", "CSV"],
      correctAnswer: "BSON"
    },
    {
      id: 3,
      question: "What is a collection in MongoDB?",
      options: [
        "A group of documents",
        "A single document",
        "A set of tables",
        "A database instance"
      ],
      correctAnswer: "A group of documents"
    },
    {
      id: 4,
      question: "Which command is used to show all databases in MongoDB?",
      options: ["show dbs", "list databases", "db.show()", "dbs.list()"],
      correctAnswer: "show dbs"
    },
    {
      id: 5,
      question: "What is the default port for MongoDB?",
      options: ["27017", "8080", "3306", "5432"],
      correctAnswer: "27017"
    },
    {
      id: 6,
      question: "What is the purpose of the _id field in MongoDB?",
      options: [
        "To uniquely identify a document",
        "To store metadata",
        "To manage collections",
        "To index the database"
      ],
      correctAnswer: "To uniquely identify a document"
    },
    {
      id: 7,
      question: "How do you insert a single document into a collection?",
      options: [
        "db.collection.insertOne(document)",
        "db.collection.add(document)",
        "db.collection.save(document)",
        "db.collection.put(document)"
      ],
      correctAnswer: "db.collection.insertOne(document)"
    },
    {
      id: 8,
      question: "Which method is used to retrieve all documents in a collection?",
      options: [
        "db.collection.find()",
        "db.collection.getAll()",
        "db.collection.list()",
        "db.collection.retrieve()"
      ],
      correctAnswer: "db.collection.find()"
    },
    {
      id: 9,
      question: "How do you delete a document in MongoDB?",
      options: [
        "db.collection.deleteOne(filter)",
        "db.collection.remove(filter)",
        "db.collection.erase(filter)",
        "db.collection.drop(filter)"
      ],
      correctAnswer: "db.collection.deleteOne(filter)"
    },
    {
      id: 10,
      question: "What does sharding in MongoDB do?",
      options: [
        "Distributes data across multiple servers",
        "Indexes documents in a collection",
        "Converts data to BSON format",
        "Creates backups of the database"
      ],
      correctAnswer: "Distributes data across multiple servers"
    },
    {
      id: 11,
      question: "What is the aggregation framework in MongoDB used for?",
      options: [
        "Data analysis and transformation",
        "Database replication",
        "User authentication",
        "Creating backups"
      ],
      correctAnswer: "Data analysis and transformation"
    },
    {
      id: 12,
      question: "Which command is used to create an index in MongoDB?",
      options: [
        "db.collection.createIndex()",
        "db.collection.addIndex()",
        "db.collection.newIndex()",
        "db.collection.index()"
      ],
      correctAnswer: "db.collection.createIndex()"
    },
    {
      id: 13,
      question: "What is the purpose of replication in MongoDB?",
      options: [
        "To ensure data availability and redundancy",
        "To optimize query performance",
        "To distribute data across shards",
        "To export data to JSON"
      ],
      correctAnswer: "To ensure data availability and redundancy"
    },
    {
      id: 14,
      question: "Which command is used to check the current database in MongoDB?",
      options: ["db", "currentDb", "show db", "use db"],
      correctAnswer: "db"
    },
    {
      id: 15,
      question: "What does the findOne() method do in MongoDB?",
      options: [
        "Retrieves a single document matching the query",
        "Retrieves all documents in a collection",
        "Deletes a single document",
        "Creates a new document"
      ],
      correctAnswer: "Retrieves a single document matching the query"
    },
    {
      id: 16,
      question: "What is a capped collection in MongoDB?",
      options: [
        "A fixed-size collection that overwrites its oldest data",
        "A collection with a maximum number of documents",
        "A read-only collection",
        "A collection with a unique index"
      ],
      correctAnswer: "A fixed-size collection that overwrites its oldest data"
    },
    {
      id: 17,
      question: "Which operator is used for pattern matching in MongoDB?",
      options: ["$regex", "$pattern", "$match", "$like"],
      correctAnswer: "$regex"
    },
    {
      id: 18,
      question: "How do you update multiple documents in MongoDB?",
      options: [
        "db.collection.updateMany(filter, update)",
        "db.collection.modify(filter, update)",
        "db.collection.edit(filter, update)",
        "db.collection.replace(filter, update)"
      ],
      correctAnswer: "db.collection.updateMany(filter, update)"
    },
    {
      id: 19,
      question: "What is a primary key in MongoDB?",
      options: [
        "The _id field",
        "The first field in a document",
        "The index of the collection",
        "A custom field specified by the user"
      ],
      correctAnswer: "The _id field"
    },
    {
      id: 20,
      question: "What is GridFS in MongoDB used for?",
      options: [
        "Storing and retrieving large files",
        "Querying relational data",
        "Sharding collections",
        "Exporting documents"
      ],
      correctAnswer: "Storing and retrieving large files"
    },
    {
      id: 21,
      question: "Which command is used to drop a database in MongoDB?",
      options: [
        "db.dropDatabase()",
        "db.removeDatabase()",
        "db.deleteDatabase()",
        "db.clearDatabase()"
      ],
      correctAnswer: "db.dropDatabase()"
    },
    {
      id: 22,
      question: "What is the difference between embedded and referenced documents in MongoDB?",
      options: [
        "Embedded documents are nested; referenced documents link to another document",
        "Embedded documents are stored separately; referenced documents are inline",
        "There is no difference",
        "Both are only for indexing"
      ],
      correctAnswer:
        "Embedded documents are nested; referenced documents link to another document"
    },
    {
      id: 23,
      question: "How does MongoDB handle schema design?",
      options: [
        "Schema-less design",
        "Strict schema enforcement",
        "Relational schema",
        "XML-based schema"
      ],
      correctAnswer: "Schema-less design"
    },
    {
      id: 24,
      question: "What is the use of the $lookup operator in MongoDB?",
      options: [
        "Performs joins between collections",
        "Updates multiple documents",
        "Deletes collections",
        "Indexes data"
      ],
      correctAnswer: "Performs joins between collections"
    },
    {
      id: 25,
      question: "Which method is used to aggregate data in MongoDB?",
      options: [
        "db.collection.aggregate()",
        "db.collection.find()",
        "db.collection.update()",
        "db.collection.search()"
      ],
      correctAnswer: "db.collection.aggregate()"
    },
    {
      id: 26,
      question: "How do you enable authentication in MongoDB?",
      options: [
        "Start MongoDB with the --auth flag",
        "Use db.enableAuth()",
        "Set enable_auth=true in the config",
        "Authentication is enabled by default"
      ],
      correctAnswer: "Start MongoDB with the --auth flag"
    },
    {
      id: 27,
      question: "Which of the following is NOT a MongoDB query operator?",
      options: ["$eq", "$gt", "$sum", "$order"],
      correctAnswer: "$order"
    },
    {
      id: 28,
      question: "What does the explain() method do in MongoDB?",
      options: [
        "Provides details about how a query is executed",
        "Creates indexes on collections",
        "Displays metadata of a collection",
        "Validates schema design"
      ],
      correctAnswer: "Provides details about how a query is executed"
    },
    {
      id: 29,
      question: "What is the oplog in MongoDB?",
      options: [
        "A log of all operations in a replica set",
        "A tool for optimizing queries",
        "A log of errors in the database",
        "An index for large files"
      ],
      correctAnswer: "A log of all operations in a replica set"
    },
    {
      id: 30,
      question: "Which type of index supports text search in MongoDB?",
      options: [
        "Text index",
        "Hash index",
        "Compound index",
        "2dsphere index"
      ],
      correctAnswer: "Text index"
    },
    {
      id: 31,
      question: "What is the WiredTiger engine used for in MongoDB?",
      options: [
        "Storage management",
        "Authentication",
        "Indexing",
        "Networking"
      ],
      correctAnswer: "Storage management"
    },
    {
      id: 32,
      question: "How do you create a capped collection in MongoDB?",
      options: [
        "db.createCollection(name, { capped: true, size: value })",
        "db.newCappedCollection(name, value)",
        "db.collection.setCapped(name, size)",
        "db.makeCappedCollection(name, size)"
      ],
      correctAnswer: "db.createCollection(name, { capped: true, size: value })"
    },
    {
      id: 33,
      question: "What is the maximum size of a BSON document in MongoDB?",
      options: ["16 MB", "32 MB", "64 MB", "128 MB"],
      correctAnswer: "16 MB"
    },
    {
      id: 34,
      question: "What is the purpose of MongoDB Compass?",
      options: [
        "A GUI for managing and analyzing MongoDB data",
        "A tool for creating indexes",
        "A CLI tool for replication",
        "A backup tool for the database"
      ],
      correctAnswer: "A GUI for managing and analyzing MongoDB data"
    },
    {
      id: 35,
      question: "What does the $unset operator do in MongoDB?",
      options: [
        "Removes a field from a document",
        "Deletes a document",
        "Clears all data in a collection",
        "Removes indexes from a collection"
      ],
      correctAnswer: "Removes a field from a document"
    },
    {
      id: 36,
      question: "Which MongoDB utility is used for data import?",
      options: [
        "mongoimport",
        "mongoexport",
        "mongodump",
        "mongorestore"
      ],
      correctAnswer: "mongoimport"
    },
    {
      id: 37,
      question: "What is the $group operator used for in aggregation?",
      options: [
        "Groups documents by a specified field",
        "Sorts documents in ascending order",
        "Filters documents by criteria",
        "Updates grouped documents"
      ],
      correctAnswer: "Groups documents by a specified field"
    },
    {
      id: 38,
      question: "What is the function of a replica set in MongoDB?",
      options: [
        "Provides high availability and redundancy",
        "Distributes data across multiple shards",
        "Improves query performance",
        "Enables schema validation"
      ],
      correctAnswer: "Provides high availability and redundancy"
    },
    {
      id: 39,
      question: "What is the function of the $sort stage in aggregation?",
      options: [
        "Sorts the documents in a specified order",
        "Filters documents by criteria",
        "Joins documents across collections",
        "Groups documents by a field"
      ],
      correctAnswer: "Sorts the documents in a specified order"
    },
    {
      id: 40,
      question: "Which tool is used to create database backups in MongoDB?",
      options: [
        "mongodump",
        "mongoexport",
        "mongorestore",
        "mongoimport"
      ],
      correctAnswer: "mongodump"
    },
    {
      id: 41,
      question: "What is the $in operator used for in MongoDB?",
      options: [
        "Checks if a field's value is in a specified array",
        "Matches a regular expression",
        "Filters documents by specific date range",
        "Matches documents by their ID"
      ],
      correctAnswer: "Checks if a field's value is in a specified array"
    },
    {
      id: 42,
      question: "Which of the following is used for debugging MongoDB operations?",
      options: [
        "MongoDB log files",
        "MongoDB Profiler",
        "MongoDB Atlas",
        "MongoDB Inspector"
      ],
      correctAnswer: "MongoDB Profiler"
    },
    {
      id: 43,
      question: "How do you update a specific field in MongoDB?",
      options: [
        "db.collection.updateOne(filter, { $set: { field: value } })",
        "db.collection.updateOne({ field: value })",
        "db.collection.modify(filter, { field: value })",
        "db.collection.replaceOne(filter, { field: value })"
      ],
      correctAnswer: "db.collection.updateOne(filter, { $set: { field: value } })"
    },
    {
      id: 44,
      question: "What is a MongoDB shard?",
      options: [
        "A horizontal partition of data",
        "A replica of the primary database",
        "A backup of a database",
        "An index for optimizing queries"
      ],
      correctAnswer: "A horizontal partition of data"
    },
    {
      id: 45,
      question: "What is the function of the $match stage in aggregation?",
      options: [
        "Filters documents by criteria",
        "Groups documents based on a field",
        "Sorts documents in a specified order",
        "Projects specified fields from documents"
      ],
      correctAnswer: "Filters documents by criteria"
    },
    {
      id: 46,
      question: "Which MongoDB feature provides data availability even if one node goes down?",
      options: [
        "Replication",
        "Sharding",
        "Indexing",
        "Aggregation"
      ],
      correctAnswer: "Replication"
    },
    {
      id: 47,
      question: "Which command is used to list all collections in a database?",
      options: [
        "show collections",
        "list collections",
        "db.collections()",
        "db.list()"
      ],
      correctAnswer: "show collections"
    },
    {
      id: 48,
      question: "What is the function of the $project stage in aggregation?",
      options: [
        "Selects which fields to include or exclude",
        "Groups documents by a field",
        "Filters documents based on a condition",
        "Sorts documents by a specified field"
      ],
      correctAnswer: "Selects which fields to include or exclude"
    },
    {
      id: 49,
      question: "What is a common reason for MongoDB's high performance in handling large datasets?",
      options: [
        "Indexing and in-memory operations",
        "Data compression",
        "Relational data handling",
        "Optimized for small data sets"
      ],
      correctAnswer: "Indexing and in-memory operations"
    },
    {
      id: 50,
      question: "Which of the following is used to encrypt data in MongoDB?",
      options: [
        "Encryption at rest",
        "Encryption in transit",
        "TLS/SSL encryption",
        "All of the above"
      ],
      correctAnswer: "All of the above"
    }

];

const MongoDbTest = () => {

      const [shuffledQuestions, setShuffledQuestions] = useState([]);
      const [currentQuestion, setCurrentQuestion] = useState(0);
      const [selectedOptions, setSelectedOptions] = useState(new Array(questions.length).fill(''));
      const [score, setScore] = useState(0);
      const [showScore, setShowScore] = useState(false);
      const [timeLeft, setTimeLeft] = useState(1500); // 25 minutes in seconds
      const [attemptedQuestions, setAttemptedQuestions] = useState(0);
      const [timerColor, setTimerColor] = useState('#00FF00'); // Initial color green
      const [timerPaused, setTimerPaused] = useState(false); // State to track timer pause/resume


      useEffect(() => {
        // Shuffle the questions array randomly when the component mounts
        const shuffleArray = (array) => {
          return array.sort(() => Math.random() - 0.5);
        };
        setShuffledQuestions(shuffleArray(questions));

        // Scroll to the element with the ID 'css' when the component mounts
        const element = document.getElementById('html');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, []); // Empty dependency array ensures this effect runs only once after the component mounts

      useEffect(() => {
        const timer = setTimeout(() => {
          if (!timerPaused) { // Only decrement time if timer is not paused
            setTimeLeft(timeLeft - 1);

            // Update timer color based on remaining time
            if (timeLeft <= 300 && timeLeft > 120) {
              setTimerColor('#FFFF00'); // Yellow
            } else if (timeLeft <= 120) {
              setTimerColor('darkred'); // Red
            }

            // Check if time is up
            if (timeLeft === 0) {
              setShowScore(true);
            }
          }
        }, 1000);

        return () => clearTimeout(timer);
      }, [timeLeft, timerPaused]);

      const handleOptionSelect = (option) => {
        // Check if the selected option is different from the previously selected option
        if (selectedOptions[currentQuestion] !== option) {
          const newSelectedOptions = [...selectedOptions];
          newSelectedOptions[currentQuestion] = option;
          setSelectedOptions(newSelectedOptions);

          if (!selectedOptions[currentQuestion]) {
            // Increment attempted questions only if the current question didn't have an attempt before
            setAttemptedQuestions(attemptedQuestions + 1);
          }

          if (option === shuffledQuestions[currentQuestion].correctAnswer) {
            setScore(score + 1);
          }
        }
      };

      const handleNextQuestion = () => {
        if (currentQuestion < shuffledQuestions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
        } else {
          setShowScore(true);
        }
      };

      const handlePreviousQuestion = () => {
        if (currentQuestion > 0) {
          setCurrentQuestion(currentQuestion - 1);
        }
      };

      const handlePauseResumeTimer = () => {
        setTimerPaused(!timerPaused); // Toggle timer pause/resume state
      };

  return (
    <div
      className="container mx-auto p-4"
      id="mongodb"
      style={{
        backgroundColor: "#F4F4F6",
        margin: "5rem",
        borderRadius: "15px",
      }}
    >
      {!showScore ? (
        <div
          className="relative isolate overflow-hidden bg-gray-900"
          style={{ borderRadius: "10px", color: "white" }}
        >
          <h1
            className="text-3xl font-bold text-center mb-8"
            style={{ margin: "1rem" }}
          >
            Mongo DB Mock Test
            <br />
            <span style={{ color: "darkred", fontSize: "22px" }}>
              Dream Adoration
            </span>
          </h1>
          <div style={{ margin: "2rem" }}>
            <h2 className="text-2xl font-medium mb-4">
              Question {currentQuestion + 1} of {questions.length}
            </h2>
            <h3 className="text-lg font-semibold mb-2">
              {questions[currentQuestion].question}
            </h3>
            {questions[currentQuestion].options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestion] === option}
                    onChange={() => handleOptionSelect(option)}
                    className="form-radio h-5 w-5 text-primary-600"
                  />
                  <span className="ml-2 ">{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-around">
            <button
              onClick={handlePreviousQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-3 rounded mt-5"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded mt-5"
            >
              Next
            </button>
            <button
              onClick={handlePauseResumeTimer}
              className="bg-green-600 hover:bg-green-700 text-black font-bold py-2 px-4 rounded mt-5"
            >
              {timerPaused ? "Resume" : "Pause"}
            </button>
          </div>
          <div
            className=" mt-1 text-center"
            style={{ color: timerColor, fontWeight: "bold" }}
          >
            Time Left: {Math.floor(timeLeft / 60)}:
            {(timeLeft % 60).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}
          </div>
          <div
            className=" mt-1 text-center"
            style={{ fontWeight: "bold", padding: "10px" }}
          >
            Attempted Questions: {attemptedQuestions} / {questions.length}
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <h3 className="text-lg font-medium mb-2">
            Your Score: {score}/{questions.length}
          </h3>
        </div>
      )}
    </div>
  );
};

export default MongoDbTest;
