import React, { useState, useEffect } from 'react';

const questions = [
  {
    id: 1,
    question: 'What does CSS stand for?',
    options: [
      'Cascading Style Sheets',
      'Creative Style Sheets',
      'Computer Style Sheets',
      'Colorful Style Sheets'
    ],
    correctAnswer: 'Cascading Style Sheets'
  },
  {
    id: 2,
    question: 'Which CSS property is used to change the text color of an element?',
    options: [
      'color',
      'text-color',
      'font-color',
      'text-style'
    ],
    correctAnswer: 'color'
  },
  {
    id: 3,
    question: 'What is the correct CSS syntax for making all the <p> elements bold?',
    options: [
      'p {font-weight: bold;}',
      'p {text-style: bold;}',
      'p {font-bold: true;}',
      'p {bold: true;}'
    ],
    correctAnswer: 'p {font-weight: bold;}'
  },
  {
    id: 4,
    question: 'Which CSS property is used to control the spacing between lines of text?',
    options: [
      'line-height',
      'text-spacing',
      'line-spacing',
      'text-line'
    ],
    correctAnswer: 'line-height'
  },
  {
    id: 5,
    question: 'Which CSS property is used to set the background color of an element?',
    options: [
      'background-color',
      'color',
      'background',
      'bgcolor'
    ],
    correctAnswer: 'background-color'
  },
  {
    id: 6,
    question: 'Which CSS property is used to make text italic?',
    options: [
      'font-style',
      'italic',
      'text-style',
      'text-italic'
    ],
    correctAnswer: 'font-style'
  },
  {
    id: 7,
    question: 'Which CSS property is used to specify the font size of text?',
    options: [
      'font-size',
      'text-size',
      'size',
      'text-font'
    ],
    correctAnswer: 'font-size'
  },
  {
    id: 8,
    question: 'What is the correct CSS syntax for making all <div> elements into inline elements?',
    options: [
      'div {display: inline;}',
      'div {display-mode: inline;}',
      'div {inline: true;}',
      'div {display-style: inline;}'
    ],
    correctAnswer: 'div {display: inline;}'
  },
  {
    id: 9,
    question: 'Which CSS property is used to add shadows to elements?',
    options: [
      'box-shadow',
      'element-shadow',
      'shadow',
      'text-shadow'
    ],
    correctAnswer: 'box-shadow'
  },
  {
    id: 10,
    question: 'Which CSS property is used to specify the space between the borders of an element?',
    options: [
      'padding',
      'border-spacing',
      'margin',
      'spacing'
    ],
    correctAnswer: 'margin'
  },
  {
    id: 11,
    question: 'Which CSS property is used to make an element disappear from the screen without affecting the layout?',
    options: [
      'display',
      'visibility',
      'hidden',
      'opacity'
    ],
    correctAnswer: 'visibility'
  },
  {
    id: 12,
    question: 'What is the correct CSS syntax for adding a background image?',
    options: [
      'background-image: url("img.jpg");',
      'image: url("img.jpg");',
      'background: image("img.jpg");',
      'image-src: url("img.jpg");'
    ],
    correctAnswer: 'background-image: url("img.jpg");'
  },
  {
    id: 13,
    question: 'Which CSS property is used to specify the style of the cursor when hovering over an element?',
    options: [
      'cursor',
      'pointer',
      'hover-cursor',
      'cursor-style'
    ],
    correctAnswer: 'cursor'
  },
  {
    id: 14,
    question: 'Which CSS property is used to control the width of an element border?',
    options: [
      'border-width',
      'width',
      'border-size',
      'border'
    ],
    correctAnswer: 'border-width'
  },
  {
    id: 15,
    question: 'What does the CSS property "position: relative;" do?',
    options: [
      'Positions the element relative to its normal position',
      'Positions the element relative to the browser window',
      'Positions the element relative to its parent element',
      'Positions the element relative to the screen'
    ],
    correctAnswer: 'Positions the element relative to its normal position'
  },
  {
    id: 16,
    question: 'Which CSS property is used to control the alignment of text within an element?',
    options: [
      'text-align',
      'alignment',
      'align',
      'text-position'
    ],
    correctAnswer: 'text-align'
  },
  {
    id: 17,
    question: 'Which CSS property is used to control the appearance of list item bullets?',
    options: [
      'list-style-type',
      'bullet-style',
      'list-bullet',
      'list-style'
    ],
    correctAnswer: 'list-style-type'
  },
  {
    id: 18,
    question: 'What is the purpose of the CSS property "float"?',
    options: [
      'To make text float above other elements',
      'To position an element to the left or right of its container',
      'To make an element invisible',
      'To align text vertically within an element'
    ],
    correctAnswer: 'To position an element to the left or right of its container'
  },
  {
    id: 19,
    question: 'Which CSS property is used to control the visibility of an element?',
    options: [
      'visibility',
      'display',
      'visible',
      'show'
    ],
    correctAnswer: 'visibility'
  },
  {
    id: 20,
    question: 'What does the CSS property "overflow: hidden;" do?',
    options: [
      'Hides content that overflows the element',
      'Creates a border around the element',
      'Makes the elements content visible',
      'Adds space between the borders of the element'
    ],
    correctAnswer: 'Hides content that overflows the element'
  },
  {
    id: 21,
    question: 'Which CSS property is used to control the spacing between elements?',
    options: [
      'margin',
      'padding',
      'spacing',
      'gap'
    ],
    correctAnswer: 'margin'
  },
  {
    id: 22,
    question: 'What is the purpose of the CSS property "z-index"?',
    options: [
      'To control the vertical stacking order of elements',
      'To control the horizontal stacking order of elements',
      'To control the font size of text',
      'To control the spacing between lines of text'
    ],
    correctAnswer: 'To control the vertical stacking order of elements'
  },
  {
    id: 23,
    question: 'Which CSS property is used to set the width of an element relative to its containing element?',
    options: [
      'width',
      'container-width',
      'relative-width',
      'element-width'
    ],
    correctAnswer: 'width'
  },
  {
    id: 24,
    question: 'What is the correct CSS syntax for changing the font family of an element?',
    options: [
      'font-family: Arial, sans-serif;',
      'font: Arial, sans-serif;',
      'family: Arial, sans-serif;',
      'text-family: Arial, sans-serif;'
    ],
    correctAnswer: 'font-family: Arial, sans-serif;'
  },
  {
    id: 25,
    question: 'Which CSS property is used to create rounded corners on an element?',
    options: [
      'border-radius',
      'corner-radius',
      'rounding',
      'border-style'
    ],
    correctAnswer: 'border-radius'
  },
  {
    "id": 26,
    "question": "Which CSS property is used to set the maximum width of an element?",
    "options": [
      "max-width",
      "width",
      "size",
      "element-width"
    ],
    "correctAnswer": "max-width"
  },
  {
    "id": 27,
    "question": "Which CSS property is used to control the text decoration of links?",
    "options": [
      "text-decoration",
      "link-style",
      "link-decoration",
      "decoration"
    ],
    "correctAnswer": "text-decoration"
  },
  {
    "id": 28,
    "question": "What is the purpose of the CSS property 'display: flex;'?",
    "options": [
      "It makes elements display in a flexible layout",
      "It aligns text within an element",
      "It hides the element",
      "It defines the background color"
    ],
    "correctAnswer": "It makes elements display in a flexible layout"
  },
  {
    "id": 29,
    "question": "Which CSS property is used to add space between elements within a flex container?",
    "options": [
      "justify-content",
      "gap",
      "space-between",
      "align-items"
    ],
    "correctAnswer": "gap"
  },
  {
    "id": 30,
    "question": "Which CSS property is used to specify the height of an element?",
    "options": [
      "height",
      "size",
      "element-height",
      "dimension"
    ],
    "correctAnswer": "height"
  },
  {
    "id": 31,
    "question": "Which CSS property is used to make a border around an element?",
    "options": [
      "border",
      "outline",
      "edge",
      "frame"
    ],
    "correctAnswer": "border"
  },
  {
    "id": 32,
    "question": "Which CSS property is used to set the opacity of an element?",
    "options": [
      "opacity",
      "visibility",
      "display",
      "hidden"
    ],
    "correctAnswer": "opacity"
  },
  {
    "id": 33,
    "question": "Which CSS property is used to add space between the content and the border of an element?",
    "options": [
      "padding",
      "margin",
      "spacing",
      "gap"
    ],
    "correctAnswer": "padding"
  },
  {
    "id": 34,
    "question": "Which CSS property is used to set the vertical alignment of an element?",
    "options": [
      "vertical-align",
      "align-vertical",
      "align-items",
      "top-align"
    ],
    "correctAnswer": "vertical-align"
  },
  {
    "id": 35,
    "question": "Which CSS property is used to set the font weight of text?",
    "options": [
      "font-weight",
      "font-style",
      "text-weight",
      "weight"
    ],
    "correctAnswer": "font-weight"
  },
  {
    "id": 36,
    "question": "Which CSS property is used to change the font style of text?",
    "options": [
      "font-style",
      "text-style",
      "text-font",
      "font-family"
    ],
    "correctAnswer": "font-style"
  },
  {
    "id": 37,
    "question": "What is the default value of the CSS 'display' property for a <div> element?",
    "options": [
      "block",
      "inline",
      "none",
      "flex"
    ],
    "correctAnswer": "block"
  },
  {
    "id": 38,
    "question": "Which CSS property is used to change the position of an element on the screen?",
    "options": [
      "position",
      "location",
      "alignment",
      "place"
    ],
    "correctAnswer": "position"
  },
  {
    "id": 39,
    "question": "Which CSS property is used to control the visibility of an element on the page?",
    "options": [
      "visibility",
      "display",
      "opacity",
      "hidden"
    ],
    "correctAnswer": "visibility"
  },
  {
    "id": 40,
    "question": "Which CSS property is used to set a fixed position for an element on the screen?",
    "options": [
      "position: fixed;",
      "position: relative;",
      "position: absolute;",
      "position: sticky;"
    ],
    "correctAnswer": "position: fixed;"
  },
  {
    "id": 41,
    "question": "Which CSS property is used to control the layout of a grid?",
    "options": [
      "grid-template",
      "display: grid;",
      "grid-layout",
      "layout"
    ],
    "correctAnswer": "display: grid;"
  },
  {
    "id": 42,
    "question": "Which CSS property is used to create a shadow effect on text?",
    "options": [
      "text-shadow",
      "box-shadow",
      "shadow-effect",
      "element-shadow"
    ],
    "correctAnswer": "text-shadow"
  },
  {
    "id": 43,
    "question": "Which CSS property is used to create a scrolling effect in an element?",
    "options": [
      "overflow",
      "scrolling",
      "scroll",
      "scroll-effect"
    ],
    "correctAnswer": "overflow"
  },
  {
    "id": 44,
    "question": "What is the correct CSS syntax for applying a border to an element?",
    "options": [
      "border: 2px solid black;",
      "border-style: solid;",
      "border-width: 2px;",
      "element-border: solid;"
    ],
    "correctAnswer": "border: 2px solid black;"
  },
  {
    "id": 45,
    "question": "Which CSS property is used to control the size of an element's font?",
    "options": [
      "font-size",
      "text-size",
      "size",
      "text-font-size"
    ],
    "correctAnswer": "font-size"
  },
  {
    "id": 46,
    "question": "Which CSS property is used to change the background image position?",
    "options": [
      "background-position",
      "background-image-position",
      "background-location",
      "image-position"
    ],
    "correctAnswer": "background-position"
  },
  {
    "id": 47,
    "question": "What is the CSS property 'clip-path' used for?",
    "options": [
      "To define a visible region for an element",
      "To clip an element",
      "To set the shape of an element",
      "To hide an element"
    ],
    "correctAnswer": "To define a visible region for an element"
  },
  {
    "id": 48,
    "question": "Which CSS property is used to make a website responsive?",
    "options": [
      "media queries",
      "viewport",
      "flexbox",
      "grid"
    ],
    "correctAnswer": "media queries"
  },
  {
    "id": 49,
    "question": "Which CSS property is used to set the font family for an element?",
    "options": [
      "font-family",
      "font-type",
      "text-font",
      "font"
    ],
    "correctAnswer": "font-family"
  },
  {
    "id": 50,
    "question": "Which CSS property is used to make an element stick to the top of the page?",
    "options": [
      "position: sticky;",
      "position: fixed;",
      "position: absolute;",
      "position: top;"
    ],
    "correctAnswer": "position: sticky;"
  }

];






const CssTest = () => {

      const [shuffledQuestions, setShuffledQuestions] = useState([]);
      const [currentQuestion, setCurrentQuestion] = useState(0);
      const [selectedOptions, setSelectedOptions] = useState(new Array(questions.length).fill(''));
      const [score, setScore] = useState(0);
      const [showScore, setShowScore] = useState(false);
      const [timeLeft, setTimeLeft] = useState(1500); // 25 minutes in seconds
      const [attemptedQuestions, setAttemptedQuestions] = useState(0);
      const [timerColor, setTimerColor] = useState('#00FF00'); // Initial color green
      const [timerPaused, setTimerPaused] = useState(false); // State to track timer pause/resume


      useEffect(() => {
        // Shuffle the questions array randomly when the component mounts
        const shuffleArray = (array) => {
          return array.sort(() => Math.random() - 0.5);
        };
        setShuffledQuestions(shuffleArray(questions));

        // Scroll to the element with the ID 'css' when the component mounts
        const element = document.getElementById('html');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, []); // Empty dependency array ensures this effect runs only once after the component mounts

      useEffect(() => {
        const timer = setTimeout(() => {
          if (!timerPaused) { // Only decrement time if timer is not paused
            setTimeLeft(timeLeft - 1);

            // Update timer color based on remaining time
            if (timeLeft <= 300 && timeLeft > 120) {
              setTimerColor('#FFFF00'); // Yellow
            } else if (timeLeft <= 120) {
              setTimerColor('darkred'); // Red
            }

            // Check if time is up
            if (timeLeft === 0) {
              setShowScore(true);
            }
          }
        }, 1000);

        return () => clearTimeout(timer);
      }, [timeLeft, timerPaused]);

      const handleOptionSelect = (option) => {
        // Check if the selected option is different from the previously selected option
        if (selectedOptions[currentQuestion] !== option) {
          const newSelectedOptions = [...selectedOptions];
          newSelectedOptions[currentQuestion] = option;
          setSelectedOptions(newSelectedOptions);

          if (!selectedOptions[currentQuestion]) {
            // Increment attempted questions only if the current question didn't have an attempt before
            setAttemptedQuestions(attemptedQuestions + 1);
          }

          if (option === shuffledQuestions[currentQuestion].correctAnswer) {
            setScore(score + 1);
          }
        }
      };

      const handleNextQuestion = () => {
        if (currentQuestion < shuffledQuestions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
        } else {
          setShowScore(true);
        }
      };

      const handlePreviousQuestion = () => {
        if (currentQuestion > 0) {
          setCurrentQuestion(currentQuestion - 1);
        }
      };

      const handlePauseResumeTimer = () => {
        setTimerPaused(!timerPaused); // Toggle timer pause/resume state
      };

  return (
    <div className="container mx-auto p-4" id='css' style={{ backgroundColor: '#F4F4F6', margin: '5rem', borderRadius: '15px' }}>
      {!showScore ? (
        <div className='relative isolate overflow-hidden bg-gray-900'  style={{  borderRadius: '10px', color: 'white' }}>
          <h1 className="text-3xl font-bold text-center mb-8" style={{ margin: '1rem' }}>CSS MOCK TEST<br /><span style={{ color: 'darkred', fontSize: '22px' }}>Dream Adoration</span></h1>
          <div style={{ margin: '2rem' }}>
            <h2 className="text-2xl font-medium mb-4">Question {currentQuestion + 1} of {questions.length}</h2>
            <h3 className="text-lg font-semibold mb-2">{questions[currentQuestion].question}</h3>
            {questions[currentQuestion].options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestion] === option}
                    onChange={() => handleOptionSelect(option)}
                    className="form-radio h-5 w-5 text-primary-600"
                  />
                  <span className="ml-2 ">{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-around">
            <button
              onClick={handlePreviousQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-3 rounded mt-5"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded mt-5"
            >
              Next
            </button>
            <button
              onClick={handlePauseResumeTimer}
              className="bg-green-600 hover:bg-green-700 text-black font-bold py-2 px-4 rounded mt-5"
            >
              {timerPaused ? 'Resume' : 'Pause'}
            </button>
          </div>
          <div className=" mt-1 text-center" style={{ color: timerColor, fontWeight: 'bold' }}>Time Left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}</div>
          <div className=" mt-1 text-center" style={{ fontWeight: 'bold', padding: '10px' }}>Attempted Questions: {attemptedQuestions} / {questions.length}</div>
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <h3 className="text-lg font-medium mb-2">Your Score: {score}/{questions.length}</h3>
        </div>
      )}
    </div>
  );
};

export default CssTest;
