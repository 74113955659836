import React, { useState } from "react";
import coursesData from "./Data/Applications.json"; // Import JSON data
import "./CSS/popularcourse.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

function Applications() {
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleExpandClick = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  return (
    <div
      style={{
        backgroundColor: "#0d1232",
        borderRadius: "10px",
        padding: "1rem",
        margin: "1rem",
      }}
    >
      <h2 className="text-white mb-3 flex justify-center items-center">
        <span style={{ fontSize: "2rem", display: "block" }}>
          Use Free <span style={{ color: "red" }}>Service</span>
        </span>
      </h2>

      <div className="cards-container">
        {coursesData.map((course, index) => (
          <Card
            key={index}
            className="w-full max-w-[22rem] shadow-lg"
            id={course.id}
          >
            <a href={course.href}>
              <CardHeader floated={false} color="blue-gray">
                <video
                  src={course.imageUrl}
                  alt={course.title}
                  autoPlay
                  loop
                  muted
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 h-full w-full " />
              </CardHeader>

              <CardBody>
                <div className="mb-3 flex items-center justify-between">
                  <Typography
                    variant="h5"
                    color="blue-gray"
                    className="font-medium"
                  >
                    {course.title}
                  </Typography>
                </div>
                <Typography
                  style={{ fontWeight: "400" }}
                  color="gray"
                  className={expandedIndex === index ? "expanded" : "collapsed"}
                  // Safely render HTML inside description
                  dangerouslySetInnerHTML={{
                    __html: course.description,
                  }}
                ></Typography>
                <Button
                  className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white  rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  style={{
                    padding: "0.5rem 1rem",
                    fontSize: "12px",
                    fontWeight: "bold",
                    backgroundColor: "#455073",
                  }}
                >
                  {" "}
                  Click Me
                </Button>
              </CardBody>
            </a>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Applications;
