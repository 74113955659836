import React, { useState, useEffect } from 'react';

const questions = [
  {
    id: 1,
    question: 'What does DOM stand for?',
    options: ['Document Object Model', 'Data Object Model', 'Digital Object Model', 'Dynamic Object Model'],
    correctAnswer: 'Document Object Model'
  },
  {
    id: 2,
    question: 'What is JavaScript?',
    options: ['A programming language', 'A markup language', 'A styling language', 'A database language'],
    correctAnswer: 'A programming language'
  },
  {
    id: 3,
    question: 'Which of the following is a JavaScript data type?',
    options: ['Boolean', 'String', 'Array', 'All of the above'],
    correctAnswer: 'All of the above'
  },
  {
    id: 4,
    question: 'What does NaN stand for?',
    options: ['Not a Number', 'Null and Number', 'Number and Null', 'Neither a Null nor a Number'],
    correctAnswer: 'Not a Number'
  },
  {
    id: 5,
    question: 'What is the correct way to declare a JavaScript variable?',
    options: ['var x;', 'variable x;', 'x = 5;', 'int x;'],
    correctAnswer: 'var x;'
  },
  {
    id: 6,
    question: 'Which method is used to add an element to the end of an array?',
    options: ['push()', 'pop()', 'splice()', 'concat()'],
    correctAnswer: 'push()'
  },
  {
    id: 7,
    question: 'What is a closure in JavaScript?',
    options: ['A combination of a function and the lexical environment within which that function was declared', 'A way to close the browser window', 'A type of loop in JavaScript', 'A built-in JavaScript method'],
    correctAnswer: 'A combination of a function and the lexical environment within which that function was declared'
  },
  {
    id: 8,
    question: 'What is the purpose of the `typeof` operator in JavaScript?',
    options: ['To determine the data type of a variable or expression', 'To concatenate strings', 'To declare a variable', 'To compare two values'],
    correctAnswer: 'To determine the data type of a variable or expression'
  },
  {
    id: 9,
    question: 'What is the output of `console.log(typeof [])`?',
    options: ['"object"', '"array"', '"array"', '"undefined"'],
    correctAnswer: '"object"'
  },
  {
    id: 10,
    question: 'What is the output of `1 + "1"` in JavaScript?',
    options: ['"2"', '"11"', '"1"', 'NaN'],
    correctAnswer: '"11"'
  },
  {
    id: 11,
    question: 'What does the `this` keyword refer to in JavaScript?',
    options: ['The current function\'s execution context', 'The global object', 'The parent object', 'The next function in the call stack'],
    correctAnswer: 'The current function\'s execution context'
  },
  {
    id: 12,
    question: 'What is a callback function in JavaScript?',
    options: ['A function passed as an argument to another function, to be executed later', 'A function that returns a callback', 'A function used for debugging', 'A function that is called back to the main function'],
    correctAnswer: 'A function passed as an argument to another function, to be executed later'
  },
  {
    id: 13,
    question: 'What does the `Array.map()` method do?',
    options: ['Creates a new array with the results of calling a provided function on every element in the calling array', 'Modifies the original array by mapping its elements to new values', 'Returns the index of the first occurrence of a specified value in an array', 'Filters elements in an array based on a provided function'],
    correctAnswer: 'Creates a new array with the results of calling a provided function on every element in the calling array'
  },
  {
    id: 14,
    question: 'What is the purpose of the `JSON.stringify()` method in JavaScript?',
    options: ['Converts a JavaScript object or value to a JSON string', 'Converts a JSON string to a JavaScript object or value', 'Parses a JavaScript object or value into a JSON string', 'Encodes a URI component'],
    correctAnswer: 'Converts a JavaScript object or value to a JSON string'
  },
  {
    id: 15,
    question: 'What does the `===` operator do in JavaScript?',
    options: ['Strict equality operator (equal value and equal type)', 'Assignment operator', 'Comparison operator (equal value)', 'Logical operator (logical AND)'],
    correctAnswer: 'Strict equality operator (equal value and equal type)'
  },
  {
    id: 16,
    question: 'What is a promise in JavaScript?',
    options: ['An object representing the eventual completion or failure of an asynchronous operation', 'A function that returns a value', 'A method for handling errors in JavaScript', 'A way to declare a variable'],
    correctAnswer: 'An object representing the eventual completion or failure of an asynchronous operation'
  },
  {
    id: 17,
    question: 'What is the purpose of the `Array.reduce()` method in JavaScript?',
    options: ['Reduces the array to a single value by executing a reducer function for each element', 'Removes the last element from an array and returns it', 'Creates a new array with the results of calling a provided function on every element in the calling array', 'Filters elements in an array based on a provided function'],
    correctAnswer: 'Reduces the array to a single value by executing a reducer function for each element'
  },
  {
    id: 18,
    question: 'What is the difference between `null` and `undefined` in JavaScript?',
    options: ['`null` is an intentional absence of any value, while `undefined` means a variable has been declared but has not been assigned a value', '`null` is a type of object, while `undefined` is a type of value', '`null` represents a numeric value of zero, while `undefined` represents a numeric value of one', '`null` is a reserved keyword in JavaScript, while `undefined` is not'],
    correctAnswer: '`null` is an intentional absence of any value, while `undefined` means a variable has been declared but has not been assigned a value'
  },
  {
    id: 19,
    question: 'What is a closure in JavaScript?',
    options: ['A combination of a function and the lexical environment within which that function was declared', 'A way to close the browser window', 'A type of loop in JavaScript', 'A built-in JavaScript method'],
    correctAnswer: 'A combination of a function and the lexical environment within which that function was declared'
  },
  {
    id: 20,
    question: 'What is the difference between `==` and `===` operators in JavaScript?',
    options: ['`==` performs type coercion, while `===` does not', '`==` is used for strict equality, while `===` is used for loose equality', '`==` compares both value and type, while `===` compares only value', '`==` is used for assigning values, while `===` is used for comparing values'],
    correctAnswer: '`==` performs type coercion, while `===` does not'
  },
  {
    id: 21,
    question: 'What is the difference between `let`, `var`, and `const` in JavaScript?',
    options: ['`let` and `var` declare variables with block scope, while `const` declares variables with constant values', '`var` and `const` declare variables with block scope, while `let` declares variables with function scope', '`var` and `let` declare variables with function scope, while `const` declares variables with block scope', '`let` and `const` declare variables with function scope, while `var` declares variables with block scope'],
    correctAnswer: '`let` and `const` declare variables with block scope, while `var` declares variables with function scope'
  },
  {
    id: 22,
    question: 'What is a higher-order function in JavaScript?',
    options: ['A function that takes another function as an argument or returns a function as a result', 'A function that performs operations on arrays', 'A function that executes immediately after its declaration', 'A function that is nested within another function'],
    correctAnswer: 'A function that takes another function as an argument or returns a function as a result'
  },
  {
    id: 23,
    question: 'What is event delegation in JavaScript?',
    options: ['A technique for handling events by delegating them to a common ancestor element', 'A method for dynamically adding event listeners to DOM elements', 'A way to prevent default browser behavior on certain events', 'A way to bind multiple events to the same element'],
    correctAnswer: 'A technique for handling events by delegating them to a common ancestor element'
  },
  {
    id: 24,
    question: 'What is the purpose of the `Array.filter()` method in JavaScript?',
    options: ['Creates a new array with elements that pass the test implemented by the provided function', 'Modifies the original array by filtering out elements that do not pass the test', 'Returns the first element in an array that passes a provided test function', 'Sorts the elements of an array in place'],
    correctAnswer: 'Creates a new array with elements that pass the test implemented by the provided function'
  },
  {
    id: 25,
    question: 'What does the `Array.forEach()` method do in JavaScript?',
    options: ['Executes a provided function once for each array element', 'Creates a new array with the results of calling a provided function on every element in the calling array', 'Modifies the original array by appending new elements to it', 'Returns the index of the first occurrence of a specified value in an array'],
    correctAnswer: 'Executes a provided function once for each array element'
  },
  {
    id: 26,
    question: 'What does the `bind()` method do in JavaScript?',
    options: ['Creates a new function that, when called, has its `this` value set to the provided value', 'Binds a function to a specific event', 'Creates a new variable in the scope of the current function', 'Returns the index of a function in an array'],
    correctAnswer: 'Creates a new function that, when called, has its `this` value set to the provided value'
  },
  {
    id: 27,
    question: 'What is the purpose of the `setTimeout()` function in JavaScript?',
    options: ['Delays the execution of a function for a specified time', 'Sets a timeout for an event listener', 'Sets the time interval between two function executions', 'Stops the execution of a function after a certain period'],
    correctAnswer: 'Delays the execution of a function for a specified time'
  },
  {
    id: 28,
    question: 'What is the difference between synchronous and asynchronous code in JavaScript?',
    options: ['Synchronous code runs sequentially, while asynchronous code runs in the background', 'Asynchronous code runs sequentially, while synchronous code runs in the background', 'Synchronous code waits for each task to finish before moving to the next, while asynchronous code does not', 'Synchronous code runs only in the browser, while asynchronous code runs on the server'],
    correctAnswer: 'Synchronous code runs sequentially, while asynchronous code runs in the background'
  },
  {
    id: 29,
    question: 'What is the `localStorage` object in JavaScript?',
    options: ['Stores data with no expiration time', 'Stores data on the server', 'Stores data temporarily on the client side and expires after a session', 'Stores data on the server with expiration time'],
    correctAnswer: 'Stores data with no expiration time'
  },
  {
    id: 30,
    question: 'What is the purpose of the `event.preventDefault()` method?',
    options: ['Prevents the default action of an event from being triggered', 'Prevents an event from propagating to other elements', 'Stops the event loop from continuing', 'Prevents a function from being called'],
    correctAnswer: 'Prevents the default action of an event from being triggered'
  },
  {
    id: 31,
    question: 'Which function is used to convert a JSON string into a JavaScript object?',
    options: ['JSON.parse()', 'JSON.stringify()', 'JSON.object()', 'parse.JSON()'],
    correctAnswer: 'JSON.parse()'
  },
  {
    id: 32,
    question: 'What is the result of `"5" - 3` in JavaScript?',
    options: ['2', 'NaN', '"53"', 'Error'],
    correctAnswer: '2'
  },
  {
    id: 33,
    question: 'Which of the following is used to declare a constant in JavaScript?',
    options: ['const', 'let', 'var', 'constant'],
    correctAnswer: 'const'
  },
  {
    id: 34,
    question: 'Which method can be used to remove the first element from an array?',
    options: ['shift()', 'pop()', 'unshift()', 'slice()'],
    correctAnswer: 'shift()'
  },
  {
    id: 35,
    question: 'Which operator is used for logical AND in JavaScript?',
    options: ['&&', '&', '||', '|'],
    correctAnswer: '&&'
  },
  {
    id: 36,
    question: 'Which method is used to concatenate two or more arrays in JavaScript?',
    options: ['concat()', 'push()', 'join()', 'splice()'],
    correctAnswer: 'concat()'
  },
  {
    id: 37,
    question: 'What is the result of `typeof null` in JavaScript?',
    options: ['"object"', '"null"', '"undefined"', '"boolean"'],
    correctAnswer: '"object"'
  },
  {
    id: 38,
    question: 'Which method is used to find the index of an element in an array?',
    options: ['indexOf()', 'findIndex()', 'getIndex()', 'search()'],
    correctAnswer: 'indexOf()'
  },
  {
    id: 39,
    question: 'What is the difference between `setInterval()` and `setTimeout()` in JavaScript?',
    options: ['`setInterval()` runs a function repeatedly, while `setTimeout()` runs a function once after a delay', '`setTimeout()` runs a function repeatedly, while `setInterval()` runs a function once', 'Both methods have the same behavior', '`setInterval()` and `setTimeout()` are not used for timing events'],
    correctAnswer: '`setInterval()` runs a function repeatedly, while `setTimeout()` runs a function once after a delay'
  },
  {
    id: 40,
    question: 'What does the `super` keyword do in JavaScript?',
    options: ['Calls the constructor of the parent class', 'Refers to the parent class', 'Defines a new class', 'Handles errors in code'],
    correctAnswer: 'Calls the constructor of the parent class'
  },
  {
    id: 41,
    question: 'What does the `apply()` method do in JavaScript?',
    options: ['Invokes a function with a given `this` value and an array of arguments', 'Sets the value of `this` for a function', 'Assigns arguments to a function without calling it', 'Returns a function for later invocation'],
    correctAnswer: 'Invokes a function with a given `this` value and an array of arguments'
  },
  {
    id: 42,
    question: 'What is the output of `console.log(1 == "1")`?',
    options: ['true', 'false', 'Error', 'undefined'],
    correctAnswer: 'true'
  },
  {
    id: 43,
    question: 'What is the result of `typeof []` in JavaScript?',
    options: ['"object"', '"array"', '"undefined"', '"null"'],
    correctAnswer: '"object"'
  },
  {
    id: 44,
    question: 'Which method is used to add an event listener to an element?',
    options: ['addEventListener()', 'attachEvent()', 'onEvent()', 'bindEvent()'],
    correctAnswer: 'addEventListener()'
  },
  {
    id: 45,
    question: 'What is the purpose of the `this` keyword in a JavaScript method?',
    options: ['It refers to the object the method is called on', 'It refers to the function being executed', 'It refers to the global scope', 'It refers to the parent method'],
    correctAnswer: 'It refers to the object the method is called on'
  },
  {
    id: 46,
    question: 'Which statement is used to stop a function from executing in JavaScript?',
    options: ['return', 'stop', 'break', 'continue'],
    correctAnswer: 'return'
  },
  {
    id: 47,
    question: 'What does the `parseInt()` function do in JavaScript?',
    options: ['Converts a string into an integer', 'Converts an integer into a string', 'Rounds a number to the nearest integer', 'Converts a number into binary'],
    correctAnswer: 'Converts a string into an integer'
  },
  {
    id: 48,
    question: 'What is the output of `console.log([] + [])` in JavaScript?',
    options: ['""', '[]', 'undefined', 'Error'],
    correctAnswer: '""'
  },
  {
    id: 49,
    question: 'What does the `clearInterval()` method do in JavaScript?',
    options: ['Stops the execution of a function set with `setInterval()`', 'Pauses the execution of a function', 'Cancels an event listener', 'Resets the `setTimeout()` function'],
    correctAnswer: 'Stops the execution of a function set with `setInterval()`'
  },
  {
    id: 50,
    question: 'What is the purpose of the `Array.slice()` method in JavaScript?',
    options: ['Returns a shallow copy of a portion of an array', 'Sorts the elements of an array', 'Adds new elements to an array', 'Removes elements from an array'],
    correctAnswer: 'Returns a shallow copy of a portion of an array'
  }

];




const JavaScriptTest = () => {

      const [shuffledQuestions, setShuffledQuestions] = useState([]);
      const [currentQuestion, setCurrentQuestion] = useState(0);
      const [selectedOptions, setSelectedOptions] = useState(new Array(questions.length).fill(''));
      const [score, setScore] = useState(0);
      const [showScore, setShowScore] = useState(false);
      const [timeLeft, setTimeLeft] = useState(1500); // 25 minutes in seconds
      const [attemptedQuestions, setAttemptedQuestions] = useState(0);
      const [timerColor, setTimerColor] = useState('#00FF00'); // Initial color green
      const [timerPaused, setTimerPaused] = useState(false); // State to track timer pause/resume


      useEffect(() => {
        // Shuffle the questions array randomly when the component mounts
        const shuffleArray = (array) => {
          return array.sort(() => Math.random() - 0.5);
        };
        setShuffledQuestions(shuffleArray(questions));

        // Scroll to the element with the ID 'css' when the component mounts
        const element = document.getElementById('html');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, []); // Empty dependency array ensures this effect runs only once after the component mounts

      useEffect(() => {
        const timer = setTimeout(() => {
          if (!timerPaused) { // Only decrement time if timer is not paused
            setTimeLeft(timeLeft - 1);

            // Update timer color based on remaining time
            if (timeLeft <= 300 && timeLeft > 120) {
              setTimerColor('#FFFF00'); // Yellow
            } else if (timeLeft <= 120) {
              setTimerColor('darkred'); // Red
            }

            // Check if time is up
            if (timeLeft === 0) {
              setShowScore(true);
            }
          }
        }, 1000);

        return () => clearTimeout(timer);
      }, [timeLeft, timerPaused]);

      const handleOptionSelect = (option) => {
        // Check if the selected option is different from the previously selected option
        if (selectedOptions[currentQuestion] !== option) {
          const newSelectedOptions = [...selectedOptions];
          newSelectedOptions[currentQuestion] = option;
          setSelectedOptions(newSelectedOptions);

          if (!selectedOptions[currentQuestion]) {
            // Increment attempted questions only if the current question didn't have an attempt before
            setAttemptedQuestions(attemptedQuestions + 1);
          }

          if (option === shuffledQuestions[currentQuestion].correctAnswer) {
            setScore(score + 1);
          }
        }
      };

      const handleNextQuestion = () => {
        if (currentQuestion < shuffledQuestions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
        } else {
          setShowScore(true);
        }
      };

      const handlePreviousQuestion = () => {
        if (currentQuestion > 0) {
          setCurrentQuestion(currentQuestion - 1);
        }
      };

      const handlePauseResumeTimer = () => {
        setTimerPaused(!timerPaused); // Toggle timer pause/resume state
      };

  return (
    <div className="container mx-auto p-4" id='js' style={{ backgroundColor: '#F4F4F6', margin: '5rem', borderRadius: '15px' }}>
      {!showScore ? (
               <div className='relative isolate overflow-hidden bg-gray-900' style={{  borderRadius: '10px', color: 'white' }}>
                <h1 className="text-3xl font-bold text-center mb-8" style={{ margin: '1rem' }}>Java Script MOCK TEST<br /><span style={{ color: 'darkred', fontSize: '22px' }}>Dream Adoration</span></h1>
          <div style={{ margin: '2rem' }}>
            <h2 className="text-2xl font-medium mb-4">Question {currentQuestion + 1} of {questions.length}</h2>
            <h3 className="text-lg font-semibold mb-2">{questions[currentQuestion].question}</h3>
            {questions[currentQuestion].options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestion] === option}
                    onChange={() => handleOptionSelect(option)}
                    className="form-radio h-5 w-5 text-primary-600"
                  />
                  <span className="ml-2 ">{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-around">
            <button
              onClick={handlePreviousQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-3 rounded mt-5"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded mt-5"
            >
              Next
            </button>
            <button
              onClick={handlePauseResumeTimer}
              className="bg-green-600 hover:bg-green-700 text-black font-bold py-2 px-4 rounded mt-5"
            >
              {timerPaused ? 'Resume' : 'Pause'}
            </button>
          </div>
          <div className=" mt-1 text-center" style={{ color: timerColor, fontWeight: 'bold' }}>Time Left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}</div>
          <div className=" mt-1 text-center" style={{ fontWeight: 'bold', padding: '10px' }}>Attempted Questions: {attemptedQuestions} / {questions.length}</div>
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <h3 className="text-lg font-medium mb-2">Your Score: {score}/{questions.length}</h3>
        </div>
      )}
    </div>
  );
};

export default JavaScriptTest;
