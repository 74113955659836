const config = {
  botName: "HelpBot",
  initialMessages: [{ type: "text", id: 1, message: "Hi! How can I assist you today?" }],
  customStyles: {
    botMessageBox: {
      backgroundColor: "#5A9",
    },
    chatButton: {
      backgroundColor: "#5A9",
    },
  },
};

export default config;
