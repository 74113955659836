import React, { useState, useEffect, useRef } from "react";
import courseVideo from "./Component/Image/review.mp4";
import ReviewModal from "./ReviewModal";
import ReviewMeta from "./Component/Meta/ReviewMeta";
import OurClient from "./Component/OurClient";

const Review = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const iframeRef = useRef(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    // Refresh iframe every 5 minutes (300,000 ms)
    const intervalId = setInterval(() => {
      if (iframeRef.current) {
        iframeRef.current.src = iframeRef.current.src;
      }
    }, 300000); // 5 minutes

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <ReviewMeta />

      {/* Video Section */}
      <div className="relative">
        <video autoPlay loop muted className="object-cover w-full h-full">
          <source src={courseVideo} type="video/mp4" />
        </video>

        {/* Desktop View */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="hidden sm:flex bg-white/60 rounded-md p-4 text-center flex-col items-center gap-6 w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[70%]">
            <h1 className="text-3xl md:text-5xl font-bold text-gray-800 text-center">
              <span className="text-black-500">
                Dream Adoration Trusted by 300+ Happy Student's on
              </span>
            </h1>
            <h1 className="text-4xl md:text-6xl font-bold text-gray-800 text-center">
              <span className="text-blue-600">G</span>
              <span className="text-red-600">o</span>
              <span className="text-yellow-500">o</span>
              <span className="text-blue-600">g</span>
              <span className="text-green-600">l</span>
              <span className="text-red-600">e</span>
            </h1>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      {/* <div className="block md:hidden p-4 text-center bg-white/70 rounded-md">
        <h1 className="text-3xl md:text-5xl font-bold text-gray-800 text-center">
          <span className="text-black-500">
            Dream Adoration Trusted by 100+ Happy Student's on
          </span>
        </h1>
        <h1 className="text-4xl md:text-6xl font-bold text-gray-800 text-center">
          <span className="text-blue-600">G</span>
          <span className="text-red-600">o</span>
          <span className="text-yellow-500">o</span>
          <span className="text-blue-600">g</span>
          <span className="text-green-600">l</span>
          <span className="text-red-600">e</span>
        </h1>
      </div> */}
      <OurClient />
      <h3 className="text-2xl md:text-3xl font-semibold mt-4 text-gray-800 text-center">
        <span className="text-black-200">Student's Say</span>
      </h3>

      {/* Google Reviews iframe */}
      <iframe
        ref={iframeRef}
        src="https://widgets.sociablekit.com/google-reviews/iframe/25500773"
        title="Google Reviews"
        width="100%"
        height="600"
        style={{ borderRadius: "1rem" }}
      ></iframe>



      {/* Review Modal */}
      <ReviewModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default Review;
