import React, { useState } from "react";
import ageVideo from "../../Component/Image/Service/state.mp4";

const stateCityData = {
  "Andhra Pradesh": ["Visakhapatnam", "Vijayawada", "Guntur", "Nellore", "Kurnool", "Tirupati", "Kadapa", "Rajahmundry", "Kakinada", "Anantapur", "Eluru", "Chittoor", "Machilipatnam", "Ongole", "Vizianagaram", "Srikakulam", "Tenali", "Hindupur", "Proddatur", "Bhimavaram", "Adoni", "Tadepalligudem", "Chilakaluripet", "Gudivada", "Narasaraopet", "Madanapalle", "Dharmavaram", "Tanuku", "Markapur", "Chirala"],

  "Arunachal Pradesh": ["Itanagar", "Tawang", "Ziro", "Bomdila", "Pasighat", "Roing", "Aalo", "Tezu", "Daporijo", "Changlang", "Seppa", "Yingkiong", "Khonsa", "Namsai", "Anini", "Dirang", "Koloriang", "Hawai", "Mechuka"],

  "Assam": ["Guwahati", "Dibrugarh", "Jorhat", "Silchar", "Tezpur", "Tinsukia", "Nagaon", "Sivasagar", "Diphu", "Golaghat", "Karimganj", "Barpeta", "Lakhimpur", "Dhemaji", "Hailakandi", "Kokrajhar", "Goalpara", "Udalguri", "Bongaigaon", "Morigaon", "Sonitpur", "Baksa", "Chirang", "Dhubri", "Majuli", "North Lakhimpur", "Haflong"],

  "Bihar": ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur", "Purnia", "Darbhanga", "Arrah", "Begusarai", "Katihar", "Munger", "Chhapra", "Danapur", "Bettiah", "Saharsa", "Sasaram", "Hajipur", "Dehri", "Siwan", "Motihari", "Nawada", "Bagaha", "Buxar", "Kishanganj", "Sitamarhi", "Jamalpur", "Jehanabad", "Aurangabad", "Araria", "Madhepura", "Sheikhpura", "Samastipur", "Lakhisarai", "Supaul", "Bihar Sharif", "Khagaria"],

  "Chhattisgarh": ["Raipur", "Bilaspur", "Durg", "Bhilai", "Korba", "Raigarh", "Jagdalpur", "Ambikapur", "Dhamtari", "Rajnandgaon", "Kanker", "Mahasamund", "Kawardha", "Champa", "Janjgir", "Dongargarh", "Manendragarh", "Sakti", "Bhatapara", "Mungeli", "Surajpur", "Baloda Bazar", "Baikunthpur", "Patan", "Charama"],

  "Goa": ["Panaji", "Margao", "Vasco da Gama", "Mapusa", "Ponda", "Bicholim", "Curchorem", "Canacona", "Valpoi", "Quepem", "Sanguem"],

  "Gujarat": ["Ahmedabad", "Surat", "Vadodara", "Rajkot", "Bhavnagar", "Jamnagar", "Gandhinagar", "Junagadh", "Anand", "Navsari", "Morbi", "Bharuch", "Nadiad", "Porbandar", "Mehsana", "Godhra", "Valsad", "Palanpur", "Surendranagar", "Dahod", "Amreli", "Botad", "Veraval", "Patan", "Gondal", "Deesa", "Bhuj", "Himatnagar", "Modasa", "Vapi", "Mahuva"],

  "Haryana": ["Gurugram", "Faridabad", "Panipat", "Ambala", "Karnal", "Hisar", "Rohtak", "Sonipat", "Yamunanagar", "Panchkula", "Bhiwani", "Sirsa", "Kurukshetra", "Jind", "Rewari", "Fatehabad", "Kaithal", "Mahendragarh", "Jhajjar", "Palwal", "Charkhi Dadri", "Narnaul"],

  "Himachal Pradesh": ["Shimla", "Dharamshala", "Kullu", "Manali", "Solan", "Mandi", "Kangra", "Bilaspur", "Chamba", "Una", "Hamirpur", "Nahan", "Palampur", "Una", "Arki", "Rohru", "Pangani", "Kasauli", "Nalagarh"],

  "Jharkhand": ["Ranchi", "Jamshedpur", "Dhanbad", "Bokaro", "Giridih", "Hazaribagh", "Deoghar", "Chaibasa", "Lohardaga", "Sahebganj", "Palamu", "Simdega", "Koderma", "Dumka", "Ramgarh", "Pakur", "Godda", "Khunti", "Latehar", "Seraikela", "Tata"],

  "Karnataka": ["Bengaluru", "Mysuru", "Mangaluru", "Hubballi", "Belagavi", "Tumakuru", "Shivamogga", "Davangere", "Bijapur", "Chitradurga", "Chikkamagaluru", "Bagalkot", "Raichur", "Kolar", "Hassan", "Mandya", "Udupi", "Dakshina Kannada", "Kodagu", "Ballari", "Haveri", "Karwar", "Gadag", "Ramanagaram", "Yadgir", "Chikkaballapur", "Bidar", "Bengaluru Rural", "Chamarajanagar"],

  "Kerala": ["Thiruvananthapuram", "Kochi", "Kozhikode", "Kottayam", "Thrissur", "Malappuram", "Palakkad", "Kannur", "Alappuzha", "Pathanamthitta", "Idukki", "Wayanad", "Ernakulam", "Kasargod", "Varkala", "Punalur", "Mavelikkara", "Ottapalam", "Payyannur"],

  "Madhya Pradesh": ["Bhopal", "Indore", "Gwalior", "Jabalpur", "Ujjain", "Sagar", "Satna", "Rewa", "Khandwa", "Dewas", "Shivpuri", "Chhindwara", "Khargone", "Mandsaur", "Ratlam", "Betul", "Vidisha", "Sehore", "Damoh", "Hoshangabad", "Shahdol", "Ashoknagar", "Rajgarh", "Neemuch", "Harda", "Tikamgarh", "Balaghat", "Narsinghpur", "Seoni", "Panna"],

  "Maharashtra": ["Mumbai", "Pune", "Nagpur", "Nashik", "Thane", "Aurangabad", "Kolhapur", "Solapur", "Amravati", "Akola", "Jalna", "Chandrapur", "Satara", "Nandurbar", "Dhule", "Palghar", "Buldhana", "Raigad", "Bhiwandi", "Latur", "Parbhani", "Shirdi", "Ichalkaranji", "Karad", "Vasai", "Miraj", "Wardha", "Navi Mumbai", "Kalyan", "Dombivli", "Ulhasnagar"],

  "Manipur": ["Imphal", "Thoubal", "Kangpokpi", "Churachandpur", "Bishnupur", "Senapati", "Tamenglong", "Ukhrul", "Jiribam", "Kakching", "Tengnoupal"],

  "Meghalaya": ["Shillong", "Tura", "Nongpoh", "Jowai", "Baghmara", "Williamnagar", "Resubelpara", "Mawkyrwat", "Ampati"],

  "Mizoram": ["Aizawl", "Lunglei", "Champhai", "Kolasib", "Serchhip", "Mamit", "Lawngtlai", "Hnahthial", "Siaha"],

  "Nagaland": ["Kohima", "Dimapur", "Mokokchung", "Wokha", "Tuensang", "Zunheboto", "Peren", "Longleng", "Phek", "Mon"],

  "Odisha": ["Bhubaneswar", "Cuttack", "Rourkela", "Berhampur", "Sambalpur", "Balasore", "Bargarh", "Kendrapara", "Jagatsinghpur", "Puri", "Jajpur", "Khordha", "Dhenkanal", "Boudh", "Nayagarh", "Khurda", "Kalahandi", "Ganjam", "Koraput", "Rayagada", "Malkangiri", "Mayurbhanj", "Angul"],

  "Punjab": ["Chandigarh", "Amritsar", "Ludhiana", "Jalandhar", "Patiala", "Mohali", "Bathinda", "Moga", "Hoshiarpur", "Rupnagar", "Firozpur", "Kapurthala", "Gurdaspur", "Muktsar", "Sangrur", "Faridkot", "Tarn Taran", "Shaheed Bhagat Singh Nagar", "Barnala", "Fatehgarh Sahib", "Sri Muktsar Sahib"],

  "Rajasthan": ["Jaipur", "Udaipur", "Jodhpur", "Kota", "Ajmer", "Bikaner", "Alwar", "Bhilwara", "Tonk", "Sikar", "Churu", "Nagaur", "Pali", "Barmer", "Jaisalmer", "Hanumangarh", "Ratangarh", "Chittorgarh", "Sawai Madhopur", "Bundi", "Dungarpur", "Jalore", "Rajsamand", "Sri Ganganagar", "Jhunjhunu", "Dholpur", "Baran", "Banswara"],

  "Sikkim": ["Gangtok", "Namchi", "Jorethang", "Rangpo", "Mangan", "Pakyong", "Rangrang", "Singtam"],

  "Tamil Nadu": ["Chennai", "Coimbatore", "Madurai", "Tiruchirappalli", "Salem", "Erode", "Vellore", "Tirunelveli", "Dindigul", "Tiruppur", "Thoothukudi", "Nagercoil", "Kanchipuram", "Cuddalore", "Karur", "Perambalur", "Kanyakumari", "Villupuram", "Nagapattinam", "Pudukkottai", "Ramanathapuram", "Sivagangai", "Virudhunagar", "Kovai"],

  "Telangana": ["Hyderabad", "Warangal", "Khammam", "Nizamabad", "Karimnagar", "Mahbubnagar", "Ramagundam", "Miryalaguda", "Suryapet", "Jagtial", "Adilabad", "Nalgonda", "Peddapalli", "Sangareddy", "Medak", "Nagarkurnool", "Kothagudem", "Mancherial"],

  "Tripura": ["Agartala", "Udaipur", "Dharmanagar", "Belonia", "Sonamura", "Kailashahar", "Amarpur", "Khowai", "Sabroom", "Jogendranagar"],

  "Uttar Pradesh": ["Lucknow", "Kanpur", "Agra", "Varanasi", "Meerut", "Allahabad", "Ghaziabad", "Noida", "Mathura", "Aligarh", "Moradabad", "Bareilly", "Firozabad", "Saharanpur", "Jhansi", "Rampur", "Muzaffarnagar", "Gorakhpur", "Shahjahanpur", "Etawah", "Sitapur", "Mainpuri", "Unnao", "Prayagraj", "Budaun", "Basti", "Jaunpur", "Azamgarh"],

  "Uttarakhand": ["Dehradun", "Haridwar", "Nainital", "Rishikesh", "Roorkee", "Haldwani", "Pauri", "Udham Singh Nagar", "Tehri", "Almora", "Rudrapur", "Champawat", "Bageshwar", "Kashipur", "Kotdwara", "Kichha"],

  "West Bengal": ["Kolkata", "Howrah", "Siliguri", "Durgapur", "Asansol", "Kolkata", "Burdwan", "Malda", "Jalpaiguri", "Haldia", "Sainthia", "Chandannagar", "Medinipur", "Purulia", "Krishnanagar", "Cooch Behar", "Balurghat", "Ranaghat", "Naihati", "Bankura", "Baharampur", "Kolar"],
};

const StateCityList = () => {
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [error, setError] = useState(null);

  const handleStateChange = () => {
    if (!selectedState) {
      setError("Please select a state.");
      return;
    }
    setError(null);
    setCities(stateCityData[selectedState]);
  };

  return (
    <div className="bg-white mt-12">
    <div className="mx-auto max-w-7xl sm:px-6 sm:py-12">
      <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
        <svg
          viewBox="0 0 1024 1024"
          className="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          aria-hidden="true"
        >
            <circle cx="512" cy="512" r="512" fill="url(#gradient)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="gradient">
                <stop stopColor="#7775D6" />
                <stop offset="1" stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>

          <div className="mx-auto max-w-md text-left lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-balance text-3xl font-semibold tracking-tight text-white sm:text-4xl">
              Discover Cities in Indian States
            </h2>

             <p className="mt-6 text-pretty text-lg/8 text-gray-300">
              Select a state from the dropdown to instantly view the list of cities associated with it.
            </p>
            <div className="mt-10 mb-10 flex flex-col gap-y-6">
              <select
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                className="border text-white-600 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700"
              >
                <option value="">Select a State</option>
                {Object.keys(stateCityData).map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              <button
                onClick={handleStateChange}
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Show Cities
              </button>
              </div>
              {error && <p className="text-red-500 mt-4">{error}</p>}

          </div>

          <div className="relative h-80 hidden lg:block" style={{ marginTop: "8rem" }}>
                      <video
                        src={ageVideo}
                        autoPlay
                        loop
                        muted
                        className="w-full h-full object-cover" style={{borderRadius:'1rem'}}
                      />
                    </div>
                    </div>
                    </div>

{/* Cities Section */}
{cities.length > 0 && !error && (
  <div className="bg-white py-2">
    <div className="mx-auto max-w-7xl px-6">
      <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-12 pb-10 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-20 lg:px-24">
        <svg
          viewBox="0 0 1024 1024"
          className="absolute left-1/2 top-1/2 -z-10 w-[74rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
          aria-hidden="true"
        >
          <circle cx="512" cy="512" r="512" fill="url(#gradient)" fillOpacity="0.7" />
          <defs>
            <radialGradient id="gradient">
              <stop stopColor="#7775D6" />
              <stop offset="1" stopColor="#E935C1" />
            </radialGradient>
          </defs>
        </svg>
        <div className="mx-auto max-w-3xl text-center lg:mx-0 lg:text-left">
          <h3 className="text-2xl font-bold text-gray-200 mb-6">
            Explore Cities
          </h3>
          <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
            {cities.map((city, index) => {
              // Generate a random color
              const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
              return (
                <div
                  key={index}
                  style={{ backgroundColor: randomColor }}
                  className="flex items-center justify-center rounded-lg py-4 px-6 shadow-md hover:shadow-lg transition-shadow duration-300"
                >
                  <p className="text-white font-medium">{city}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  </div>
)}






        </div>

  );
};

export default StateCityList;
