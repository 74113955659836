import React, { useState, useEffect } from 'react';


const questions = [
  {
    id: 1,
    question: 'What does PHP stand for?',
    options: ['Personal Home Page', 'PHP: Hypertext Preprocessor', 'Private Hypertext Processor', 'Personal Hypertext Processor'],
    correctAnswer: 'PHP: Hypertext Preprocessor'
  },
  {
    id: 2,
    question: 'Which of the following is used to start a PHP code block?',
    options: ['<?php', '<?', '<?=', '{%'],
    correctAnswer: '<?php'
  },
  {
    id: 3,
    question: 'What is the result of the following code snippet? \n echo 5 + "5";',
    options: ['10', '55', 'Error', '25'],
    correctAnswer: '10'
  },
  {
    id: 4,
    question: 'Which function in PHP is used to redirect the browser to another URL?',
    options: ['redirect()', 'location()', 'header()', 'forward()'],
    correctAnswer: 'header()'
  },
  {
    id: 5,
    question: 'Which PHP superglobal variable is used to retrieve information from forms?',
    options: ['$_GET', '$_POST', '$_REQUEST', '$_SERVER'],
    correctAnswer: '$_POST'
  },
  {
    id: 6,
    question: 'In PHP, what does the double arrow (=>) operator do?',
    options: ['Concatenates two strings', 'Assigns a value to a variable', 'Indicates an array key-value pair', 'Performs a comparison'],
    correctAnswer: 'Indicates an array key-value pair'
  },
  {
    id: 7,
    question: 'Which function in PHP is used to count the number of elements in an array?',
    options: ['elements()', 'sizeOf()', 'count()', 'length()'],
    correctAnswer: 'count()'
  },
  {
    id: 8,
    question: 'What is the correct way to comment multiple lines in PHP?',
    options: ['// This is a comment', '/* This is a comment */', '# This is a comment', '%% This is a comment'],
    correctAnswer: '/* This is a comment */'
  },
  {
    id: 9,
    question: 'Which PHP function is used to check if a file exists?',
    options: ['file_exists()', 'is_file()', 'file_check()', 'check_file()'],
    correctAnswer: 'file_exists()'
  },
  {
    id: 10,
    question: 'Which operator in PHP is used to compare two values but does not check data types?',
    options: ['==', '===', '!=', '!=='],
    correctAnswer: '=='
  },
  {
    id: 11,
    question: 'Which of the following is not a valid PHP data type?',
    options: ['string', 'boolean', 'int', 'double'],
    correctAnswer: 'double'
  },
  {
    id: 12,
    question: 'What is the purpose of the PHP function "unlink()"?',
    options: ['To delete a file', 'To create a directory', 'To rename a file', 'To copy a file'],
    correctAnswer: 'To delete a file'
  },
  {
    id: 13,
    question: 'Which PHP function is used to encode a string?',
    options: ['encode()', 'encrypt()', 'base64_encode()', 'hash()'],
    correctAnswer: 'base64_encode()'
  },
  {
    id: 14,
    question: 'What is the output of the following code snippet? \n echo strpos("Hello World", "World");',
    options: ['World', 'Hello', '0', '-1'],
    correctAnswer: '6'
  },
  {
    id: 15,
    question: 'Which PHP function is used to generate a random number?',
    options: ['random()', 'mt_rand()', 'rand()', 'generateRandom()'],
    correctAnswer: 'mt_rand()'
  },
  {
    id: 16,
    question: 'In PHP, what does the function "explode()" do?',
    options: ['Joins array elements with a string', 'Splits a string into an array', 'Concatenates two strings', 'Removes whitespace from the beginning and end of a string'],
    correctAnswer: 'Splits a string into an array'
  },
  {
    id: 17,
    question: 'Which of the following is the correct way to start a session in PHP?',
    options: ['start_session()', 'new_session()', 'session_start()', 'init_session()'],
    correctAnswer: 'session_start()'
  },
  {
    id: 18,
    question: 'In PHP, what does the function "implode()" do?',
    options: ['Joins array elements with a string', 'Splits a string into an array', 'Concatenates two strings', 'Removes whitespace from the beginning and end of a string'],
    correctAnswer: 'Joins array elements with a string'
  },
  {
    id: 19,
    question: 'Which PHP function is used to set the HTTP response code?',
    options: ['http_response_code()', 'set_http_response_code()', 'header()', 'http_code()'],
    correctAnswer: 'http_response_code()'
  },
  {
    id: 20,
    question: 'What does the function "json_encode()" do in PHP?',
    options: ['Decodes a JSON string', 'Encodes a PHP array into a JSON string', 'Decodes a PHP array from a JSON string', 'Encodes a JSON string'],
    correctAnswer: 'Encodes a PHP array into a JSON string'
  },
  {
    id: 21,
    question: 'What is the purpose of the PHP function "trim()"?',
    options: ['To delete a file', 'To create a directory', 'To remove whitespace from the beginning and end of a string', 'To copy a file'],
    correctAnswer: 'To remove whitespace from the beginning and end of a string'
  },
  {
    id: 22,
    question: 'Which PHP function is used to get the length of a string?',
    options: ['length()', 'strlen()', 'str_length()', 'sizeOf()'],
    correctAnswer: 'strlen()'
  },
  {
    id: 23,
    question: 'What is the correct way to define a constant in PHP?',
    options: ['define(CONSTANT_NAME, value)', 'const CONSTANT_NAME = value', '$CONSTANT_NAME = value', 'constant(CONSTANT_NAME, value)'],
    correctAnswer: 'const CONSTANT_NAME = value'
  },
  {
    id: 24,
    question: 'Which PHP function is used to send emails?',
    options: ['send_mail()', 'mail()', 'email()', 'smtp()'],
    correctAnswer: 'mail()'
  },
  {
    id: 25,
    question: 'Which function in PHP can be used to insert content of one PHP file into another PHP file before server executes it?',
    options: ['file_get_contents()', 'include()', 'copy()', 'require()'],
    correctAnswer: 'include()'
  },
  {
    id: 26,
    question: 'Which PHP function is used to sort an array in ascending order?',
    options: ['sort()', 'asort()', 'ksort()', 'rsort()'],
    correctAnswer: 'sort()'
  },
  {
    id: 27,
    question: 'What is the correct way to check if a variable is an array in PHP?',
    options: ['is_array()', 'array_check()', 'check_array()', 'is_array_type()'],
    correctAnswer: 'is_array()'
  },
  {
    id: 28,
    question: 'Which of the following is the correct way to start an if statement in PHP?',
    options: ['if (condition)', 'if condition', 'if {condition}', 'if: condition'],
    correctAnswer: 'if (condition)'
  },
  {
    id: 29,
    question: 'Which of the following is used to include the contents of a file in PHP, and stops if the file is not found?',
    options: ['require()', 'include()', 'include_once()', 'require_once()'],
    correctAnswer: 'require()'
  },
  {
    id: 30,
    question: 'What is the purpose of the PHP function "session_destroy()"?',
    options: ['To remove all session data', 'To remove a specific session variable', 'To start a session', 'To send session data to the client'],
    correctAnswer: 'To remove all session data'
  },
  {
    id: 31,
    question: 'Which function is used to fetch a single row from a database result in PHP?',
    options: ['mysqli_fetch_row()', 'mysqli_fetch_assoc()', 'mysqli_fetch_result()', 'mysqli_fetch_array()'],
    correctAnswer: 'mysqli_fetch_assoc()'
  },
  {
    id: 32,
    question: 'Which PHP function is used to delete a session variable?',
    options: ['session_remove()', 'unset()', 'session_unset()', 'destroy_session()'],
    correctAnswer: 'unset()'
  },
  {
    id: 33,
    question: 'Which function is used to encrypt data in PHP?',
    options: ['encrypt_data()', 'md5()', 'sha1()', 'openssl_encrypt()'],
    correctAnswer: 'md5()'
  },
  {
    id: 34,
    question: 'What does the PHP function "strip_tags()" do?',
    options: ['Removes HTML tags from a string', 'Strips the white space from a string', 'Encodes HTML characters', 'Converts HTML tags into entities'],
    correctAnswer: 'Removes HTML tags from a string'
  },
  {
    id: 35,
    question: 'Which PHP function is used to merge two or more arrays?',
    options: ['array_merge()', 'array_combine()', 'merge()', 'combine_arrays()'],
    correctAnswer: 'array_merge()'
  },
  {
    id: 36,
    question: 'What is the correct way to check if a variable is set in PHP?',
    options: ['isset()', 'is_set()', 'set()', 'check_set()'],
    correctAnswer: 'isset()'
  },
  {
    id: 37,
    question: 'Which of the following functions can be used to get the value of an environment variable?',
    options: ['getenv()', 'get_env()', 'env()', 'fetch_env()'],
    correctAnswer: 'getenv()'
  },
  {
    id: 38,
    question: 'Which PHP function is used to get the current time?',
    options: ['time()', 'current_time()', 'get_time()', 'now()'],
    correctAnswer: 'time()'
  },
  {
    id: 39,
    question: 'Which PHP function is used to get the last inserted ID in a MySQL database?',
    options: ['get_last_id()', 'mysqli_insert_id()', 'last_inserted_id()', 'mysql_last_id()'],
    correctAnswer: 'mysqli_insert_id()'
  },
  {
    id: 40,
    question: 'What is the default value of a session cookie in PHP?',
    options: ['When the browser is closed', '1 hour', '30 minutes', 'None'],
    correctAnswer: 'When the browser is closed'
  },
  {
    id: 41,
    question: 'Which PHP function is used to get the type of a variable?',
    options: ['gettype()', 'type_of()', 'variable_type()', 'type()'],
    correctAnswer: 'gettype()'
  },
  {
    id: 42,
    question: 'What is the correct way to define an array in PHP?',
    options: ['array()', '[]', 'array([])', '[]()'],
    correctAnswer: 'array()'
  },
  {
    id: 43,
    question: 'Which PHP function is used to redirect the browser to another page?',
    options: ['redirect()', 'header()', 'location()', 'location_redirect()'],
    correctAnswer: 'header()'
  },
  {
    id: 44,
    question: 'Which PHP function is used to remove a directory?',
    options: ['remove_dir()', 'rmdir()', 'delete_directory()', 'unlink_dir()'],
    correctAnswer: 'rmdir()'
  },
  {
    id: 45,
    question: 'What is the purpose of the PHP function "array_push()"?',
    options: ['To add an element to the beginning of an array', 'To remove an element from the array', 'To add one or more elements to the end of an array', 'To search for an element in an array'],
    correctAnswer: 'To add one or more elements to the end of an array'
  },
  {
    id: 46,
    question: 'Which PHP function is used to check if a variable is empty?',
    options: ['empty()', 'is_empty()', 'isset()', 'check_empty()'],
    correctAnswer: 'empty()'
  },
  {
    id: 47,
    question: 'What is the correct way to concatenate strings in PHP?',
    options: ['+', 'concat()', '.', '&'],
    correctAnswer: '.'
  },
  {
    id: 48,
    question: 'Which of the following is used to start a session in PHP?',
    options: ['session_init()', 'session_start()', 'start_session()', 'new_session()'],
    correctAnswer: 'session_start()'
  },
  {
    id: 49,
    question: 'Which PHP function is used to get the current date and time?',
    options: ['current_datetime()', 'datetime()', 'date()', 'get_datetime()'],
    correctAnswer: 'date()'
  },
  {
    id: 50,
    question: 'What is the correct way to retrieve a session variable in PHP?',
    options: ['$_SESSION[]', '$SESSION[]', 'get_session()', 'session_variable()'],
    correctAnswer: '$_SESSION[]'
  }
];


const PhpTest = () => {

     const [shuffledQuestions, setShuffledQuestions] = useState([]);
     const [currentQuestion, setCurrentQuestion] = useState(0);
     const [selectedOptions, setSelectedOptions] = useState(new Array(questions.length).fill(''));
     const [score, setScore] = useState(0);
     const [showScore, setShowScore] = useState(false);
     const [timeLeft, setTimeLeft] = useState(1500); // 25 minutes in seconds
     const [attemptedQuestions, setAttemptedQuestions] = useState(0);
     const [timerColor, setTimerColor] = useState('#00FF00'); // Initial color green
     const [timerPaused, setTimerPaused] = useState(false); // State to track timer pause/resume


     useEffect(() => {
       // Shuffle the questions array randomly when the component mounts
       const shuffleArray = (array) => {
         return array.sort(() => Math.random() - 0.5);
       };
       setShuffledQuestions(shuffleArray(questions));

       // Scroll to the element with the ID 'css' when the component mounts
       const element = document.getElementById('html');
       if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
       }
     }, []); // Empty dependency array ensures this effect runs only once after the component mounts

     useEffect(() => {
       const timer = setTimeout(() => {
         if (!timerPaused) { // Only decrement time if timer is not paused
           setTimeLeft(timeLeft - 1);

           // Update timer color based on remaining time
           if (timeLeft <= 300 && timeLeft > 120) {
             setTimerColor('#FFFF00'); // Yellow
           } else if (timeLeft <= 120) {
             setTimerColor('darkred'); // Red
           }

           // Check if time is up
           if (timeLeft === 0) {
             setShowScore(true);
           }
         }
       }, 1000);

       return () => clearTimeout(timer);
     }, [timeLeft, timerPaused]);

     const handleOptionSelect = (option) => {
       // Check if the selected option is different from the previously selected option
       if (selectedOptions[currentQuestion] !== option) {
         const newSelectedOptions = [...selectedOptions];
         newSelectedOptions[currentQuestion] = option;
         setSelectedOptions(newSelectedOptions);

         if (!selectedOptions[currentQuestion]) {
           // Increment attempted questions only if the current question didn't have an attempt before
           setAttemptedQuestions(attemptedQuestions + 1);
         }

         if (option === shuffledQuestions[currentQuestion].correctAnswer) {
           setScore(score + 1);
         }
       }
     };

     const handleNextQuestion = () => {
       if (currentQuestion < shuffledQuestions.length - 1) {
         setCurrentQuestion(currentQuestion + 1);
       } else {
         setShowScore(true);
       }
     };

     const handlePreviousQuestion = () => {
       if (currentQuestion > 0) {
         setCurrentQuestion(currentQuestion - 1);
       }
     };

     const handlePauseResumeTimer = () => {
       setTimerPaused(!timerPaused); // Toggle timer pause/resume state
     };

  return (
    <div className="container mx-auto p-4" id='php' style={{ backgroundColor: '#F4F4F6', margin: '5rem', borderRadius: '15px' }}>
      {!showScore ? (
                <div className='relative isolate overflow-hidden bg-gray-900' style={{  borderRadius: '10px', color: 'white' }}>
          <h1 className="text-3xl font-bold text-center mb-8" style={{ margin: '1rem' }}>PHP MOCK TEST<br /><span style={{ color: 'darkred', fontSize: '22px' }}>Dream Adoration</span></h1>
          <div style={{ margin: '2rem' }}>
            <h2 className="text-2xl font-medium mb-4">Question {currentQuestion + 1} of {questions.length}</h2>
            <h3 className="text-lg font-semibold mb-2">{questions[currentQuestion].question}</h3>
            {questions[currentQuestion].options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestion] === option}
                    onChange={() => handleOptionSelect(option)}
                    className="form-radio h-5 w-5 text-primary-600"
                  />
                  <span className="ml-2 ">{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-around">
            <button
              onClick={handlePreviousQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-3 rounded mt-5"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded mt-5"
            >
              Next
            </button>
            <button
              onClick={handlePauseResumeTimer}
              className="bg-green-600 hover:bg-green-700 text-black font-bold py-2 px-4 rounded mt-5"
            >
              {timerPaused ? 'Resume' : 'Pause'}
            </button>
          </div>
          <div className=" mt-1 text-center" style={{ color: timerColor, fontWeight: 'bold' }}>Time Left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}</div>
          <div className=" mt-1 text-center" style={{ fontWeight: 'bold', padding: '10px' }}>Attempted Questions: {attemptedQuestions} / {questions.length}</div>
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <h3 className="text-lg font-medium mb-2">Your Score: {score}/{questions.length}</h3>
        </div>
      )}
    </div>
  );
};

export default PhpTest;
