import React, { useState } from "react";
import "boxicons/css/boxicons.min.css";
import chatBotImage from "../Image/ChatBot/ChatBot-unscreen (1).gif"; // Adjust the path as needed
import ChatModal from "./ChatModal"; // Import the ChatModal component

function ChatBot() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div>
      <p
        className="chatbot-btn-icon text-white w-20 h-20 rounded-full flex items-center justify-center fixed right-4 bottom-0 border-0 outline-none transition duration-500 z-[9999]"
        onClick={openModal}
      >
        <img
          src={chatBotImage}
          alt="Chat Bot"
          style={{ cursor: "pointer" }}
        />
      </p>
      {isModalOpen && <ChatModal closeModal={closeModal} />}
    </div>
  );
}

export default ChatBot;
