import React, { useState, useEffect } from "react";

const questions = [
  {
    id: 1,
    question: "What is Bootstrap?",
    options: [
      "A CSS framework for responsive web design",
      "A programming language",
      "A database management tool",
      "An image editing software",
    ],
    correctAnswer: "A CSS framework for responsive web design",
  },
  {
    id: 2,
    question:
      "Which class is used to create a responsive container in Bootstrap?",
    options: [".container", ".responsive", ".grid", ".wrapper"],
    correctAnswer: ".container",
  },
  {
    id: 3,
    question: "What is the default grid system in Bootstrap based on?",
    options: ["12 columns", "16 columns", "10 columns", "8 columns"],
    correctAnswer: "12 columns",
  },
  {
    id: 4,
    question: "Which Bootstrap class is used to make an image responsive?",
    options: [".img-fluid", ".img-responsive", ".img-container", ".img-adapt"],
    correctAnswer: ".img-fluid",
  },
  {
    id: 5,
    question: "Which class is used to create a button in Bootstrap?",
    options: [".btn", ".button", ".bootstrap-btn", ".btn-class"],
    correctAnswer: ".btn",
  },
  {
    id: 6,
    question: "Which class in Bootstrap is used to center text?",
    options: [".text-center", ".center-text", ".align-center", ".text-align"],
    correctAnswer: ".text-center",
  },
  {
    id: 7,
    question: "How do you add a navigation bar in Bootstrap?",
    options: [".navbar", ".nav", ".navigation-bar", ".menu"],
    correctAnswer: ".navbar",
  },
  {
    id: 8,
    question:
      "Which class is used to make a column take up the entire row in Bootstrap?",
    options: [".col-12", ".col-row", ".full-width", ".row"],
    correctAnswer: ".col-12",
  },
  {
    id: 9,
    question: "What does the class 'd-flex' do in Bootstrap?",
    options: [
      "Applies Flexbox display properties",
      "Hides an element",
      "Creates a grid",
      "Centers text",
    ],
    correctAnswer: "Applies Flexbox display properties",
  },
  {
    id: 10,
    question: "Which class is used to add a background color in Bootstrap?",
    options: [".bg-primary", ".bg-color", ".background", ".color-bg"],
    correctAnswer: ".bg-primary",
  },
  {
    id: 11,
    question:
      "Which Bootstrap class is used to align items horizontally in Flexbox?",
    options: [
      ".justify-content-*",
      ".align-items-*",
      ".flex-horizontal",
      ".align-horizontal",
    ],
    correctAnswer: ".justify-content-*",
  },
  {
    id: 12,
    question:
      "Which class is used to hide elements on small screens in Bootstrap?",
    options: [".d-none .d-sm-block", ".hide-sm", ".hidden-xs", ".display-hide"],
    correctAnswer: ".d-none .d-sm-block",
  },
  {
    id: 13,
    question: "Which version of Bootstrap introduced Flexbox support?",
    options: ["Bootstrap 4", "Bootstrap 3", "Bootstrap 5", "Bootstrap 2"],
    correctAnswer: "Bootstrap 4",
  },
  {
    id: 14,
    question: "Which class is used to create a dropdown menu in Bootstrap?",
    options: [".dropdown", ".menu-dropdown", ".dropdown-menu", ".menu"],
    correctAnswer: ".dropdown",
  },
  {
    id: 15,
    question: "What does the Bootstrap utility class 'text-danger' do?",
    options: [
      "Applies a red color to the text",
      "Centers the text",
      "Makes the text bold",
      "Hides the text",
    ],
    correctAnswer: "Applies a red color to the text",
  },
  {
    id: 16,
    question: "Which class is used to create a modal in Bootstrap?",
    options: [".modal", ".popup", ".dialog", ".window"],
    correctAnswer: ".modal",
  },
  {
    id: 17,
    question: "How do you add a shadow to an element in Bootstrap?",
    options: [".shadow", ".box-shadow", ".element-shadow", ".shadow-box"],
    correctAnswer: ".shadow",
  },
  {
    id: 18,
    question: "Which class is used to add padding in Bootstrap?",
    options: [".p-*", ".padding-*", ".pad-*", ".pd-*"],
    correctAnswer: ".p-*",
  },
  {
    id: 19,
    question: "Which class is used to create a responsive table in Bootstrap?",
    options: [
      ".table-responsive",
      ".responsive-table",
      ".table-fluid",
      ".table-grid",
    ],
    correctAnswer: ".table-responsive",
  },
  {
    id: 20,
    question: "Which class is used to set an element as sticky in Bootstrap?",
    options: [".sticky-top", ".sticky", ".fixed-top", ".sticky-element"],
    correctAnswer: ".sticky-top",
  },
  {
    id: 21,
    question: "Which class is used to create a responsive video in Bootstrap?",
    options: [
      ".embed-responsive",
      ".video-responsive",
      ".responsive-video",
      ".video-container",
    ],
    correctAnswer: ".embed-responsive",
  },
  {
    id: 22,
    question: "What is the purpose of the 'col-*' classes in Bootstrap?",
    options: [
      "To define column width in the grid system",
      "To add margins",
      "To style the header",
      "To create buttons",
    ],
    correctAnswer: "To define column width in the grid system",
  },
  {
    id: 23,
    question: "Which class is used to float elements in Bootstrap?",
    options: [".float-*", ".align-*", ".position-*", ".float-element"],
    correctAnswer: ".float-*",
  },
  {
    id: 24,
    question: "Which class is used to make text uppercase in Bootstrap?",
    options: [
      ".text-uppercase",
      ".text-transform",
      ".uppercase",
      ".text-upper",
    ],
    correctAnswer: ".text-uppercase",
  },
  {
    id: 25,
    question: "Which class is used to create a fixed footer in Bootstrap?",
    options: [
      ".fixed-bottom",
      ".footer-fixed",
      ".sticky-footer",
      ".footer-static",
    ],
    correctAnswer: ".fixed-bottom",
  },
];

const BootstrapTest = () => {

    const [shuffledQuestions, setShuffledQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState(new Array(questions.length).fill(''));
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [timeLeft, setTimeLeft] = useState(600); // 10 minutes in seconds
    const [attemptedQuestions, setAttemptedQuestions] = useState(0);
    const [timerColor, setTimerColor] = useState('#00FF00'); // Initial color green
    const [timerPaused, setTimerPaused] = useState(false); // State to track timer pause/resume


    useEffect(() => {
      // Shuffle the questions array randomly when the component mounts
      const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
      };
      setShuffledQuestions(shuffleArray(questions));

      // Scroll to the element with the ID 'css' when the component mounts
      const element = document.getElementById('html');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, []); // Empty dependency array ensures this effect runs only once after the component mounts

    useEffect(() => {
      const timer = setTimeout(() => {
        if (!timerPaused) { // Only decrement time if timer is not paused
          setTimeLeft(timeLeft - 1);

          // Update timer color based on remaining time
          if (timeLeft <= 300 && timeLeft > 120) {
            setTimerColor('#FFFF00'); // Yellow
          } else if (timeLeft <= 120) {
            setTimerColor('darkred'); // Red
          }

          // Check if time is up
          if (timeLeft === 0) {
            setShowScore(true);
          }
        }
      }, 1000);

      return () => clearTimeout(timer);
    }, [timeLeft, timerPaused]);

    const handleOptionSelect = (option) => {
      // Check if the selected option is different from the previously selected option
      if (selectedOptions[currentQuestion] !== option) {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[currentQuestion] = option;
        setSelectedOptions(newSelectedOptions);

        if (!selectedOptions[currentQuestion]) {
          // Increment attempted questions only if the current question didn't have an attempt before
          setAttemptedQuestions(attemptedQuestions + 1);
        }

        if (option === shuffledQuestions[currentQuestion].correctAnswer) {
          setScore(score + 1);
        }
      }
    };

    const handleNextQuestion = () => {
      if (currentQuestion < shuffledQuestions.length - 1) {
        setCurrentQuestion(currentQuestion + 1);
      } else {
        setShowScore(true);
      }
    };

    const handlePreviousQuestion = () => {
      if (currentQuestion > 0) {
        setCurrentQuestion(currentQuestion - 1);
      }
    };

    const handlePauseResumeTimer = () => {
      setTimerPaused(!timerPaused); // Toggle timer pause/resume state
    };


  return (
    <div
      className="container mx-auto p-4"
      id="css"
      style={{
        backgroundColor: "#F4F4F6",
        margin: "5rem",
        borderRadius: "15px",
      }}
    >
      {!showScore ? (
        <div
          className="relative isolate overflow-hidden bg-gray-900"
          style={{ borderRadius: "10px", color: "white" }}
        >
          <h1
            className="text-3xl font-bold text-center mb-8"
            style={{ margin: "1rem" }}
          >
            BOOTSTRAP MOCK TEST
            <br />
            <span style={{ color: "darkred", fontSize: "22px" }}>
              Dream Adoration
            </span>
          </h1>
          <div style={{ margin: "2rem" }}>
            <h2 className="text-2xl font-medium mb-4">
              Question {currentQuestion + 1} of {questions.length}
            </h2>
            <h3 className="text-lg font-semibold mb-2">
              {questions[currentQuestion].question}
            </h3>
            {questions[currentQuestion].options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestion] === option}
                    onChange={() => handleOptionSelect(option)}
                    className="form-radio h-5 w-5 text-primary-600"
                  />
                  <span className="ml-2 ">{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-around">
            <button
              onClick={handlePreviousQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-3 rounded mt-5"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded mt-5"
            >
              Next
            </button>
            <button
              onClick={handlePauseResumeTimer}
              className="bg-green-600 hover:bg-green-700 text-black font-bold py-2 px-4 rounded mt-5"
            >
              {timerPaused ? "Resume" : "Pause"}
            </button>
          </div>
          <div
            className=" mt-1 text-center"
            style={{ color: timerColor, fontWeight: "bold" }}
          >
            Time Left: {Math.floor(timeLeft / 60)}:
            {(timeLeft % 60).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}
          </div>
          <div
            className=" mt-1 text-center"
            style={{ fontWeight: "bold", padding: "10px" }}
          >
            Attempted Questions: {attemptedQuestions} / {questions.length}
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <h3 className="text-lg font-medium mb-2">
            Your Score: {score}/{questions.length}
          </h3>
        </div>
      )}
    </div>
  );
};

export default BootstrapTest;
