import React from "react";
import img from "./Component/Image/ceo.png";
function AfterBanner() {
  return (
    <div className="bg-gray-100 m-2 p-4 rounded-lg">
      <h2 className="text-center">
        Introducing <span style={{ color: "darkblue" }}>'Internship'</span>
      </h2>
      <div className="flex flex-col md:flex-row">
        {/* Flex container with column direction and 1rem gap */}

        <div className="relative flex flex-col md:flex-row bg-clip-border rounded-xl bg-white text-gray-700 shadow-md w-full md:w-2/3 mt-4 md:mt-0">
          <div className="relative w-full md:w-1/2 overflow-hidden text-gray-700 bg-white rounded-r-none bg-clip-border rounded-xl shrink-0">
            <img
              src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1471&amp;q=80"
              alt="card-image"
              className="object-cover w-full h-full"
            />
          </div>
          <div className="p-6 flex-grow">
            <h5 className="font-bold uppercase text-gray-900">
              Choose Our Internship Why?
            </h5>
            <p className="block  font-sans font-normal leading-relaxed text-gray-900">
              <span className="font-bold">Real Experience:</span> Work on real
              projects that matter, hence seeing the impact of your efforts.
            </p>
            <p className="block  font-sans font-normal leading-relaxed text-gray-900">
              <span className="font-bold">Guidance:</span> Learn from some
              experienced professionals who will mentor and nurture you.{" "}
            </p>
            <p className="block  font-sans font-normal leading-relaxed text-gray-900">
              <span className="font-bold">Creative Environment:</span> This
              company holds creative output and teamwork accountable.{" "}
            </p>
            <p className="block  font-sans font-normal leading-relaxed text-gray-900">
              <span className="font-bold">Skill Building:</span> Acquire new
              skills and enhance the existing ones to enhance your career.
            </p>
            <p className="block  font-sans font-normal leading-relaxed text-gray-900">
              <span className="font-bold">Networking:</span> It allows you to
              make new connections, which can benefit you down the line.
            </p>
            <div className="flex flex-wrap gap-2 mt-5">
              <div className="flex flex-wrap gap-2 w-full md:w-auto">
                <a href="/ebook">
                  <button
                    type="button"
                    className="relative px-4 py-2 text-white rounded"
                    style={{ backgroundColor: "#0d1232" }}
                  >
                    EBook
                  </button>
                </a>

                <button
                  type="button"
                  className="relative px-4 py-2 text-white rounded"
                  disabled
                  style={{ backgroundColor: "#0d1232" }}
                >
                  ChatBot
                </button>

                <button
                  type="button"
                  className="relative px-4 py-2 text-white rounded"
                  disabled
                  style={{ backgroundColor: "#0d1232" }}
                >
                  Podcast
                </button>
              </div>

              <div className="flex flex-wrap gap-2 w-full md:w-auto">
                <a href="/interview">
                  {" "}
                  <button
                    type="button"
                    className="relative px-4 py-2 text-white rounded"
                    style={{ backgroundColor: "#0d1232" }}
                  >
                    Interview Preparation
                  </button>
                </a>
                <a href="/mocktest">
                  <button
                    type="button"
                    className="relative px-4 py-2 text-white rounded"
                    style={{ backgroundColor: "#0d1232" }}
                  >
                    Mock Test
                  </button>
                </a>

                <a href="#government">
                  <button
                    type="button"
                    className="relative px-4 py-2 text-white rounded"
                    style={{ backgroundColor: "#0d1232" }}
                  >
                    Government Pocket Test
                  </button>
                </a>
              </div>

              <div className="flex flex-wrap gap-2 w-full md:w-auto">
                <a href="/blog">
                  {" "}
                  <button
                    type="button"
                    className="relative px-4 py-2 text-white rounded"
                    style={{ backgroundColor: "#0d1232" }}
                  >
                    Blog
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="relative grid h-full w-full md:w-1/2 flex-col items-end justify-center overflow-hidden rounded-xl bg-white bg-clip-border text-center mt-4 md:ml-6 text-gray-700">
          <div
            className="absolute inset-0 m-0 h-full w-full overflow-hidden rounded-none bg-transparent bg-cover bg-clip-border bg-center text-gray-700 shadow-none"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1552960562-daf630e9278b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80')",
            }}
          >
            <div className="absolute inset-0 w-full h-full bg-gradient-to-t from-black/100 via-black/50"></div>
          </div>
          <div className="relative p-6 px-6 py-16 md:px-12">
            <h2 className="mb-6 block font-sans text-2xl font-medium leading-[1.5] text-gray-100 tracking-normal antialiased">
              Dream Adoration is happy to announce its internship programme for
              energetic, motivated young people who want to make a difference.
            </h2>
            <img
              alt="CEO"
              src={img}
              className="relative inline-block h-[94px] w-[94px] rounded-full border-3 border-white object-cover object-center"
            />
            <h5 className="block font-sans text-xl antialiased font-semibold leading-snug tracking-normal text-gray-300">
              Aman Kumar
              <p className="text-sm">(Founder & CEO)</p>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AfterBanner;
