import React, { useState } from "react";
import ageVideo from "../../Component/Image/Service/Age.mp4";

const AgeCalculator = () => {
  const [birthDate, setBirthDate] = useState("");
  const [age, setAge] = useState(null);
  const [error, setError] = useState(null);

  const calculateAge = () => {
    if (!birthDate) {
      setError("Please enter your date of birth.");
      return;
    }

    setError(null);

    const today = new Date();
    const birth = new Date(birthDate);

    if (birth > today) {
      setError("Birth date cannot be in the future.");
      return;
    }

    let years = today.getFullYear() - birth.getFullYear();
    let months = today.getMonth() - birth.getMonth();
    let days = today.getDate() - birth.getDate();

    if (days < 0) {
      // Borrow days from the previous month
      const prevMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      days += prevMonth.getDate();
      months -= 1;
    }

    if (months < 0) {
      // Borrow months from the previous year
      months += 12;
      years -= 1;
    }

    setAge({ years, months, days });
  };

  return (
    <div className="bg-white mt-12">
      <div className="mx-auto max-w-7xl sm:px-6 sm:py-12">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle cx="512" cy="512" r="512" fill="url(#gradient)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="gradient">
                <stop stopColor="#7775D6" />
                <stop offset="1" stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-left lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-balance text-3xl font-semibold tracking-tight text-white sm:text-4xl">
              Calculate Your Exact Age in Seconds
            </h2>
            <p className="mt-6 text-pretty text-lg/8 text-gray-300">
              Enter your date of birth and instantly find out your precise age, including years, months, and days. Simplify calculations with our easy-to-use age calculator.
            </p>
            <div className="mt-10 mb-10 flex items-left gap-x-6 lg:justify-start">
              <input
                type="date"
                value={birthDate}
                onChange={(e) => setBirthDate(e.target.value)}
                className="border text-white-600 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700"
                placeholder="Enter Your Date of Birth"
              />
              <button
                onClick={calculateAge}
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Calculate
              </button>
            </div>
            {error && <p className="text-red-500 mt-4">{error}</p>}
          </div>
          {/* Video only shown on non-mobile screens */}
          <div className="relative h-80 hidden lg:block" style={{ marginTop: "8rem" }}>
            <video
              src={ageVideo}
              autoPlay
              loop
              muted
              className="w-full h-full object-cover" style={{borderRadius:'1rem'}}
            />
          </div>
        </div>
      </div>

      {/* Hidden Section */}
      {age !== null && !error && (
        <div className="bg-white">
          <div className="mx-auto max-w-7xl">
            <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
              <svg
                viewBox="0 0 1024 1024"
                className="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                aria-hidden="true"
              >
                <circle cx="512" cy="512" r="512" fill="url(#gradient)" fillOpacity="0.7" />
                <defs>
                  <radialGradient id="gradient">
                    <stop stopColor="#7775D6" />
                    <stop offset="1" stopColor="#E935C1" />
                  </radialGradient>
                </defs>
              </svg>
              <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-12 lg:text-left">
                <p className="text-pretty text-lg/8 text-gray-300">
                  Your Age - <span className="bg-aliceblue text-darkgreen rounded-md px-2 font-bold">{age.years} years</span>, {age.months} months, and {age.days} days
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgeCalculator;
