import primetouch from "./Data/PrimeTouch.json"; // Import JSON data
import PrimeTouchMeta from "./Meta/PrimeTouchMeta";
import courseVideo from "../Component/Image/OurService.mp4";
// import ServiceModal from "./ServiceModal";
import ServiceModal from "../ServiceModal";
import React, { useState, useEffect } from "react";
import ServiceCard from "./ServiceCard";
import Applications from "./Applications";

function PrimeTouch() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div style={{ marginTop: "3.5rem" }}>
      <PrimeTouchMeta />
      <div className="relative">
        <video autoPlay loop muted className="object-cover w-full h-full">
          <source src={courseVideo} type="video/mp4" />
        </video>
        <div className="absolute inset-0 flex items-center justify-center">
          {/* Hide this div on mobile screens */}
          <div className="hidden sm:flex bg-white/40 rounded-md p-4 text-center flex-col items-center gap-6 w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[70%]">
            <h1 className="text-xl md:text-2xl lg:text-3xl font-extrabold">
              Our Services
              <br />
              <span style={{ color: "darkorange" }}>
                Dream Adoration Service - Your trusted partner in transforming
                dreams into reality,
              </span>
            </h1>
            <p className="text-base md:text-lg lg:text-lg font-bold">
              Adding vibrant colors to every cherished aspiration!
            </p>
            <button
              type="button"
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
              onClick={openModal}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      {/* Display text below video for mobile view */}
      <div className="block md:hidden p-4 text-center bg-white/70 rounded-md">
        <h1 className="text-xxl font-extrabold" style={{ color: "#F1B434" }}>
          Dream Adoration Service - Your trusted partner in transforming dreams
          into reality,
        </h1>
        <p className="text-base font-bold">
          Adding vibrant colors to every cherished aspiration!
        </p>
        <button
          type="button"
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
          onClick={openModal}
        >
          Contact Us
        </button>
      </div>
      <ServiceModal isOpen={isModalOpen} onClose={closeModal} />
      <Applications/>
      <div
        style={{
          backgroundColor: "#f4f4f6",
          margin: "1rem",
          borderRadius: "10px",
        }}
      >

        <div className="flex flex-wrap justify-center">
          {primetouch.map((service, index) => (
            <div
              key={index}
              className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 m-4"
            >
              <img
                src={service.image}
                alt=""
                style={{ borderRadius: "0.3rem" }}
              />

              <a href={service.href}>
                <h5 className="m-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
                  {service.title}
                </h5>
                <p
                  style={{ fontWeight: "400" }}
                  className="m-2 font-normal text-gray-500 dark:text-gray-400"
                >
                  {service.description}
                </p>
                <span className="inline-flex items-center text-blue-600 hover:underline">
                  {service.linkText}
                </span>
              </a>
            </div>
          ))}
        </div>
      </div>


      <ServiceCard/>
    </div>
  );
}

export default PrimeTouch;
