import React, { useState, useEffect } from "react";

const questions = [
  {
    id: 1,
    question: "What is Node.js?",
    options: [
      "A JavaScript runtime environment",
      "A web browser",
      "A programming language",
      "An operating system"
    ],
    correctAnswer: "A JavaScript runtime environment"
  },
  {
    id: 2,
    question: "Which engine powers Node.js?",
    options: ["V8", "SpiderMonkey", "Chakra", "Nashorn"],
    correctAnswer: "V8"
  },
  {
    id: 3,
    question: "How does Node.js handle asynchronous operations?",
    options: [
      "Event loop",
      "Multi-threading",
      "Polling",
      "Synchronous blocking"
    ],
    correctAnswer: "Event loop"
  },
  {
    id: 4,
    question: "Which of the following is a core module in Node.js?",
    options: ["fs", "lodash", "express", "react"],
    correctAnswer: "fs"
  },
  {
    id: 5,
    question: "What is npm in Node.js?",
    options: [
      "Node Programming Manager",
      "Node Package Manager",
      "Node Process Manager",
      "Node Proxy Manager"
    ],
    correctAnswer: "Node Package Manager"
  },
  {
    id: 6,
    question: "How do you import a core module in Node.js?",
    options: [
      "require('moduleName')",
      "import('moduleName')",
      "include('moduleName')",
      "fetch('moduleName')"
    ],
    correctAnswer: "require('moduleName')"
  },
  {
    id: 7,
    question: "What does the 'fs' module in Node.js handle?",
    options: [
      "File system operations",
      "HTTP requests",
      "Database connections",
      "Security features"
    ],
    correctAnswer: "File system operations"
  },
  {
    id: 8,
    question: "Which of the following is true about Node.js?",
    options: [
      "It is single-threaded",
      "It is multi-threaded",
      "It only works on Windows",
      "It is written in Python"
    ],
    correctAnswer: "It is single-threaded"
  },
  {
    id: 9,
    question: "What is the purpose of the 'package.json' file?",
    options: [
      "To define project dependencies",
      "To store environment variables",
      "To write application code",
      "To store database configuration"
    ],
    correctAnswer: "To define project dependencies"
  },
  {
    id: 10,
    question: "Which command is used to initialize a new Node.js project?",
    options: [
      "npm init",
      "node init",
      "npm install",
      "node start"
    ],
    correctAnswer: "npm init"
  },
  {
    id: 11,
    question: "What does the 'http' module in Node.js do?",
    options: [
      "Handles HTTP requests and responses",
      "Manages database operations",
      "Reads and writes files",
      "Creates a user interface"
    ],
    correctAnswer: "Handles HTTP requests and responses"
  },
  {
    id: 12,
    question: "Which method is used to create an HTTP server in Node.js?",
    options: [
      "http.createServer()",
      "http.create()",
      "server.createHttp()",
      "server.create()"
    ],
    correctAnswer: "http.createServer()"
  },
  {
    id: 13,
    question: "How do you install a package using npm?",
    options: [
      "npm install packageName",
      "npm add packageName",
      "node install packageName",
      "node add packageName"
    ],
    correctAnswer: "npm install packageName"
  },
  {
    id: 14,
    question: "What does the 'path' module in Node.js handle?",
    options: [
      "File and directory paths",
      "Network requests",
      "Database connections",
      "Security encryption"
    ],
    correctAnswer: "File and directory paths"
  },
  {
    id: 15,
    question: "Which object in Node.js represents the current process?",
    options: ["process", "current", "node", "global"],
    correctAnswer: "process"
  },
  {
    id: 16,
    question: "What is the purpose of 'buffer' in Node.js?",
    options: [
      "To handle binary data",
      "To manage HTTP requests",
      "To store user sessions",
      "To cache data"
    ],
    correctAnswer: "To handle binary data"
  },
  {
    id: 17,
    question: "Which command runs a Node.js application?",
    options: [
      "node app.js",
      "npm start app.js",
      "run app.js",
      "nodejs app.js"
    ],
    correctAnswer: "node app.js"
  },
  {
    id: 18,
    question: "Which of the following is an event emitter in Node.js?",
    options: ["EventEmitter", "Emitter", "EventManager", "EventLoop"],
    correctAnswer: "EventEmitter"
  },
  {
    id: 19,
    question: "How do you exit a Node.js process?",
    options: ["process.exit()", "process.stop()", "node.exit()", "exit()"],
    correctAnswer: "process.exit()"
  },
  {
    id: 20,
    question: "Which of the following is NOT a core module in Node.js?",
    options: ["crypto", "events", "express", "dns"],
    correctAnswer: "express"
  },
  {
    id: 21,
    question: "What does the 'os' module in Node.js handle?",
    options: [
      "Operating system-related utilities",
      "Database management",
      "Security encryption",
      "Networking protocols"
    ],
    correctAnswer: "Operating system-related utilities"
  },
  {
    id: 22,
    question: "How do you handle exceptions in Node.js?",
    options: [
      "Using try-catch blocks",
      "Using error.listen()",
      "Using error.catch()",
      "Using exception.throw()"
    ],
    correctAnswer: "Using try-catch blocks"
  },
  {
    id: 23,
    question: "What is middleware in Node.js?",
    options: [
      "A function that processes requests and responses",
      "A core module",
      "An HTTP server",
      "A database connector"
    ],
    correctAnswer: "A function that processes requests and responses"
  },
  {
    id: 24,
    question: "What is the purpose of 'stream' in Node.js?",
    options: [
      "To handle data in chunks",
      "To manage file uploads only",
      "To create HTTP servers",
      "To encrypt sensitive data"
    ],
    correctAnswer: "To handle data in chunks"
  },
  {
    id: 25,
    question: "How do you make a directory in Node.js?",
    options: [
      "fs.mkdir()",
      "fs.dir()",
      "fs.createDir()",
      "fs.newDir()"
    ],
    correctAnswer: "fs.mkdir()"
  },
  {
    id: 26,
    question: "Which method reads a file synchronously in Node.js?",
    options: [
      "fs.readFileSync()",
      "fs.readFile()",
      "fs.read()",
      "fs.syncReadFile()"
    ],
    correctAnswer: "fs.readFileSync()"
  },
  {
    id: 27,
    question: "What is a callback in Node.js?",
    options: [
      "A function passed as an argument to another function",
      "A feature for HTTP requests",
      "A process for handling files",
      "A module for managing APIs"
    ],
    correctAnswer: "A function passed as an argument to another function"
  },
  {
    id: 28,
    question: "What is the default scope of variables in Node.js?",
    options: [
      "Module scope",
      "Global scope",
      "Function scope",
      "Class scope"
    ],
    correctAnswer: "Module scope"
  },
  {
    id: 29,
    question: "Which module is used for making HTTPS requests in Node.js?",
    options: ["https", "http", "url", "path"],
    correctAnswer: "https"
  },
  {
    id: 30,
    question: "What is the global object in Node.js?",
    options: ["global", "window", "this", "node"],
    correctAnswer: "global"
  },
  {
    id: 31,
    question: "Which of the following is true about Node.js streams?",
    options: [
      "They handle reading/writing data in chunks",
      "They are always synchronous",
      "They do not support pipes",
      "They cannot be paused"
    ],
    correctAnswer: "They handle reading/writing data in chunks"
  },
  {
    id: 32,
    question: "What does 'child_process' module handle in Node.js?",
    options: [
      "Spawning and managing child processes",
      "Handling database operations",
      "Managing event listeners",
      "Parsing JSON files"
    ],
    correctAnswer: "Spawning and managing child processes"
  },
  {
    id: 33,
    question: "What is the default value of `process.env.NODE_ENV`?",
    options: ["undefined", "development", "production", "test"],
    correctAnswer: "undefined"
  },
  {
    id: 34,
    question: "How do you listen for data in a readable stream?",
    options: [
      "stream.on('data', callback)",
      "stream.listen('data', callback)",
      "stream.emit('data', callback)",
      "stream.read('data', callback)"
    ],
    correctAnswer: "stream.on('data', callback)"
  },
  {
    id: 35,
    question: "Which method writes data to a writable stream?",
    options: ["stream.write()", "stream.push()", "stream.end()", "stream.flush()"],
    correctAnswer: "stream.write()"
  },
  {
    id: 36,
    question: "What is the use of the 'zlib' module in Node.js?",
    options: [
      "Compression and decompression",
      "Database handling",
      "Creating web servers",
      "Managing file uploads"
    ],
    correctAnswer: "Compression and decompression"
  },
  {
    id: 37,
    question: "How do you parse query strings in Node.js?",
    options: [
      "Using the 'querystring' module",
      "Using the 'url' module",
      "Using the 'http' module",
      "Using the 'path' module"
    ],
    correctAnswer: "Using the 'querystring' module"
  },
  {
    id: 38,
    question: "What does the 'dns' module in Node.js handle?",
    options: [
      "Domain name resolution",
      "HTTP requests",
      "File system operations",
      "User authentication"
    ],
    correctAnswer: "Domain name resolution"
  },
  {
    id: 39,
    question: "How do you export a module in Node.js?",
    options: [
      "module.exports = moduleName",
      "exports.moduleName",
      "import moduleName",
      "define(moduleName)"
    ],
    correctAnswer: "module.exports = moduleName"
  },
  {
    id: 40,
    question: "What is the purpose of 'readline' module?",
    options: [
      "Reading input from command line",
      "Reading files line by line",
      "Reading streams synchronously",
      "Creating HTTP connections"
    ],
    correctAnswer: "Reading input from command line"
  },
  {
    id: 41,
    question: "How do you create a new event in EventEmitter?",
    options: [
      "emitter.emit('eventName')",
      "emitter.trigger('eventName')",
      "emitter.call('eventName')",
      "emitter.fire('eventName')"
    ],
    correctAnswer: "emitter.emit('eventName')"
  },
  {
    id: 42,
    question: "How do you start a Node.js REPL session?",
    options: ["node", "npm start", "node run", "repl start"],
    correctAnswer: "node"
  },
  {
    id: 43,
    question: "What is the role of the 'util' module?",
    options: [
      "Provides utility functions for debugging",
      "Manages file uploads",
      "Creates server instances",
      "Handles session data"
    ],
    correctAnswer: "Provides utility functions for debugging"
  },
  {
    id: 44,
    question: "What does 'npm install -g' do?",
    options: [
      "Installs a package globally",
      "Installs a package locally",
      "Removes a package globally",
      "Lists installed global packages"
    ],
    correctAnswer: "Installs a package globally"
  },
  {
    id: 45,
    question: "What does 'npm update' do?",
    options: [
      "Updates installed packages",
      "Installs new packages",
      "Uninstalls packages",
      "Lists outdated packages"
    ],
    correctAnswer: "Updates installed packages"
  },
  {
    id: 46,
    question: "How do you check the version of Node.js installed?",
    options: [
      "node -v",
      "node --version",
      "Both a and b",
      "node version"
    ],
    correctAnswer: "Both a and b"
  },
  {
    id: 47,
    question: "What is the 'cluster' module used for?",
    options: [
      "Enabling multi-core performance",
      "Managing file uploads",
      "Handling stream data",
      "Executing HTTP requests"
    ],
    correctAnswer: "Enabling multi-core performance"
  },
  {
    id: 48,
    question: "What is the purpose of 'dotenv' in Node.js?",
    options: [
      "Managing environment variables",
      "Running background tasks",
      "Parsing JSON files",
      "Handling HTTP responses"
    ],
    correctAnswer: "Managing environment variables"
  },
  {
    id: 49,
    question: "How do you uninstall a package using npm?",
    options: [
      "npm uninstall packageName",
      "npm remove packageName",
      "npm delete packageName",
      "npm clear packageName"
    ],
    correctAnswer: "npm uninstall packageName"
  },
  {
    id: 50,
    question: "How do you stop a running Node.js process?",
    options: [
      "Ctrl + C",
      "Ctrl + Z",
      "Ctrl + D",
      "Ctrl + Q"
    ],
    correctAnswer: "Ctrl + C"
  }

];

const NodeTest = () => {

      const [shuffledQuestions, setShuffledQuestions] = useState([]);
      const [currentQuestion, setCurrentQuestion] = useState(0);
      const [selectedOptions, setSelectedOptions] = useState(new Array(questions.length).fill(''));
      const [score, setScore] = useState(0);
      const [showScore, setShowScore] = useState(false);
      const [timeLeft, setTimeLeft] = useState(1500); // 25 minutes in seconds
      const [attemptedQuestions, setAttemptedQuestions] = useState(0);
      const [timerColor, setTimerColor] = useState('#00FF00'); // Initial color green
      const [timerPaused, setTimerPaused] = useState(false); // State to track timer pause/resume


      useEffect(() => {
        // Shuffle the questions array randomly when the component mounts
        const shuffleArray = (array) => {
          return array.sort(() => Math.random() - 0.5);
        };
        setShuffledQuestions(shuffleArray(questions));

        // Scroll to the element with the ID 'css' when the component mounts
        const element = document.getElementById('html');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, []); // Empty dependency array ensures this effect runs only once after the component mounts

      useEffect(() => {
        const timer = setTimeout(() => {
          if (!timerPaused) { // Only decrement time if timer is not paused
            setTimeLeft(timeLeft - 1);

            // Update timer color based on remaining time
            if (timeLeft <= 300 && timeLeft > 120) {
              setTimerColor('#FFFF00'); // Yellow
            } else if (timeLeft <= 120) {
              setTimerColor('darkred'); // Red
            }

            // Check if time is up
            if (timeLeft === 0) {
              setShowScore(true);
            }
          }
        }, 1000);

        return () => clearTimeout(timer);
      }, [timeLeft, timerPaused]);

      const handleOptionSelect = (option) => {
        // Check if the selected option is different from the previously selected option
        if (selectedOptions[currentQuestion] !== option) {
          const newSelectedOptions = [...selectedOptions];
          newSelectedOptions[currentQuestion] = option;
          setSelectedOptions(newSelectedOptions);

          if (!selectedOptions[currentQuestion]) {
            // Increment attempted questions only if the current question didn't have an attempt before
            setAttemptedQuestions(attemptedQuestions + 1);
          }

          if (option === shuffledQuestions[currentQuestion].correctAnswer) {
            setScore(score + 1);
          }
        }
      };

      const handleNextQuestion = () => {
        if (currentQuestion < shuffledQuestions.length - 1) {
          setCurrentQuestion(currentQuestion + 1);
        } else {
          setShowScore(true);
        }
      };

      const handlePreviousQuestion = () => {
        if (currentQuestion > 0) {
          setCurrentQuestion(currentQuestion - 1);
        }
      };

      const handlePauseResumeTimer = () => {
        setTimerPaused(!timerPaused); // Toggle timer pause/resume state
      };

  return (
    <div
      className="container mx-auto p-4"
      id="nodejs"
      style={{
        backgroundColor: "#F4F4F6",
        margin: "5rem",
        borderRadius: "15px",
      }}
    >
      {!showScore ? (
        <div
          className="relative isolate overflow-hidden bg-gray-900"
          style={{ borderRadius: "10px", color: "white" }}
        >
          <h1
            className="text-3xl font-bold text-center mb-8"
            style={{ margin: "1rem" }}
          >
            Node Js Mock Test
            <br />
            <span style={{ color: "darkred", fontSize: "22px" }}>
              Dream Adoration
            </span>
          </h1>
          <div style={{ margin: "2rem" }}>
            <h2 className="text-2xl font-medium mb-4">
              Question {currentQuestion + 1} of {questions.length}
            </h2>
            <h3 className="text-lg font-semibold mb-2">
              {questions[currentQuestion].question}
            </h3>
            {questions[currentQuestion].options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestion] === option}
                    onChange={() => handleOptionSelect(option)}
                    className="form-radio h-5 w-5 text-primary-600"
                  />
                  <span className="ml-2 ">{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-around">
            <button
              onClick={handlePreviousQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-3 rounded mt-5"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded mt-5"
            >
              Next
            </button>
            <button
              onClick={handlePauseResumeTimer}
              className="bg-green-600 hover:bg-green-700 text-black font-bold py-2 px-4 rounded mt-5"
            >
              {timerPaused ? "Resume" : "Pause"}
            </button>
          </div>
          <div
            className=" mt-1 text-center"
            style={{ color: timerColor, fontWeight: "bold" }}
          >
            Time Left: {Math.floor(timeLeft / 60)}:
            {(timeLeft % 60).toLocaleString("en-US", {
              minimumIntegerDigits: 2,
            })}
          </div>
          <div
            className=" mt-1 text-center"
            style={{ fontWeight: "bold", padding: "10px" }}
          >
            Attempted Questions: {attemptedQuestions} / {questions.length}
          </div>
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <h3 className="text-lg font-medium mb-2">
            Your Score: {score}/{questions.length}
          </h3>
        </div>
      )}
    </div>
  );
};

export default NodeTest;
