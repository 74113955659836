import React, { useState, useEffect, useRef } from "react";

function OurClient() {
  const [reviews, setReviews] = useState(0); // Student's Reviews
  const [rating, setRating] = useState(0); // Rating
  const [happyStudents, setHappyStudents] = useState(0); // Happy Students
  const sectionRef = useRef(null);

  useEffect(() => {
    const animateValue = (start, end, duration, setValue, isDecimal = false) => {
      let startTime = null;

      const step = (timestamp) => {
        if (!startTime) startTime = timestamp;
        const progress = Math.min((timestamp - startTime) / duration, 1);
        const value = isDecimal
          ? (progress * (end - start) + start).toFixed(1)
          : Math.floor(progress * (end - start) + start);
        setValue(value);
        if (progress < 1) {
          requestAnimationFrame(step);
        }
      };

      requestAnimationFrame(step);
    };

    const handleScrollAnimation = (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        animateValue(0, 120, 3000, setReviews); // Animate 0 to 119 for reviews
        animateValue(0, 4.9, 3000, setRating, true); // Animate 0 to 4.9 for rating
        animateValue(0, 300, 3000, setHappyStudents); // Animate 0 to 300 for happy students
      }
    };

    const observer = new IntersectionObserver(handleScrollAnimation, {
      root: null,
      threshold: 0.1,
    });

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      style={{
        marginTop: "1rem",
        backgroundColor: "#0d1232",
        padding: "1rem",
        margin: "1rem",
        borderRadius: "0.5rem",
      }}
    >
      <section>
        <div>
          <h2 className="font-manrope text-3xl sm:text-4xl leading-10 flex items-center justify-center flex-col text-center">
            <span style={{ color: "white" }}>Student's</span>{" "}
            <span style={{ color: "red" }}>reviews & rating</span>
          </h2>
          <div className="grid grid-cols-12 ">
            <div className="col-span-12 w-full min-h-[230px]">
              <div className="grid grid-cols-12 h-full px-20 max-lg:py-16 rounded-xl bg-gray-100 w-full max-xl:max-w-2xl">
                <div className="col-span-12 md:col-span-8 flex items-center">
                  <div className="flex flex-col sm:flex-row items-center max-lg:justify-center w-full h-full gap-4">
                    <div className="sm:pr-3 sm:border-r border-gray-200 flex items-center justify-center flex-col">
                      <h2 className="font-manrope font-bold text-5xl text-black text-center mb-4">
                        {rating}/5
                      </h2>
                      <p
                        className="font-bold text-lg leading-8"
                        style={{ color: "darkred" }}
                      >
                        Highly Rated
                      </p>
                    </div>
                    <div className="sm:pl-3 border-gray-200 flex items-center justify-center flex-col">
                      <h2 className="font-manrope font-bold text-5xl text-black text-center mb-4">
                        {reviews}+
                      </h2>

                      <p
                        className="font-bold text-lg leading-8"
                        style={{ color: "darkred" }}
                      >
                        Student's Reviews
                      </p>
                    </div>
                    <div className="sm:pl-3 sm:border-l border-gray-200 flex items-center justify-center flex-col">
                      <h2 className="font-manrope font-bold text-5xl text-black text-center mb-4">
                        {happyStudents}+
                      </h2>

                      <p
                        className="font-bold text-lg leading-8"
                        style={{ color: "darkred" }}
                      >
                        Happy Student's
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-span-12 md:col-span-4 max-lg:mt-8 md:pl-8">
                  <div className="flex items-center flex-col justify-center w-full h-full ">
                    <a
                      className="rounded-full px-4 py-3 font-semibold text-md text-white whitespace-nowrap mb-6 w-full text-center shadow-sm shadow-transparent transition-all duration-500 hover:bg-indigo-700 hover:shadow-indigo-400"
                      style={{ backgroundColor: "#0d1232" }}
                      href="https://maps.app.goo.gl/5jSCvvWH7Y7Mt3oy6"
                    >
                      <button>Write A Review</button>
                    </a>
                    <a
                      className="rounded-full px-4 py-3 text-white font-semibold text-md  whitespace-nowrap w-full text-center shadow-sm shadow-transparent transition-all duration-500 hover:shadow-indigo-200"
                      href="https://maps.app.goo.gl/5jSCvvWH7Y7Mt3oy6"
                      style={{ backgroundColor: "#0d1232" }}
                    >
                      <button>See All Reviews</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default OurClient;
