import React, { useState } from "react";
import emailvide from "../../Component/Image/Service/email.mp4";

const EmailValidator = () => {
  const [isSectionVisible, setSectionVisible] = useState(false);

  const handleValidateClick = (event) => {
    event.preventDefault(); // Prevent the default action of the button
    setSectionVisible(true); // Show the hidden section
  };

  return (
    <div className="bg-white mt-12">
      <div className="mx-auto max-w-7xl sm:px-6 sm:py-12">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle cx="512" cy="512" r="512" fill="url(#gradient)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="gradient">
                <stop stopColor="#7775D6" />
                <stop offset="1" stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-left lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-balance text-3xl font-semibold tracking-tight text-white sm:text-4xl">
              Smart Email Validation for Accurate Submissions
            </h2>
            <p className="mt-6 text-pretty text-lg/8 text-gray-300">
              Validate email inputs effortlessly with real-time error detection and guidance. Improve accuracy and streamline form submissions for a seamless user experience.
            </p>
            <div className="mt-10 mb-10 flex items-left gap-x-6 lg:justify-start">
              <input
                type="text"
                id="email"
                className="border text-white-600 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700"
                placeholder="Enter Your Email id"
              />
              <button
                onClick={handleValidateClick}
                className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Validate
              </button>
            </div>
          </div>
          {/* Video only shown on non-mobile screens */}
          <div className="relative h-80 hidden lg:block" style={{ marginTop: "6rem" }}>
            <video
              src={emailvide}
              autoPlay
              loop
              muted
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>

      {/* Hidden Section */}
      {isSectionVisible && (
        <div className="bg-white">
          <div className="mx-auto max-w-7xl">
            <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
              <svg
                viewBox="0 0 1024 1024"
                className="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                aria-hidden="true"
              >
                <circle cx="512" cy="512" r="512" fill="url(#gradient)" fillOpacity="0.7" />
                <defs>
                  <radialGradient id="gradient">
                    <stop stopColor="#7775D6" />
                    <stop offset="1" stopColor="#E935C1" />
                  </radialGradient>
                </defs>
              </svg>
              <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-12 lg:text-left">
                <p className="text-pretty text-lg/8 text-gray-300">
                  Format - <span className="bg-aliceblue text-darkgreen rounded-md px-2 font-bold">Valid</span>
                  <br /> This email address has the correct format and is not gibberish.
                </p>
                <br />
                <p className="text-pretty text-lg/8 text-gray-300">
                  Server status - <span className="bg-aliceblue text-darkgreen rounded-md px-2 font-bold">Valid</span>
                  <br /> MX records are present for the domain and we can connect to the SMTP server these MX records point to.
                </p>
              </div>
              <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-12 lg:text-left">
                <p className="text-pretty text-lg/8 text-gray-300">
                  Type - <span className="bg-aliceblue text-darkgreen rounded-md px-2 font-bold">Professional</span>
                  <br /> The domain name isn't used for webmails or for creating temporary email addresses.
                </p>
                <br />
                <p className="text-pretty text-lg/8 text-gray-300">
                  Email status - <span className="bg-aliceblue text-darkgreen rounded-md px-2 font-bold">Valid</span>
                  <br /> This email address exists and can receive emails.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailValidator;
