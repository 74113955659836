import React, { useState } from "react";
import courseVideo from "./Image/Wardiere.mp4";
import Modal from "./Modal";
import Ourservices from "./Ourservices";
import CourseMeta from "./Meta/CourseMeta";
import DevelopementCourse from "./DevelopementCourse";

function Course() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <CourseMeta />
      <div className="relative">
        <video autoPlay loop muted className="object-cover w-full h-full">
          <source src={courseVideo} type="video/mp4" />
        </video>
        <div className="absolute inset-0 flex items-center justify-center">
          {/* Hide this div on mobile screens */}
          <div className="hidden sm:flex bg-white/60 rounded-md p-4 text-center flex-col items-center gap-6 w-[90%] sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[70%]">
            <h1 className="text-xl md:text-2xl lg:text-3xl font-extrabold">
              Our
              <br />
              <span style={{ color: "#F1B434" }}>Popular Project Language</span>
            </h1>
            <p className="text-base md:text-lg lg:text-xl font-bold">
              Shape Your Career with World-Class Online Training at Dream
              Adoration
            </p>
            <button
              type="button"
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
              onClick={openModal}
            >
              Registration
            </button>
          </div>
        </div>
      </div>
      {/* Display text below video for mobile view */}
      <div className="block md:hidden p-4 text-center bg-white/70 rounded-md">
      <h1 className="text-xl md:text-2xl lg:text-3xl font-extrabold">
              Our
              <br />
              <span style={{ color: "#F1B434" }}>Popular Project Language</span>
            </h1>
        <p className="text-base font-bold">
          Shape Your Career with World-Class Online Training at Dream Adoration
        </p>
        <button
          type="button"
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
          onClick={openModal}
        >
          Registration
        </button>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} />
      <DevelopementCourse />
      <Ourservices />
    </div>
  );
}

export default Course;
