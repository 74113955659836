import React, { useState, useEffect } from 'react';

const questions = [
  {
    id: 1,
    question: 'What does HTML stand for?',
    options: ['Hyper Text Markup Language', 'Hyperlinks and Text Markup Language', 'Home Tool Markup Language', 'Hyper Tool Markup Language'],
    correctAnswer: 'Hyper Text Markup Language'
  },
  {
    id: 2,
    question: 'What is the correct HTML for creating a hyperlink?',
    options: ['<a url="http://example.com">Example</a>', '<a href="http://example.com">Example</a>', '<a link="http://example.com">Example</a>', '<hyperlink>http://example.com</hyperlink>'],
    correctAnswer: '<a href="http://example.com">Example</a>'
  },
  // Add more questions here
  {
    id: 3,
    question: 'What is the correct HTML for inserting an image?',
    options: ['<img alt="MyImage" src="image.jpg">', '<image src="image.jpg" alt="MyImage">', '<img src="image.jpg" alt="MyImage">', '<img href="image.jpg" alt="MyImage">'],
    correctAnswer: '<img src="image.jpg" alt="MyImage">'
  },
  {
    id: 4,
    question: 'How can you make a numbered list in HTML?',
    options: ['<ol>', '<list>', '<ul>', '<dl>'],
    correctAnswer: '<ol>'
  },
  {
    id: 5,
    question: 'What is the correct HTML for making a text input field?',
    options: ['<input type="textfield">', '<input type="text">', '<textinput type="text">', '<textfield>'],
    correctAnswer: '<input type="text">'
  },
  {
    id: 6,
    question: 'Which HTML element defines the title of a document?',
    options: ['<head>', '<title>', '<meta>', '<body>'],
    correctAnswer: '<title>'
  },
  {
    id: 7,
    question: 'How can you open a link in a new tab/browser window?',
    options: ['<a href="url" target="_blank">', '<a href="url" new>', '<a href="url" target="new">', '<a href="url" target="blank">'],
    correctAnswer: '<a href="url" target="_blank">'
  },
  {
    id: 8,
    question: 'What is the correct HTML for creating a checkbox?',
    options: ['<checkbox>', '<input type="check">', '<check>', '<input type="checkbox">'],
    correctAnswer: '<input type="checkbox">'
  },
  {
    id: 9,
    question: 'What does CSS stand for?',
    options: ['Cascading Style Sheets', 'Creative Style Sheets', 'Computer Style Sheets', 'Colorful Style Sheets'],
    correctAnswer: 'Cascading Style Sheets'
  },
  {
    id: 10,
    question: 'Which HTML element is used to display a scalar measurement within a range?',
    options: ['<meter>', '<gauge>', '<range>', '<scale>'],
    correctAnswer: '<meter>'
  },
  {
    id: 11,
    question: 'Which HTML attribute specifies an alternate text for an image, if the image cannot be displayed?',
    options: ['alt', 'src', 'title', 'href'],
    correctAnswer: 'alt'
  },
  {
    id: 12,
    question: 'Which HTML element is used to specify a header for a document or section?',
    options: ['<header>', '<head>', '<nav>', '<section>'],
    correctAnswer: '<header>'
  },
  {
    id: 13,
    question: 'Which HTML element defines navigation links?',
    options: ['<navigation>', '<nav>', '<navigate>', '<navigational>'],
    correctAnswer: '<nav>'
  },
  {
    id: 14,
    question: 'Which HTML element defines the title of a work (e.g. a book, a song, etc.)?',
    options: ['<work>', '<title>', '<heading>', '<header>'],
    correctAnswer: '<title>'
  },
  {
    id: 15,
    question: 'Which HTML element is used to define emphasized text?',
    options: ['<bold>', '<important>', '<em>', '<italic>'],
    correctAnswer: '<em>'
  },
  {
    id: 16,
    question: 'Which HTML attribute specifies the URL of the page the link goes to?',
    options: ['url', 'src', 'link', 'href'],
    correctAnswer: 'href'
  },
  {
    id: 17,
    question: 'Which HTML element is used to define an unordered (bulleted) list?',
    options: ['<list>', '<ul>', '<ol>', '<dl>'],
    correctAnswer: '<ul>'
  },
  {
    id: 18,
    question: 'Which HTML element defines the caption for a table?',
    options: ['<caption>', '<td>', '<th>', '<table>'],
    correctAnswer: '<caption>'
  },
  {
    id: 19,
    question: 'Which HTML attribute specifies the alternative text for an image?',
    options: ['src', 'title', 'alt', 'href'],
    correctAnswer: 'alt'
  },
  {
    id: 20,
    question: 'Which HTML element is used to specify a footer for a document or section?',
    options: ['<footer>', '<foot>', '<end>', '<bottom>'],
    correctAnswer: '<footer>'
  },
  // Add more questions here
  {
    id: 21,
    question: 'Which HTML element defines a paragraph?',
    options: ['<p>', '<paragraph>', '<para>', '<pp>'],
    correctAnswer: '<p>'
  },
  {
    id: 22,
    question: 'Which HTML element defines the structure of an HTML document?',
    options: ['<html>', '<head>', '<body>', '<structure>'],
    correctAnswer: '<html>'
  },
  {
    id: 23,
    question: 'What is the correct HTML for inserting a line break?',
    options: ['<br>', '<lb>', '<break>', '<line>'],
    correctAnswer: '<br>'
  },
  {
    id: 24,
    question: 'What is the correct HTML for creating a hyperlink?',
    options: ['<a href="http://example.com">Example</a>', '<a url="http://example.com">Example</a>', '<a link="http://example.com">Example</a>', '<hyperlink>http://example.com</hyperlink>'],
    correctAnswer: '<a href="http://example.com">Example</a>'
  },
  {
    id: 25,
    question: 'What is the correct HTML for inserting a comment?',
    options: ['<!--This is a comment-->', '<!This is a comment!>', '<comment>This is a comment</comment>', '//This is a comment//'],
    correctAnswer: '<!--This is a comment-->'
  },
  {
    id: 26,
    question: 'Which tag is used to create a form in HTML?',
    options: ['<form>', '<input>', '<submit>', '<textarea>'],
    correctAnswer: '<form>'
  },
  {
    id: 27,
    question: 'Which HTML element is used to define a table row?',
    options: ['<row>', '<td>', '<th>', '<tr>'],
    correctAnswer: '<tr>'
  },
  {
    id: 28,
    question: 'Which attribute is used to define the size of an input field?',
    options: ['size', 'length', 'width', 'height'],
    correctAnswer: 'size'
  },
  {
    id: 29,
    question: 'What does the <strong> tag do?',
    options: ['Defines important text', 'Defines bold text', 'Defines italicized text', 'Defines underlined text'],
    correctAnswer: 'Defines important text'
  },
  {
    id: 30,
    question: 'What is the correct HTML for adding a background image?',
    options: ['<body style="background-image: url(image.jpg)">', '<background src="image.jpg">', '<img background="image.jpg">', '<body background="image.jpg">'],
    correctAnswer: '<body style="background-image: url(image.jpg)">'
  },
  {
    id: 31,
    question: 'Which tag is used to create a dropdown list?',
    options: ['<dropdown>', '<select>', '<option>', '<list>'],
    correctAnswer: '<select>'
  },
  {
    id: 32,
    question: 'What tag is used to define a hyperlink?',
    options: ['<hyperlink>', '<link>', '<a>', '<href>'],
    correctAnswer: '<a>'
  },
  {
    id: 33,
    question: 'Which tag is used for inserting a line of text that acts as a divider?',
    options: ['<br>', '<hr>', '<divider>', '<line>'],
    correctAnswer: '<hr>'
  },
  {
    id: 34,
    question: 'What does the <em> tag represent?',
    options: ['Emphasized text', 'Bold text', 'Italicized text', 'Underlined text'],
    correctAnswer: 'Emphasized text'
  },
  {
    id: 35,
    question: 'Which tag is used for defining an inline frame?',
    options: ['<iframe>', '<frame>', '<inline>', '<frame-inline>'],
    correctAnswer: '<iframe>'
  },
  {
    id: 36,
    question: 'Which tag is used to define a section in HTML?',
    options: ['<section>', '<part>', '<article>', '<group>'],
    correctAnswer: '<section>'
  },
  {
    id: 37,
    question: 'Which attribute specifies the width of an image?',
    options: ['width', 'size', 'height', 'length'],
    correctAnswer: 'width'
  },
  {
    id: 38,
    question: 'What tag is used to define a link to an external style sheet?',
    options: ['<link>', '<style>', '<css>', '<script>'],
    correctAnswer: '<link>'
  },
  {
    id: 39,
    question: 'Which element defines a footer for a document?',
    options: ['<footer>', '<end>', '<bottom>', '<footer-section>'],
    correctAnswer: '<footer>'
  },
  {
    id: 40,
    question: 'Which HTML element is used to define a heading?',
    options: ['<heading>', '<h1>', '<title>', '<header>'],
    correctAnswer: '<h1>'
  },
  {
    id: 41,
    question: 'What is the purpose of the <meta> tag in HTML?',
    options: ['Provides metadata about the HTML document', 'Defines the main content', 'Defines the title of the page', 'Links external resources'],
    correctAnswer: 'Provides metadata about the HTML document'
  },
  {
    id: 42,
    question: 'Which tag is used to define a table cell?',
    options: ['<cell>', '<td>', '<tr>', '<th>'],
    correctAnswer: '<td>'
  },
  {
    id: 43,
    question: 'Which HTML element is used to define an ordered list?',
    options: ['<list>', '<ol>', '<ul>', '<order>'],
    correctAnswer: '<ol>'
  },
  {
    id: 44,
    question: 'Which tag is used to define a dropdown option?',
    options: ['<option>', '<list>', '<dropdown>', '<select>'],
    correctAnswer: '<option>'
  },
  {
    id: 45,
    question: 'Which HTML tag is used to display a tooltip when the user hovers over an element?',
    options: ['<tooltip>', '<span>', '<title>', '<hover>'],
    correctAnswer: '<title>'
  },
  {
    id: 46,
    question: 'Which tag is used to define a table header?',
    options: ['<header>', '<th>', '<thead>', '<h1>'],
    correctAnswer: '<th>'
  },
  {
    id: 47,
    question: 'What attribute is used to define the source of an image?',
    options: ['src', 'href', 'alt', 'link'],
    correctAnswer: 'src'
  },
  {
    id: 48,
    question: 'What is the correct HTML for creating a button?',
    options: ['<button>', '<input type="button">', '<button type="submit">', '<submit>'],
    correctAnswer: '<button>'
  },
  {
    id: 49,
    question: 'What is the purpose of the <noscript> tag?',
    options: ['To define scripts', 'To provide alternate content for users without JavaScript', 'To define style', 'To include external resources'],
    correctAnswer: 'To provide alternate content for users without JavaScript'
  },
  {
    id: 50,
    question: 'Which HTML tag is used for defining an area in an image map?',
    options: ['<area>', '<map>', '<image>', '<location>'],
    correctAnswer: '<area>'
  }

];

// import React, { useState, useEffect } from 'react';
const HtmlTest = () => {
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState(new Array(questions.length).fill(''));
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [timeLeft, setTimeLeft] = useState(1500); // 25 minutes in seconds
  const [attemptedQuestions, setAttemptedQuestions] = useState(0);
  const [timerColor, setTimerColor] = useState('#00FF00'); // Initial color green
  const [timerPaused, setTimerPaused] = useState(false); // State to track timer pause/resume


  useEffect(() => {
    // Shuffle the questions array randomly when the component mounts
    const shuffleArray = (array) => {
      return array.sort(() => Math.random() - 0.5);
    };
    setShuffledQuestions(shuffleArray(questions));

    // Scroll to the element with the ID 'css' when the component mounts
    const element = document.getElementById('html');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []); // Empty dependency array ensures this effect runs only once after the component mounts

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!timerPaused) { // Only decrement time if timer is not paused
        setTimeLeft(timeLeft - 1);

        // Update timer color based on remaining time
        if (timeLeft <= 300 && timeLeft > 120) {
          setTimerColor('#FFFF00'); // Yellow
        } else if (timeLeft <= 120) {
          setTimerColor('darkred'); // Red
        }

        // Check if time is up
        if (timeLeft === 0) {
          setShowScore(true);
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, timerPaused]);

  const handleOptionSelect = (option) => {
    // Check if the selected option is different from the previously selected option
    if (selectedOptions[currentQuestion] !== option) {
      const newSelectedOptions = [...selectedOptions];
      newSelectedOptions[currentQuestion] = option;
      setSelectedOptions(newSelectedOptions);

      if (!selectedOptions[currentQuestion]) {
        // Increment attempted questions only if the current question didn't have an attempt before
        setAttemptedQuestions(attemptedQuestions + 1);
      }

      if (option === shuffledQuestions[currentQuestion].correctAnswer) {
        setScore(score + 1);
      }
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestion < shuffledQuestions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowScore(true);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handlePauseResumeTimer = () => {
    setTimerPaused(!timerPaused); // Toggle timer pause/resume state
  };

  return (
    <div className="container mx-auto p-4" id='html'  style={{ backgroundColor: '#F4F4F6', margin: '5rem', borderRadius: '15px' }}>
      {!showScore ? (
        <div className='relative isolate overflow-hidden bg-gray-900' style={{  borderRadius: '10px', color: 'white' }}>
          <h1 className="text-3xl font-bold text-center mb-8" style={{ margin: '1rem' }}>HTML MOCK TEST<br /><span style={{ color: 'darkred', fontSize: '22px' }}>Dream Adoration</span></h1>
          <div style={{ margin: '2rem' }}>
            <h2 className="text-2xl font-medium mb-4">Question {currentQuestion + 1} of {questions.length}</h2>
            <h3 className="text-lg font-semibold mb-2">{questions[currentQuestion].question}</h3>
            {questions[currentQuestion].options.map((option, index) => (
              <div key={index} className="mb-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOptions[currentQuestion] === option}
                    onChange={() => handleOptionSelect(option)}
                    className="form-radio h-5 w-5 text-primary-600"
                  />
                  <span className="ml-2 ">{option}</span>
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-around">
            <button
              onClick={handlePreviousQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-3 rounded mt-5"
              disabled={currentQuestion === 0}
            >
              Previous
            </button>
            <button
              onClick={handleNextQuestion}
              className="bg-primary-500 hover:bg-primary-600 text-black font-bold py-2 px-4 rounded mt-5"
            >
              Next
            </button>
            <button
              onClick={handlePauseResumeTimer}
              className="bg-green-600 hover:bg-green-700 text-black font-bold py-2 px-4 rounded mt-5"
            >
              {timerPaused ? 'Resume' : 'Pause'}
            </button>
          </div>
          <div className=" mt-1 text-center" style={{ color: timerColor, fontWeight: 'bold' }}>Time Left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}</div>
          <div className=" mt-1 text-center" style={{ fontWeight: 'bold', padding: '10px' }}>Attempted Questions: {attemptedQuestions} / {questions.length}</div>
        </div>
      ) : (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Quiz Completed!</h2>
          <h3 className="text-lg font-medium mb-2">Your Score: {score}/{questions.length}</h3>
        </div>
      )}
    </div>
  );
};

export default HtmlTest;
