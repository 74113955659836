import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Component/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Component/CSS/testimonial.css';
import Ebook from './Component/Ebook';
import PrimeTouch from './Component/PrimeTouch';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';
import Course from './Component/Course';
import Tranning from './Component/Tranning';
import Plan from './Component/Plan';
import Appdownload from './Component/Appdownload';
import Mocktest from './Component/Mocktest';
import CssTest from './Component/Test/CssTest';
import JavaScriptTest from './Component/Test/JavaScriptTest';
import PhpTest from './Component/Test/PhpTest';
import ReactTest from './Component/Test/ReactTest';
import HtmlTest from './Component/Test/HtmlTest';
import MysqlTest from './Component/Test/Mysql';
import About from './Component/About';
import Railway from './Component/Goverment/Railway';
import NewsLetter from './Component/NewsLetter';
import PageNotFound from './Component/PageNotFound';
import Company from './Component/Company';
import RailwayMockTest from './Component/Goverment/RailwayMockTest';
import BankPO from './Component/Goverment/Banking/BankPO';
import Bank from './Component/Goverment/Bank';
import BankClerk from './Component/Goverment/Banking/BankClerk';
import BankRRB from './Component/Goverment/Banking/BankRRB';
import BankSO from './Component/Goverment/Banking/BankSO';
import IIT from './Component/Goverment/IIT';
import JeeMain from './Component/Goverment/IIT/JeeMain';
import JeeAdvance from './Component/Goverment/IIT/JeeAdvance';
// import ComingSoon from './Component/ComingSoon';
import HtmlPage from './Component/IT/HtmlPage';
import CssPage from './Component/IT/CssPage';
import JavaScriptPage from './Component/IT/JavaScriptPage';
import PythonPage from './Component/IT/PythonPage';
import ReactJsPage from './Component/IT/ReactJsPage';
import PhpPage from './Component/IT/PhpPage';
import MysqlPage from './Component/IT/MysqlPage';
import BootstrapPage from './Component/IT/BootstrapPage';
import TermsCondition from './Component/TermsCondition';
import MaterailUIPage from './Component/IT/MaterialUIPage';
import Interview from './Component/Interview';
import PHPInterviewQuestion from './Component/InterviewQuestion/PHPInterviewQuestion';
import PythonInterviewQuestion from './Component/InterviewQuestion/PythonInterviewQuestion';
import MYSQLInterviewQuestion from './Component/InterviewQuestion/MYSQLInterviewQuestion';
import ReactJSInterviewQuestion from './Component/InterviewQuestion/ReactJSInterviewQuestion';
import HTMLInterviewQuestion from './Component/InterviewQuestion/HTMLInterviewQuestion';
import CSSInterviewQuestion from './Component/InterviewQuestion/CSSInterviewQuestion';
import BootstrapInterviewQuestion from './Component/InterviewQuestion/BootstrapInterviewQuestion';
import JavaScriptInterviewQuestion from './Component/InterviewQuestion/JavaScriptInterviewQuestion';
import Contact from './Component/Contact';
import Blog from './Component/Blog';
import BlogDetails from './Component/BlogDetails';
import HtmlBook from './Component/BookDetails/HtmlBook';
import CssBook from './Component/BookDetails/CssBook';
import JavaScriptBook from './Component/BookDetails/JavaScriptBook';
import ReactJsBook from './Component/BookDetails/ReactJsBook';
import PythonBook from './Component/BookDetails/PythonBook';
import PhpBook from './Component/BookDetails/PhpBook';
import BootstrapBook from './Component/BookDetails/BootstrapBook';
import MySQlBook from './Component/BookDetails/MySQlBook';

import Railway_Group_B from './Component/Goverment/Railway/Railway_Group_B';
import Railway_Group_C from './Component/Goverment/Railway/Railway_Group_C';
import Railway_Group_D from './Component/Goverment/Railway/Railway_Group_D';

import BpscMockTest from './Component/Goverment/Bpsc/BpscMockTest';
import Bpsc from './Component/Goverment/Bpsc';
import Upsc from './Component/Goverment/Upsc';
import UpscMockTest from './Component/Goverment/Upsc/UpscMockTest';
import SSC from './Component/Goverment/SSC';
import SSC_CGL from './Component/Goverment/SSC/SSC_CGL';
import SSC_CHSL from './Component/Goverment/SSC/SSC_CHSL';
import SSC_JE from './Component/Goverment/SSC/SSC_JE';
import SSC_GD from './Component/Goverment/SSC/SSC_GD';
import SSC_CPO from './Component/Goverment/SSC/SSC_CPO';
import SSC_MTS from './Component/Goverment/SSC/SSC_MTS';
import Class1 from './Component/Course/Class1';
import Math from './Component/Course/Class-1/Math';
import Science from './Component/Course/Class-1/Science';
import Hindi from './Component/Course/Class-1/Hindi';
import Computer from './Component/Course/Class-1/Computer';
import Whatsapp from './Component/Whatsapp';
import Railway_3250_Math_Book_Solution from './Component/Course/Railway/Kundan/Railway_3250_Math_Book_Solution';
import RailwayCourse from './Component/Course/Railway/RailwayCourse';
import ChatBot from './Component/ChatBot/ChatBot';
import PrivacyPolicy from './PrivacyPolicy';
import Editor from './CodeEditor/CodeEditor';
import PythonTest from './Component/Test/PythonTest';
import BootstrapTest from './Component/Test/BootstrapTest';
import TelwindCssTest from './Component/Test/TelwindCssTest';
import Review from './Review';
import JavaTest from './Component/Test/JavaTest';
import NodeTest from './Component/Test/NodeTest';
import MongoDbTest from './Component/Test/MongoDbTest';
import ActionProvider from './Component/ChatBot/ActionProvider';
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "./Component/ChatBot/config";
import MessageParser from "./Component/ChatBot/MessageParser";
import WeatherApp from './Component/Service/WeatherApp';
import AgeCalculator from './Component/Service/AgeCalculator';
import StateCityList from './Component/Service/StateCityList';
import EmailValidator from './Component/Service/EmailValidator';




// import CourseMeta from './Component/Meta/CourseMeta';


// import TopPopup from './Component/TopPopup';
function App() {
  return (
    <div>

      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ebook" element={<Ebook />} />
          <Route path="/service" element={<PrimeTouch />} />
          <Route path="/interview" element={<Interview />} />
          <Route path="/course" element={<Course />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/training" element={<Tranning />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path='/plan' element={<Plan />} />
          <Route path='/company' element={<Company />} />
          <Route path='/about' element={<About />} />
          <Route path='/privacy&policy' element={<PrivacyPolicy />} />
          <Route path='/terms-and-condition' element={<TermsCondition />} />

          {/* IT MOCK TEST START */}
          <Route path='/mocktest' element={<Mocktest />} />
          <Route path='/css-mock-test' element={<CssTest />} />
          <Route path='/javaScript-mock-test' element={<JavaScriptTest />} />
          <Route path='/php-mock-test' element={<PhpTest />} />
          <Route path='/react-mock-test' element={<ReactTest />} />
          <Route path='/html-mock-test' element={<HtmlTest />} />
          <Route path='/mysql-mock-test' element={<MysqlTest />} />
          <Route path='/python-mock-test' element={<PythonTest />} />
          <Route path='/bootstrap-mock-test' element={<BootstrapTest />} />
          <Route path='/telwind-css-mock-test' element={<TelwindCssTest />} />
          <Route path='/java-mock-test' element={<JavaTest />} />
          <Route path='/node-mock-test' element={<NodeTest />} />
          <Route path='/mongodb-mock-test' element={<MongoDbTest />} />
          {/* IT MOCK TEST END */}

          <Route path='/html-learning' element={<HtmlPage />} />
          <Route path='/css-learning' element={<CssPage />} />
          <Route path='/javascript-learning' element={<JavaScriptPage />} />
          <Route path='/php-learning' element={<PhpPage />} />
          <Route path='/react-js-learning' element={<ReactJsPage />} />
          <Route path='/python-learning' element={<PythonPage />} />
          <Route path='/mysql-learning' element={<MysqlPage />} />
          <Route path='/bootstrap-learning' element={<BootstrapPage />} />
          <Route path='/materialui-learning' element={<MaterailUIPage />} />

          {/* INTERVIEW QUESTION START */}
          <Route path='/php-interview-question' element={<PHPInterviewQuestion />} />
          <Route path='/python-interview-question' element={<PythonInterviewQuestion />} />
          <Route path='/html-interview-question' element={<HTMLInterviewQuestion />} />
          <Route path='/css-interview-question' element={<CSSInterviewQuestion />} />
          <Route path='/mysql-interview-question' element={<MYSQLInterviewQuestion />} />
          <Route path='/reactjs-interview-question' element={<ReactJSInterviewQuestion />} />
          <Route path='/javascript-interview-question' element={<JavaScriptInterviewQuestion />} />
          <Route path='/bootstrap-interview-question' element={<BootstrapInterviewQuestion />} />
          {/* INTERVIEW QUESTION END */}

          {/* GOVERMENT MOCK TEST START  */}

          {/* Banking Exam START*/}
          <Route path='/banking' element={<Bank />} />
          <Route path='/banking-po' element={<BankPO />} />
          <Route path='/banking-clerk' element={<BankClerk />} />
          <Route path='/banking-rrb' element={<BankRRB />} />
          <Route path='/banking-so' element={<BankSO />} />
          {/* Banking Exam END */}

          {/* Jee Main Exam Start */}
          <Route path='/iit' element={<IIT />} />
          <Route path='/jee-main' element={<JeeMain />} />
          <Route path='/jee-advance' element={<JeeAdvance />} />
          {/* Jee Main Exam End */}

          {/* Railway exam START */}
          <Route path='/railway' element={<Railway />} />
          <Route path='group-A' element={<RailwayMockTest />} />
          <Route path='group-B' element={<Railway_Group_B />} />
          <Route path='group-C' element={<Railway_Group_C />} />
          <Route path='group-D' element={<Railway_Group_D />} />
          {/* Railway exam END */}

          {/* BPSC MOCK TEST START */}
          <Route path='bpsc' element={<Bpsc />} />
          <Route path='bpsc-mock-test' element={<BpscMockTest />} />
          {/* BPSC MOCK TEST END */}

          {/* UPSC MOCK TEST START */}
          <Route path='upsc' element={<Upsc />} />
          <Route path='upsc-mock-test' element={<UpscMockTest />} />
          {/* UPSC MOCK TEST END */}

          {/* UPSC MOCK TEST START */}
          <Route path='ssc' element={<SSC />} />
          <Route path='ssc-cgl' element={<SSC_CGL />} />
          <Route path='ssc-chsl' element={<SSC_CHSL />} />
          <Route path='ssc-je' element={<SSC_JE />} />
          <Route path='ssc-gd' element={<SSC_GD />} />
          <Route path='ssc-cpo' element={<SSC_CPO />} />
          <Route path='ssc-mts' element={<SSC_MTS />} />
          {/* UPSC MOCK TEST END */}

          {/* GOVERMENT MOCK TEST END  */}

          {/* E-BOOK PAGE START */}
          <Route path='/html-book' element={<HtmlBook />} />
          <Route path='/css-book' element={<CssBook />} />
          <Route path='/javascript-book' element={<JavaScriptBook />} />
          <Route path='/reactjs-book' element={<ReactJsBook />} />
          <Route path='/python-book' element={<PythonBook />} />
          <Route path='/php-book' element={<PhpBook />} />
          <Route path='/bootstrap-book' element={<BootstrapBook />} />
          <Route path='/mysql-book' element={<MySQlBook />} />

          {/* E-BOOK PAGE END */}
          <Route path='/class-1' element={<Class1 />} />
          <Route path='/math' element={<Math />} />
          <Route path='/science' element={<Science />} />
          <Route path='/hindi' element={<Hindi />} />
          <Route path='/computer' element={<Computer />} />


          {/* Railway Course Start */}
          <Route path='/railway-course' element={<RailwayCourse />} />
          {/* Railway Course End */}

          {/* Editor */}
          <Route path='/editor' element={<Editor />} />
          {/* Editor */}
          <Route path='/review' element={<Review />} />
          {/* COURSE START  */}

          {/* COURSE END */}

          {/* META TAGS START */}
          {/* <Route path='/course' element={<CourseMeta />} /> */}
          {/* META TAGS END */}

          <Route path='*' element={<PageNotFound />} />
          {/* <Route path='*' element={<ComingSoon />} /> */}

 {/* Service Application Start */}
 <Route path='/weather' element={<WeatherApp />} />
 <Route path='/age-calculator' element={<AgeCalculator />} />
 <Route path='/state-city' element={<StateCityList />} />
 <Route path='/email-validator' element={<EmailValidator />} />

{/* Service Application End */}

        </Routes>
      </BrowserRouter>
      <NewsLetter />

      <Appdownload />
      <Whatsapp />
      <ChatBot />
      {/* <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      /> */}



      <Footer />
    </div>
  );
}

export default App;
