import React from "react";
import htmlimg from "../Component/Image/Icon/html-5.png"
import cssimg from "../Component/Image/Icon/css.png"
import jsimg from "../Component/Image/Icon/js.png"
import javaimg from "../Component/Image/Icon/java.png"
import phpimg from "../Component/Image/Icon/php.png"
import bootstrapimg from "../Component/Image/Icon/bootstrap.png"
import pythonimg from "../Component/Image/Icon/python.png"
import mysqlimg from "../Component/Image/Icon/mysql.png"
import reactjsimg from "../Component/Image/Icon/reactjs.png"
import telwindimg from "../Component/Image/Icon/telwind.png"
import mongoimg from "../Component/Image/Icon/mongodb.png"
import nodeimg from "../Component/Image/Icon/node.png"
import { Link } from "react-router-dom";
import MockTestMeta from "./FAQ/MockTestMeta";


function Mocktest() {
  return (
    <div>
      <MockTestMeta />

      <div
        class="px-3 py-20 bg-opacity-10"
      >
        <div
          class="grid grid-cols-1 md:lg:xl:grid-cols-3 group bg-white shadow-xl shadow-neutral-100 border"

        >
          <Link to="/html-mock-test">
            <div
              class="p-10 flex flex-col items-center text-center group md:lg:xl:border-r hover:bg-slate-50 cursor-pointer"
              style={{ borderRadius: "1rem" }}
            >
              <span>
              <img style={{width:'10rem'}} src={htmlimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                HTML Mock Test
              </p>
              <p class="text-m text-slate-700">
                Experience the transformative power of HTML with Dream
                Adoration's test. Wish the students success as they embark on
                their HTML journey. Gain valuable insights and direction for
                advancing your skills in HTML development through this test.
              </p>
            </div>
          </Link>

          <Link to="/css-mock-test">
            <div class="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
            <span>
              <img style={{width:'10rem'}} src={cssimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                Css Mock Test
              </p>
              <p class="text-m text-slate-700">
                Start your CSS journey with Dream Adoration's test, uncovering
                insights and guidance for success. Discover your standing and
                advance your skills in CSS development. Take the test to pave
                the way for growth and proficiency.
              </p>
            </div>
          </Link>

          <Link to="/javascript-mock-test">
            <div class="p-10 flex flex-col items-center text-center group   md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
            <span>
              <img style={{width:'10rem'}} src={jsimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                Javascript Mock Test
              </p>
               <p class="text-m text-slate-700">
                Elevate your JavaScript proficiency with Dream Adoration's
                insightful test. Gauge your skills, receive tailored guidance,
                and accelerate your journey to mastery. Take the test to unlock
                new possibilities and excel in JavaScript development.
              </p>
            </div>
          </Link>

          <Link to="/java-mock-test#java">
            <div class="p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
            <span>
              <img style={{width:'10rem'}} src={javaimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                Java Mock Test
              </p>
               <p class="text-m text-slate-700">
              Unleash your Java potential with Dream Adoration's tailored test! Evaluate your knowledge, enhance your skills, and master the art of efficient programming. Take the test now to develop robust and scalable applications with ease.
              </p>
            </div>
          </Link>

          <Link to="/node-mock-test">
            <div class="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
            <span>
              <img style={{width:'10rem'}} src={nodeimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                Node Js Mock Test
              </p>
               <p class="text-m text-slate-700">
               Unlock your Node.js potential with Dream Adoration's test, gaining insights and guidance for success. Assess your skills, chart your course, and excel in Node.js development. Take the test to propel your journey towards expertise and achievement.
              </p>
            </div>
          </Link>

          <Link to="/python-mock-test">
            <div class="p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
            <span>
              <img style={{width:'10rem'}} src={pythonimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                Python Mock Test
              </p>
               <p class="text-m text-slate-700">
                Master Python with Dream Adoration's expert-designed test!
                Assess your knowledge, identify areas to improve, and gain the
                confidence to excel in Python programming. Take the test now and
                step up your coding journey toward success.
              </p>
            </div>
          </Link>

          <Link to="/react-mock-test">
            <div class="p-10 flex flex-col items-center text-center group   md:lg:xl:border-r hover:bg-slate-50 cursor-pointer">
            <span>
              <img style={{width:'10rem'}} src={reactjsimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                React Js Mock Test
              </p>
               <p class="text-m text-slate-700">
                Master React.js with Dream Adoration's comprehensive test.
                Assess your skills, receive personalized recommendations, and
                advance your expertise in React development. Take the test to
                propel your career forward and unleash your full potential in
                React.js.
              </p>
            </div>
          </Link>

          <Link to="/php-mock-test">
            <div class="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
            <span>
              <img style={{width:'10rem'}} src={phpimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                PHP Mock Test
              </p>
               <p class="text-m text-slate-700">
                Unlock your PHP potential with Dream Adoration's test, gaining
                insights and guidance for success. Assess your skills, chart
                your course, and excel in PHP development. Take the test to
                propel your journey towards expertise and achievement.
              </p>
            </div>
          </Link>

          <Link to="/mongodb-mock-test">
            <div class="p-10 flex flex-col items-center text-center group md:lg:xl:border-r md:lg:xl:border-b hover:bg-slate-50 cursor-pointer">
            <span>
              <img style={{width:'10rem'}} src={mongoimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                Mongo DB Mock Test
              </p>
               <p class="text-m text-slate-700">
               Unlock your MongoDB potential with Dream Adoration's test, gaining insights and guidance for success. Assess your skills, chart your course, and excel in MongoDB development. Take the test to propel your journey towards expertise and achievement.
              </p>
            </div>
          </Link>

          <Link to="/mysql-mock-test">
            <div class="p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
            <span>
              <img style={{width:'10rem'}} src={mysqlimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                MySQL Mock Test
              </p>
               <p class="text-m text-slate-700">
                Unlock your MySQL potential with Dream Adoration's insightful
                test. Evaluate your knowledge, uncover areas for improvement,
                and chart a path to success in MySQL. Take the test to elevate
                your database skills and excel in MySQL development.
              </p>
            </div>
          </Link>



          <Link to="/bootstrap-mock-test">
            <div class="p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
            <span>
              <img style={{width:'10rem'}} src={bootstrapimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                Bootstrap Mock Test
              </p>
               <p class="text-m text-slate-700">
                Elevate your Bootstrap skills with Dream Adoration's exclusive
                test! Assess your expertise, discover improvement opportunities,
                and unlock your potential in responsive web design. Take the
                test today and build stunning, professional websites with ease.
              </p>
            </div>
          </Link>

          <Link to="/telwind-css-mock-test#telwind">
            <div class="p-10 flex flex-col items-center text-center group     hover:bg-slate-50 cursor-pointer">
            <span>
              <img style={{width:'10rem'}} src={telwindimg}/>
              </span>
              <p class="text-xl font-medium text-slate-700 mt-3">
                Telwind Css Mock Test
              </p>
               <p class="text-m text-slate-700">
                Unleash your Tailwind CSS potential with Dream Adoration's
                tailored test! Evaluate your knowledge, refine your skills, and
                master the art of utility-first web design. Take the test now to
                create modern, responsive designs with ease.
              </p>
            </div>
          </Link>
        </div>

        <div
          class="w-full   bg-indigo-600 shadow-xl shadow-indigo-200 py-9 px-9 flex justify-between items-center"
          style={{
            borderBottomRightRadius: "1rem",
            borderBottomLeftRadius: "1rem",
          }}
        >
          <p class=" text-white">
            {" "}
            <span class="text-xl font-medium">
              Still Confused ?
            </span> <br />{" "}
            <span class="text-sm">
              Book For Free Career Consultation Today !{" "}
            </span>
          </p>
          <button
            class="px-1.5 py-2  font-medium text-slate-700 shadow-xl  hover:bg-white duration-150  bg-yellow-400"
            style={{ marginLeft: "1rem", borderRadius: "0.5rem" }}
            onClick={() => {
              window.open(
                "https://api.whatsapp.com/send/?phone=9097951772&text&type=phone_number&app_absent=0",
                "_blank"
              );
            }}
          >
            BOOK AN APPOINTMENT{" "}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Mocktest;
