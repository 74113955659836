import React, { useState } from "react";
import axios from "axios";
import weathervideo from "../Image/Video/weather_video.mp4";
import { Widgets } from "@mui/icons-material";

const WeatherApp = () => {
  const [location, setLocation] = useState("");
  const [weatherData, setWeatherData] = useState(null);
  const [error, setError] = useState(null);

  const fetchWeather = async () => {
    if (!location) {
      setError("Please enter a location");
      return;
    }
    setError(null);

    try {
      const apiKey = "4ohh8kmgPBaj8Aodco6IQy6Gs0I6IDq7";
      const response = await axios.get(
        `https://api.tomorrow.io/v4/weather/realtime`,
        {
          params: {
            location,
            apikey: apiKey,
            units: "metric",
          },
        }
      );
      setWeatherData(response.data);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch weather data. Please check the location or try again.");
    }
  };

  return (
    <div className="bg-white mt-12">
      <div className="mx-auto max-w-7xl sm:px-6 sm:py-12">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle cx="512" cy="512" r="512" fill="url(#gradient)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="gradient">
                <stop stopColor="#7775D6" />
                <stop offset="1" stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-left lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-semibold tracking-tight text-white sm:text-4xl">
              Get Real-Time Weather Updates
            </h2>
            <p className="mt-6 text-lg text-gray-300">
              Enter your location to receive the latest weather information, including temperature, humidity, and wind speed.
            </p>
            <div className="mt-10 mb-10 flex items-left gap-x-6 lg:justify-start">
              <input
                type="text"
                placeholder="Enter location"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="border text-white-600 text-sm rounded-lg block w-full p-2.5 dark:bg-gray-700"
              />
              <button
                onClick={fetchWeather}
                className="rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white" style={{width:'10rem'}}
              >
                Get Weather
              </button>
            </div>
            {error && <p className="text-red-500 mt-4">{error}</p>}
          </div>
          <div className="relative h-80 hidden lg:block" style={{ marginTop: "8rem" }}>
            <video
              src={weathervideo}
              autoPlay
              loop
              muted
              className="w-full h-full object-cover"
              style={{ borderRadius: "1rem" }}
            />
          </div>
        </div>
      </div>

      {weatherData && !error && (
        <div className="bg-white">
          <div className="mx-auto max-w-7xl">
            <div className="relative isolate overflow-hidden bg-gray-900 px-2  shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
              <svg
                viewBox="0 0 1024 1024"
                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                aria-hidden="true"
              >
                <circle cx="512" cy="512" r="512" fill="url(#gradient)" fillOpacity="0.7" />
                <defs>
                  <radialGradient id="gradient">
                    <stop stopColor="#7775D6" />
                    <stop offset="1" stopColor="#E935C1" />
                  </radialGradient>
                </defs>
              </svg>
              <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-12 lg:text-left">
                <p className="text-lg text-gray-300">
                  Weather in : {""}
                  <span className="bg-aliceblue text-darkgreen rounded-md px-2 font-bold">
                    {location}
                  </span>
                </p>
                <p className="text-lg text-gray-300">
                  Temperature : {""}
                  <span className="bg-aliceblue text-darkgreen rounded-md px-2 font-bold">
                    {weatherData?.data?.values?.temperature}°C
                  </span>
                </p>
                <p className="text-lg text-gray-300">
                  Humidity : {""}
                  <span className="bg-aliceblue text-darkgreen rounded-md px-2 font-bold">
                    {weatherData?.data?.values?.humidity}%
                  </span>
                </p>
                <p className="text-lg text-gray-300">
                  Wind Speed : {""}
                  <span className="bg-aliceblue text-darkgreen rounded-md px-2 font-bold">
                    {weatherData?.data?.values?.windSpeed} km/h
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WeatherApp;
