import React from "react";
import "./CSS/footer.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import twitterimg from "../Component/Image/Icon/x-icon.png"


function Footer() {
  // Function to get the current year
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (

   <footer>
   <div className="container footer__container">
     <div className="footer__top">
       <a href="#" className="logo">
       </a>
     </div>
     <div className="footer__main">
       <article>
         <p >Company</p>
         <ul className="footer__links" style={{ listStyle: "none"}}>
           <li><a href="/company">Company</a></li>
           <li><a href="/about">About Us</a></li>
           <li><a href="/contact">Contact Us</a></li>
         </ul>
       </article>
       <article>
         <p >Service</p>
         <ul className="footer__links">
           <li><a href="/service">Website Developement</a></li>
           <li><a href="/service">Mobile App Development</a></li>
           <li><a href="/service">Logo Design</a></li>
           <li><a href="/service">SEO</a></li>
           <li><a href="/service">Content Writting</a></li>
           <li><a href="/service">Video Editing</a></li>
           <li><a href="/service">Image Editing</a></li>
         </ul>
       </article>
       <article>
         <p >Resources</p>
         <ul className="footer__links">
           <li><a href="/blog">Blog</a></li>
           <li><a href="/ebook">Ebooks</a></li>
           <li><a href="/review">Google Review</a></li>
         </ul>
       </article>
       <article>
         <p >Support</p>
         <ul className="footer__links">
           <li>+91 9097951772</li>
           <li>support@dreamadoration.com</li>
           <li>contact@dreamadoration.com</li>
         </ul>
       </article>
       <article>
         <div className="footer__socials">
           <p >Stay Connected</p>
           <ul>
             <li style={{backgroundColor:'blue', fontSize:'2rem'}}><a href="https://www.facebook.com/share/YZ6rt9XW2QGCAjzg/"><i className="bx bxl-facebook"></i></a></li>
             {/* <li><a href="https://x.com/dream_adoration"><i className="bx bxl-twitter"></i></a></li> */}
             <li style={{backgroundColor:'black'}}><a href="https://x.com/dream_adoration" aria-label="twitter"><img src={twitterimg} alt="X Icon" width="12px" height="auto" /></a></li>
             <li style={{backgroundColor:'red'}}><a href="https://www.youtube.com/@AmanKumar-kf3bl"><i className="bx bxl-youtube"></i></a></li>
             <li style={{backgroundColor:'#0077B5'}}><a href="https://www.linkedin.com/in/dream-adoration-60606930b"><i className="bx bxl-linkedin"></i></a></li>
             <li style={{ backgroundColor: 'darkred'}}><a href="https://www.instagram.com/dream_adoration/"><i className="bx bxl-instagram"></i></a></li>

             <li style={{backgroundColor:'#E60023'}}><a href="https://in.pinterest.com/dream_adoration/"><i className="bx bxl-pinterest"></i></a></li>

           </ul>
         </div>

       </article>
     </div>
     <div className="footer__bottom">
       <div className="footer__bottom-links">
         <a href="/privacy&policy" style={{marginLeft:'5px'}}>Privacy Policy</a>
         <a href="/terms-and-condition">Terms of Use</a>
       </div>
       <p>Copyright © {getCurrentYear()} <a href="/">Dream Adoration</a> All rights reserved.</p>
     </div>
   </div>
 </footer>

  );
}

export default Footer;
