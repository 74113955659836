import React, { useState, useEffect } from "react";
import Preloader from "./Component/Preloader";
import $ from "jquery";
import Toast from "./Component/Toast";

export default function ServiceModal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyname: "",
    number: "",
    interestedservice: "",
    uniqueKey: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [toast, setToast] = useState({ show: false, message: "", type: "" });

  useEffect(() => {
    fetchUniqueKey();
  }, []);

  const fetchUniqueKey = async () => {
    try {
      const response = await fetch("http://example.com/getUniqueKey");
      const data = await response.json();
      setFormData((prevData) => ({
        ...prevData,
        uniqueKey: data.key,
      }));
    } catch (error) {
      console.error("Error fetching unique key:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (value.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const errors = {};

    // Validate each field
    if (typeof formData.name !== 'string' || !formData.name.trim() || !isNaN(formData.name)) {
      errors.name = "This field is required and must not be a number";
    }



    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email.trim() || !emailPattern.test(formData.email)) {
      errors.email = "Enter a valid email address";
    }

    const phonePattern = /^[0-9]{10}$/;
    if (!formData.number.trim() || !phonePattern.test(formData.number)) {
      errors.number = "Enter a valid 10-digit phone number";
    }

    if (!formData.companyname.trim()) {
      errors.companyname = "This field is required";
    }

    if (!formData.interestedservice.trim()) {
      errors.interestedservice = "This field is required";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors); // Show errors if validation fails
      return; // Prevent AJAX call if form is invalid
    }

    // Only if there are no errors, proceed with the AJAX call
    setLoading(true);
    $.ajax({
      url: "/webservices/modal-form-data.php",
      type: "POST",
      data: {
        name: formData.name,
        email: formData.email,
        number: formData.number,
        companyname: formData.companyname,
        interestedservice: formData.interestedservice,
        action: "intrested_product",
      },
      success: function (response) {
        setLoading(false);
        setToast({ show: true, message: response, type: "success" });
        setTimeout(() => {
          setToast({ show: false, message: "", type: "" });
          window.location.reload();
        }, 3000);
      },
      error: function (xhr, status, error) {
        setLoading(false);
        setToast({
          show: true,
          message: "Please try again after some time !",
          type: "error",
        });
      },
    });
  };

  if (!isOpen) return null;

  return (
    <div className="fadeOut">
      <div>
        {loading ? (
          <Preloader />
        ) : (
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto mt-12 mx-auto max-w-3xl lg:w-[27rem]">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-4 border-b border-solid border-blueGray-200 rounded-t">
                  <h1 className="text-2xl font-semibold">Tell us the service you need</h1>
                  {/* <h2 className="text-3xl font-semibold">Service You Need</h2> */}
                </div>
                {/*body*/}
                <form
                  className="space-y-4 md:space-y-6 text-left"
                  style={{ margin: "1rem" }}
                  onSubmit={handleSubmit}
                >
                  <div>
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {/* Error message for name */}
                    <span style={{ color: "red" }}>{errors.name}</span>
                  </div>

                  <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {/* Error message for email */}
                    <span style={{ color: "red" }}>{errors.email}</span>
                  </div>

                  <div>
                    <label htmlFor="number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Mobile Number
                    </label>
                    <input
                      type="number"
                      name="number"
                      id="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Mobile Number"
                      value={formData.number}
                      onChange={handleChange}
                    />
                    {/* Error message for number */}
                    <span style={{ color: "red" }}>{errors.number}</span>
                  </div>

                  <div>
                    <label htmlFor="companyname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="companyname"
                      id="companyname"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Company Name"
                      value={formData.companyname}
                      onChange={handleChange}
                    />
                    {/* Error message for company name */}
                    <span style={{ color: "red" }}>{errors.companyname}</span>
                  </div>

                  <div>
                    <label htmlFor="interestedservice" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Interested Service
                    </label>
                    <select
                      name="interestedservice"
                      id="interestedservice"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      value={formData.interestedservice}
                      onChange={handleChange}
                    >
                      <option value="" disabled>Select Service</option>
                      <option value="Web Development">Web Development</option>
                      <option value="Mobile Development">Mobile Development</option>
                      <option value="Logo Design">Logo Design</option>
                      <option value="SEO">SEO</option>
                      <option value="Content Writing">Content Writing</option>
                      <option value="Video Editing">Video Editing</option>
                      <option value="Image Editing">Image Editing</option>
                    </select>
                    {/* Error message for service selection */}
                    <span style={{ color: "red" }}>{errors.interestedservice}</span>
                  </div>

                  <div className="flex items-center justify-end p-1 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      onClick={onClose}
                    >
                      Close
                    </button>
                    <input type="hidden" name="uniqueKey" value={formData.uniqueKey} />
                    <button
                      className="bg-emerald-500 text-white font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {toast.show && <Toast message={toast.message} type={toast.type} />}
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </div>
    </div>
  );
}
