import React, { useState } from "react";
import "boxicons/css/boxicons.min.css";

function ChatModal({ closeModal }) {
  const [messages, setMessages] = useState([
    {
      type: "bot",
      text: "Hey.. welcome to Dream Adoration Internship Program. Select a question below to get started!",
    },
  ]);
  const [questions, setQuestions] = useState([
    {
      "id": 9,
      "question": "Are internships available for specific courses like Web Development, Full Stack Development, Python, and more?",
      "answer": "Yes, Dream Adoration offers internships for various courses such as Web Development (HTML, CSS, JavaScript), Full Stack Development (React, Angular, Node.js, Express.js), Python Programming (Basics, Data Structures, Flask), Java Development (Core Java, Advanced Java, Spring Framework), Data Science (Python, SQL, Machine Learning Basics), Cloud Computing (AWS, Microsoft Azure, Google Cloud), Cybersecurity (Ethical Hacking, Cryptography, Kali Linux), and AI & ML (TensorFlow, PyTorch, Scikit-learn)."
    },
    {
      "id": 10,
      "question": "Does Dream Adoration provide additional services apart from internships?",
      "answer": "Yes, Dream Adoration provides additional services such as Website Development, Mobile App Development, Logo Design, Search Engine Optimization, Content Writing, and Video Editing. You can contact us for professional and high-quality services tailored to your needs."
    },
    {
      id: 1,
      question: "Is an internship available at Dream Adoration?",
      answer: "Yes, internships are available at Dream Adoration. Students from BCA, MCA, BBA, MBA, and CSE can apply for internships here.",
    },
    {
      id: 2,
      question: "What is the duration of the internship?",
      answer: "The internship duration is divided into three categories: 1 month, 2 months, and 3 months.",
    },
    {
      id: 3,
      question: "How can one apply for an internship?",
      answer: "To apply for an internship, you can fill out the registration form on the website and submit it. You will be contacted within 2 days.",
    },
    {
      id: 4,
      question: "Is certification provided for a specific language?",
      answer: "Yes, certification is provided for a specific language. If someone has experience and wants to improve their skills in one language, they can get certified here.",
    },
    {
      id: 5,
      question: "What should one do in case of a problem?",
      answer: "If there is any problem, you can contact us via WhatsApp at 9097951772, email at contact@dreamadoration.com, or ask your query through the Contact Us page.",
    },
    {
      id: 6,
      question: "Is DCA Tally available here?",
      answer: "Yes, DCA Tally is available here.",
    },
    {
      id: 7,
      question: "What types of internships does Dream Adoration provide?",
      answer: "Dream Adoration provides internships in multiple fields such as BCA, MCA, BBA, MBA, DCA Tally, Cloud Computing, Ethical Hacking, Digital Marketing, and more.",
    },
    {
      id: 8,
      question: "What should one do if they face problems while applying?",
      answer: "If you face any problems while applying, you can click on the WhatsApp icon to chat, email us at contact@dreamadoration.com, or ask your questions through the Contact Us page.",
    },
  ]);


  const handleQuestionClick = (questionId) => {
    const selectedQuestion = questions.find((q) => q.id === questionId);
    if (selectedQuestion) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: "user", text: selectedQuestion.question },
        { type: "bot", text: selectedQuestion.answer },
      ]);
      setQuestions((prevQuestions) => prevQuestions.filter((q) => q.id !== questionId));
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-[10000]">
      <div className="bg-white rounded-lg p-6 relative shadow-lg max-w-2xl w-full">
        <button
          onClick={closeModal}
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
        >
          <i className="bx bx-x text-2xl"></i>
        </button>
        <h2 className="text-2xl font-bold mb-4">
          Chat with <span style={{ color: "red" }}>Zenith AI</span>
        </h2>
        <div className="flex flex-col space-y-4 overflow-y-auto h-80">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`p-2 rounded-lg ${
                message.type === "bot"
                  ? "bg-gray-200 text-gray-700"
                  : "bg-blue-500 text-white self-end"
              }`}
              style={{
                maxWidth: "100%",
                height: "auto",
                alignSelf: message.type === "bot" ? "flex-start" : "flex-end",
              }}
            >
              <p style={{ wordWrap: "break-word" }}>{message.text}</p>
            </div>
          ))}
        </div>
        <div className="mt-4">
          {questions.length > 0 ? (
            <div className="grid gap-2">
              {questions.slice(0, 3).map((q) => (
                <button
                  key={q.id}
                  onClick={() => handleQuestionClick(q.id)}
                  className="text-center border rounded-lg p-2 hover:bg-gray-100"
                >
                  {q.question}
                </button>
              ))}
            </div>
          ) : (
            <p className="text-gray-600">For more questions connect on whatsaap.<br/> Thank you for chatting!</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatModal;
