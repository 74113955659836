import React, { useState } from "react";
import Modal from "./Component/Modal";
import image from "./Component/Image/game.png";

function GameChange() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="flex justify-center items-center text-center bg-gray-100 rounded-lg">
      <div
        className="max-w-8xl p-6 border border-gray-900 rounded-lg shadow bg-gray-600 m-2"
        style={{
          backgroundColor:"white",
          backgroundPosition: "center",
          overflow: "hidden",
          zIndex: 1,
          // filter: "blur(1px)",
        }}
      >
        <p className="mb-2 font-semibold text-black dark:text-black">
          Make your dreams come true now. The right time to experience it is by
          interning. At Dream Adoration, you can give wings to your dreams and
          prepare for future successes. Every learning and growth opportunity
          can be found here. Chase your dreams.
        </p>

        <div className="mb-6">
          <button
            type="button"
            onClick={openModal}
            className="inline-flex items-center px-3 py-2 text-md font-medium text-center text-white rounded-lg " style={{backgroundColor:'#0d1232'}}
          >
            Try For Free
          </button>
        </div>

        {/* Render Modal conditionally */}
        {isModalOpen && <Modal isOpen={isModalOpen} onClose={closeModal} />}
      </div>
    </div>
  );
}

export default GameChange;
