import React from "react";
import { Helmet } from "react-helmet";
function ReviewMeta() {
  return (
    <div>
      <Helmet>
        <title>Dream Adoration: Genuine Customer Reviews</title>
        <meta
          name="description"
          content="Discover genuine customer reviews about Dream Adoration's exceptional services. Read experiences, testimonials, and feedback from satisfied customers who trust us for quality and reliability."
        />
        <meta
          name="keywords"
          content="Dream Adoration reviews, customer feedback, testimonials, trusted services, customer satisfaction, Dream Adoration experience, genuine reviews"
        />
        <meta name="author" content="Dream Adoration" />
        <link rel="canonical" href="https://www.dreamadoration.com/reviews" />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Dream Adoration: Genuine Customer Reviews" />
        <meta
          property="og:description"
          content="Discover genuine customer reviews about Dream Adoration's exceptional services. Read experiences, testimonials, and feedback from satisfied customers who trust us for quality and reliability."
        />
        <meta
          property="og:image"
          content="https://dreamadoration.com/static/media/DreamadorationReviewImage.png"
        />
        <meta
          property="og:image:secure_url"
          content="https://dreamadoration.com/static/media/DreamadorationReviewImage.png"
        />
        <meta property="og:image:alt" content="Customer Reviews for Dream Adoration" />
        <meta property="og:url" content="https://dreamadoration.com/reviews" />
        <meta property="og:site_name" content="Dream Adoration" />
        <meta
          property="article:publisher"
          content="https://www.facebook.com/profile.php?id=61560307265465"
        />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Dream Adoration: Genuine Customer Reviews" />
        <meta
          name="twitter:description"
          content="Discover genuine customer reviews about Dream Adoration's exceptional services. Read experiences, testimonials, and feedback from satisfied customers who trust us for quality and reliability."
        />
        <meta
          name="twitter:image"
          content="https://dreamadoration.com/static/media/DreamAdorationTwitterImage.png"
        />
        <meta name="twitter:site" content="@dreamadoration" />
        <meta name="twitter:creator" content="@dreamadoration" />
      </Helmet>
    </div>
  );
}

export default ReviewMeta;
