import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CSS/testimonial.css";

const data = [
  {
    name: "Abhipsa Binakar",
    img: "Image/testimonials/Abhipsa.JPG",
    alt: "image1",
    review:
      "Dream Adoration's students sing the praises of its transformative impact, specially crediting it with personalized learning and the supportive community around it. Dedicated mentorship and personalized tools ensure that students succeed academically, and beyond academic success, this school fosters a culture of achievement and growth. These testimonials attest to Dream Adoration's unmatched commitment to the success and holistic development of their students.",
  },
  {
    name: "Susri Subhrakali",
    img: "Image/testimonials/Susri.jpeg",
    alt: "image1",
    review:
      "Dream Adoration is a game-changer! Its innovative platform and engaging curriculum have revolutionized my learning experience. A personalized approach combined with a supportive community has enabled me to rocket toward my educational goals, making learning enjoyable and rewarding. Dream Adoration's commitment to excellence and student success truly sets it apart.",
  },
  {
    name: "Rutuparna Muduli",
    img: "Image/testimonials/Rutuparna.jpeg",
    alt: "image1",
    review:
      "This has been a really great journey with Dream Adoration. I really liked the way I could learn something through interactive lessons and get guidance from experts for every little step taken. I found the usable interface of the system and that it had its unique set of learning resources, which made it quite enjoyable and quite effective in learning.",
  },
  {
    name: "Dibyajyoti Mohapatra",
    img: "Image/testimonials/Dibyajyoti.jpeg",
    alt: "image1",
    review:
      "Dream Adoration has been so revolutionizing for me. The interactive lessons and the real projects have also helped boost up my confidence in coding. The supportive community and professional mentors in the platform have also been super helpful to me throughout my journey. Dream Adoration helped not only master new skills, but it also deepened an interest in programming.",
  },
  {
    name: "Raja Palei",
    img: "Image/testimonials/Raja.jpeg",
    alt: "image1",
    review:
      "Dream Adoration has changed the way I learn in tremendous ways. The customized lessons and full curriculum have made me understand many subjects a lot more clearly. The interface is also really simple to operate, and interactive aspects make learning really fun. I am glad for the opportunity to grow with Dream Adoration.",
  },
  {
      name: "Kirtiparna Kar",
      img: "Image/testimonials/Kirtiparna.jpeg ",
      alt: "image1",
      review:
        "Dream Adoration provides a platform where employees can openly give and receive feedback. This exciting cycle of feedback ensures that individuals not only learn but also enhance their skills during their work journey. This process fosters personal growth while significantly contributing to the success of the organization. Open communication and the exchange of diverse perspectives empower employees to reach their full potential and drive innovation within the organization.",
    },
];

function Ourstudentsay() {
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const toggleExpand = (index) => {
    setExpandedIndex(index === expandedIndex ? -1 : index);
  };

  return (
    <div
      className="testimonial-container"
      style={{
        backgroundColor: "#0d1232",
        padding: "2rem",
        margin: "3rem",
        borderRadius: "5px",
        textAlign: "center",
      }}
    >
      <h2 className="text-white" style={{ fontSize: "18px" }}>
        <span style={{ fontSize: "1.5rem" }}>Voices of Success</span>
        <br />
        <span style={{ fontSize: "2rem" }}>
          Hear What Our Students{" "}
          <span style={{ color: "red" }}>Have to Say</span>
        </span>
      </h2>
      <div className="w-3/4 m-auto">
        <div className="mt-10">
          <Slider {...settings}>
            {data.map((d, index) => (
              <div
                key={index}
                className="slide-testimonial bg-white text-black rounded-xl overflow-hidden"
                style={{ height: "400px" }}
              >
                <div className="h-46 rounded-t-xl bg-white-500 flex justify-center items-center">
                  <img src={d.img} alt="" className="h-44 w-44 rounded-full" />
                </div>
                <div className="flex flex-col justify-center items-center gap-1 p-4 overflow-auto">
                  <p className="text-xl font-semibold">{d.name}</p>
                  {expandedIndex === index ? (
                    <p>{d.review}</p>
                  ) : (
                    <p>
                      {d.review.length > 60
                        ? d.review.slice(0, 60) + "..."
                        : d.review}
                    </p>
                  )}
                  <button
                    onClick={() => toggleExpand(index)}
                    className="text-white text-lg px-6 py-1 rounded-xl read-more-button" style={{backgroundColor:'#0d1232'}}
                  >
                    {expandedIndex === index ? "Read Less" : "Read More"}
                  </button>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Ourstudentsay;
